import React, { useState, useEffect, useReducer } from 'react'
import styles from './TranspaisBusLayout.module.scss'
import {
  BusSeat, Loader, SegmentedControl,
} from './index'
import { SeatStatus } from './enums'
import PassengerView from '../../modules/booking/partials/PassengerView'
import { useModalView } from '../../contexts/modalview.context'
import { useModal } from '../../contexts/modal.context'
import { useAtom } from 'jotai'
import { bookingSessionDepartureBlockedTickets, bookingSessionDepartureSelectedTickets,bookingSessionTripConfig,bookingSessionDepartureTrip,bookingSessionReturningTrip } from '../../utilities/atoms'

const TranspaisBusLayout = ({layout, disponibilidad, selected, onSelect, seatSelect, listPasajeros, listPassengers, noPets, referencia, noPetsWindow, selectFloor, fromReservation}) => {

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [bookingDepartureSelectedTickets,] = useAtom(bookingSessionDepartureSelectedTickets)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTrip, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)

  const _floors = ['Planta baja', 'Planta alta']
  const [floors, setFloors] = useState([])
  const [selectedFloor, setSelectedFloor] = useState(0)

  const [selectedSeat, setSelectedSeat] = useState(null)

  const [listaPassengers, setListPassengers] = useState(listPassengers)

  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const {showModal, hideModal, setupModal} = useModal()

  const changeBusFloor = (index) => {
    setSelectedFloor(index)
    selectFloor(index + 1)
  }

  const selectSeat = (seat, isSelected) => {
    console.log('se', seat, isSelected)
    if (isSelected) {
      onSelect({
        data: {
          asiento: seat.asiento
        }
      })
    } else if ((listPassengers.length !== 0 && selected.length === listPassengers.length) && (bookingDepartureBlockedTickets.length !== 0)) {
      setupModal({
        title: 'Atención',
        content: <p>Ya has seleccionado todos los pasajeros, si deseas cambiar de asiento has click en uno de los que ya escogiste para desbloquearlo y seleccionar otro</p>,
        actions: {
          positive: {
            title: 'Ok',
            onClick: () => {
              hideModal()
            }
          }
        }
      })
      showModal()
    } else {
      console.log('pongo el item', seat)
      if (selectedSeat?.asiento !== seat.asiento) {
        setSelectedSeat(seat)
      } else {
        modalSeatSelected()
      }
    }
  }

  const finishSeatSelection = (seat) => {
    onSelect(seat)
    destroyModalView()
    setSelectedSeat(null)
    const isSelected = selected.find((seats) => seats.asiento === seat.data.mancuerna)
    let mancuerna = seat.data.mancuerna
    if (seat.disponibilidad.categoria === 6 && isSelected === undefined) {
      let _seat = layout.find((seats) => seats.asiento === mancuerna)
      setupModal({
        title: 'Mascotas',
        content: <p>Seleccionaste el asiento de tu mascota, ahora selecciona el de la persona que irá a su lado.</p>,
        actions: {
          positive: {
            title: 'Continuar',
            onClick: () => {
              hideModal()
              selectSeat(_seat, false)
            }
          }
        }
      })
      showModal()
      // setTimeout( () => {
      //   console.log('entro el timeout')
      //   hideModal()
      //   selectSeat(_seat, false)
      // }, 2000 )
    }
  }

  const updateListPassenger = (listPassenger) => {
    setListPassengers(listPassenger)
    listPasajeros(listaPassengers)
  }

  const modalSeatSelected = () => {
    setupModalView({
      title: bookingTripConfig.redondo === false ? 'Pasajero' : 'Elije el pasajero',
      content: (
        <PassengerView departure={ bookingDepartureBlockedTickets.filter((ticket) => {
          const exists = selected.some( (selectedTicket) => { 
            return selectedTicket.nombre === ticket.pasajero && selectedTicket.tipo === ticket.tipo 
          })
          return !exists
        }) } seat={ selectedSeat } disponibilidad={ disponibilidad } onFinish={ finishSeatSelection } selected={ selected } listsPassengers={ listaPassengers } updatedList={ updateListPassenger }
        noPets={noPets} referencia={referencia} noPetsWindow={noPetsWindow}
        fromoduleReserv={fromReservation}/>)
    })
    showModalView()
  }

  useEffect(() => {
    console.log('callback', selectedSeat, disponibilidad)
    if (selectedSeat !== null && SeatStatus.disponible(selectedSeat.status)/*(selectedSeat.status === 'DP' || selectedSeat.status === 'DPI')*/) {
      modalSeatSelected()
    }

  }, [selectedSeat])

  useEffect(() => {
    //UseState to show passenger view if SGI/SG
    if (seatSelect !== null) {
      selectSeat(seatSelect, false)
      changeBusFloor(seatSelect.piso -1)
    }
  },[seatSelect])

  // useEffect(() => {
  //   console.log(selectedFloor)
  // },[selectedFloor])

  const x = (column) => {
    //const _row = row - 1
    const _column = column - 1

    return (_column * 64) + (_column * 30)
  }

  const y = (row) => {
    const _row = row - 1
    const even = _row % 2 === 0

    let y = _row * 5
    //if (even) {
    if (_row > 1) {
      y -= 44 * (_row / 2)

      if (_row > 2) {
        y += 10
      }
    }
    //}

    return (_row * 64) + y
  }

  return (
    <>
      <SegmentedControl items={ floors } selected={ selectedFloor } onChange={ changeBusFloor }/>

      <div className={ [styles.busLayout, styles[`piso${Object.entries(layout).length > 0 ? layout[0].piso : 0}`]].join(' ') }>
          <div className={ styles.volante }></div>
          <div>
            { layout?.map((seat, i) => {
              if (!floors.includes(_floors[seat.piso - 1])) {
                let __floorsTmp = [...floors]
                __floorsTmp[seat.piso - 1] = _floors[seat.piso - 1]
                setFloors(__floorsTmp)
              }

              return seat.piso === (selectedFloor + 1) ? (
                Number.isInteger(parseInt(seat.asiento)) || seat.asiento === 'WC' ? (
                  (
                    <BusSeat key={ `bs_${ i }` } position={ {x: x(seat.columna), y: y(seat.fila)} } type={ seat.asiento === 'WC' ? 'placeholder' : 'default' }
                             variant={ 'default' }
                             status={ SeatStatus.disponible(seat.status) ? 'available' : 'unavailable' }
                             value={ seat.asiento }
                             selected={ selected.findIndex((ticket) => ticket.asiento === seat.asiento) > -1 }
                             onClick={ (isSelected) => {
                               console.log('cluc on lay')
                               selectSeat(seat, isSelected)
                             } }
                             categoria={ seat.categoria_id }
                             destacado={ seat.destacado }
                             preferente={ disponibilidad }/>
                  )
                ) : null
              ) : null
            }) }
          </div>
        </div>
    </>
  )
}

export default TranspaisBusLayout
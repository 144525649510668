import React, { useEffect, useState, useRef } from 'react'
import {useKey} from 'react-use'
import { isEmpty } from 'lodash'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import { useAtom } from 'jotai'
import Keyboard from 'react-simple-keyboard'
import Rive from '@rive-app/react-canvas'
import { useNavigate } from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {light} from '@fortawesome/fontawesome-svg-core/import.macro'
import * as Sentry from '@sentry/react'

import {
  reservationTickets,
  reservationFolio,
  bookingSessionTickets,
} from '../../utilities/atoms'
import gateway from '../../services/gateway'

import {
  BASE_URL
} from '../../utilities/constants'
import { Button, Timer, Loader, InputAutocomplete } from '../../components/transpais'
import { useModal } from '../../contexts/modal.context'
import transpais from '../../services/transpais'

import global from './SearchTicketGlobal.module.scss'
import styles from './SearchTicket.module.scss'
import { usePinpad } from '../../contexts/pinpad.context'
import { useLogger } from '../../contexts/logger.context'
import { useTimer } from '../../contexts/timer.context'
import {
  RESERVATION_CODE_MIN_SIZE,
  RESERVATION_CODE_MAX_SIZE
} from '../../utilities/constants'

const SearchTicket = () => {
  const {showTimer, setupTimer, hideTimer, waitingPayment} = useTimer()
  const {showModal, hideModal, setupModal} = useModal()
  const completeMail = [
    'gmail.com',
    'outlook.com',
    'transpais.com.mx',
    'me.com',
    'hotmail.com',
    'icloud.com',
    'yahoo.com.mx',
    'live.com.mx',
    'yahoo.com',
    'hotmail.es',
    'live.com',
    'hotmail.com.mx',
    'prodigy.net.mx',
    'msn.com',
  ]
  const {loggerDebug, loggerInfo, loggerError} = useLogger()
  const keyboard = useRef()
  const inputBooking = useRef(false)
  const inputFolio = useRef(false)
  const navigate = useNavigate()

  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [localLoading, setLocalLoading] = useState(false)
  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [folio, setFolio] = useState('')
  const [keyboardLayout, setKeyboardLayout] = useState('default')
  const [reserva, setReserva] = useState('')
  const [changeInput, setChangeInput] = useState(true)
  const [isReading, setIsReading] = useState(false)

  const [, setTickets] = useAtom(reservationTickets)
  // const [, setFolio] = useAtom(reservationFolio)
  const {loading, reset, changeCompany} = usePinpad()

  const readCode = (event) => {
    if(inputFolio.current === true) {
      setChangeInput(true)
      console.log('Enter read code')
      console.log(inputBooking.current)
      const key = event.key
      inputBooking.current = true
      setCodeKey(key)
    }
  }

  const setCodeKey = (key) => {
    if (key !== 'Enter' && !(key.includes('{') && key.includes('}'))) {
      if (!isReading) {
        setIsReading(true)
      }
      inputBooking.current ? setReserva(reserva + key) : setFolio(folio + key)
    } else {
      search()
    }
  }

  const predicate = (event) => (event.key >= '0' && event.key <= '9') || event.key === 'Enter'
  useKey(predicate, readCode, {event: 'keyup'})

  function handleShift() {
    //loggerDebug(keyboard.current)
    const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default'
    setKeyboardLayout(newLayoutName)
  }

  useEffect(() => {
    if (bookingTickets !== null && bookingTickets.length > 0) {
      navigate(`${ BASE_URL }/reprints/ticket`)
    }
  }, [bookingTickets])

  useEffect(() => {
    if (reserva.length === RESERVATION_CODE_MIN_SIZE && folio !== '') {
      setCanContinue(true)
    } else if (reserva.length < RESERVATION_CODE_MIN_SIZE || folio === '') {
      setCanContinue(false)
    }
  }, [folio, reserva])

  useEffect(() => {
    if (showScreenKeyboard) {
      if (!isReading) {
        setIsReading(true)
      }
    } else {
      if (isReading) {
        setIsReading(false)
      }
    }
  },[showScreenKeyboard])

  const onKeyboardChange = (input) => {
    loggerDebug('Input changed', input)
  }

  const search = () => {
    if (!isEmpty(reserva) && !isEmpty(folio)) {
      setLocalLoading(true)
      transpais.tickets(reserva, folio)
        .then((response) => {
          Sentry.setContext('SearchTickets', {
            reserva: reserva,
            folio: folio,
            data: response.data,
          })
          setLocalLoading(false)
          console.log(response.data)
          const tickets = response.data
          setBookingTickets(tickets)
        })
        .catch((error) => {
          Sentry.setContext('SearchTickets', {
            reserva: reserva,
            folio: folio,
          })
          Sentry.addBreadcrumb({
            category: 'ibus',
            message: 'SearchTickets',
            level: 'error',
          })
          Sentry.captureException(error)
          const message = error.response.data
          setLocalLoading(false)
          setupModal({
            title: 'Atención',
            content: <p>{ message.message }</p>,
            actions: {
              positive: {
                title: 'Aceptar',
                onClick: () => {
                  hideModal()
                  setReserva('')
                  setFolio('')
                }
              }
            }
          })
          showModal()
        })
    } else {
      setLocalLoading(false)
      setupModal({
        title: 'Atención',
        content: <p>Asegurate de escribir tu PIN</p>,
        actions: {
          positive: {
            title: 'OK',
            onClick: () => {
              hideModal()
              setReserva('')
            }
          }
        }
      })
      showModal()
    }
  }

  function onKeyboardKeyPress(button) {
    loggerDebug('Button pressed', button)
    if (button === '{esc}') {
      setShowScreenKeyboard(false)
    } else if (button === '{bksp}') {
      inputBooking.current ? setReserva(reserva.slice(0, -1)) : setFolio(folio.slice(0, -1))
    } else if (button === '{shift}' || button === '{lock}') {
      handleShift()
    } else {
      setCodeKey(button)
    }
  }

  return (
    <>
      <Loader show={ loading || localLoading }/>
      {/*----Page----*/ }
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.timers }>
              <Timer variant={ 'clock' }/>
            </div>
          </div>
          <div className={ styles.middle }>
            <div className={ styles.formWrapper }>
              <div>
                <Rive src="https://transpais-kiosk.s3.amazonaws.com/animations/kiosk-pin.riv"
                      className={ styles.instructionsLoop }/>
              </div>
              <div className={styles.formContent}>
                <p>Escribe tu PIN y el folio de reserva para imprimir tus boletos.</p>
                <div className={ styles.form }>
                  <h2>PIN</h2>
                  <input type="text" value={ folio }
                         onFocus={ () => {
                           setShowScreenKeyboard(true)
                           setChangeInput(false)
                           inputBooking.current = false
                           inputFolio.current = false
                         }
                         }
                         onChange={ (e) => setFolio(e.target.value) }
                  />
                  <h2 className={ styles.labelFolio }>Folio de reserva</h2>
                  <input type="text" value={ reserva }
                         onFocus={ () => {
                           setShowScreenKeyboard(true)
                           setChangeInput(true)
                           inputBooking.current = true
                           inputFolio.current = true
                         }
                         }
                         onChange={ (e) => setReserva(e.target.value) }
                  />
                  <p>Este servicio solo es para boletos pagados desde una reserva</p>
                  <div
                    className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null, changeInput ? styles.reserva : styles.pin].join(' ') }>
                    <Keyboard
                      onChange={ onKeyboardChange }
                      onKeyPress={ onKeyboardKeyPress }
                      layout={ {
                        default: [
                          '1 2 3', '4 5 6', '7 8 9', '{esc} 0 {bksp}',
                        ]
                      } }
                      display={ {
                        '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                        '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                      } }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
            <Button to="/" type="neutral" left-icon="chevron-left">
              Volver
            </Button>
            {/* <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button> */ }
            {/* <Button to={ `${ BASE_URL }/booking/bus` } type="neutral" left-icon="chevron-left">Volver</Button> */ }
          </div>
          <div className={global.middle}>
            <TransitionGroup>
              {!isReading && (
                <CSSTransition
                  key={'notice-01'}
                  classNames="notice"
                  timeout={{enter: 300, exit: 300}}
                  >
                  <div className={global.notice}>
                    <p>El lector está aquí</p>
                    <FontAwesomeIcon
                        icon={light('arrow-down')}
                        bounce
                        className={global.pointer}
                    />
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </div>
          <div>
            <Button
              type="positive"
              right-icon="chevron-right"
              // to={ `${ BASE_URL }/reprints/ticket` }
              disabled={!canContinue}
              onClick={ search }
            >
              Buscar
            </Button>
            {/* <Button onClick={ willPay } type="positive" right-icon="chevron-right">Pagar</Button> */ }
          </div>
        </footer>
      </section>

      {/* <div className={[styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ')}>
              <Keyboard
                  onChange={onKeyboardChange}
                  onKeyPress={onKeyboardKeyPress}
                  layout={{
                      default: [
                          '1 2 3', '4 5 6', '7 8 9', '{esc} 0 {bksp}',
                      ]
                  }}
                  display={{
                      '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                      '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                  }}
              />
            </div> */ }

      {/* <div className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ') }>
              <Keyboard keyboardRef={ r => (keyboard.current = r) }
                onKeyPress={ onKeyboardKeyPress }
                layoutName={ keyboardLayout }
                layout={ {
                  default: [
                    '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                    '{blank} q w e r t y u i o p',
                    '{lock} a s d f g h j k l \u00f1 {blank}',
                    '{shift} z x c v b n m . - {shift}',
                    '{space} {at} {dotcom}',
                  ],
                  shift: [
                    '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                    'Q W E R T Y U I O P \u0308',
                    '{lock} A S D F G H J K L \u00d1 {blank}',
                    '{shift} {blank} Z X C V B N M _ {shift}',
                    '{space} {at} {dotcom}',
                  ],
                } }
                display={ {
                  '{at}': `<svg class="key-icon"><use xlink:Href="#at"/></svg>`,
                  '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                  '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                  '{blank}': ` `,
                  '{space}': ` `,
                  '{lock}': `<svg class="key-icon"><use xlink:Href="#font-case"/></svg>`,
                  '{shift}': `<svg class="key-icon"><use xlink:Href="#up"/></svg>`,
                  '{dotcom}': `.com`,
                } }
              />
            </div> */ }
    </>
  )
}

export default SearchTicket

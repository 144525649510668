import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { BASE_URL } from '../../utilities/constants'

import { Button, Loader } from '../../components/transpais'
import { useModal } from '../../contexts/modal.context'
import { useNavigate } from 'react-router-dom'
import { useLogger } from '../../contexts/logger.context'

import '../../scss/Layout.scss'
import * as Styled from './Login.styled'

import gateway from '../../services/gateway'
import { useAtom } from 'jotai'
import {
  claveUsuario,
  usuarioID,
  puntoVentaID,
  estacionID,
  accessToken,
  nombrePuntoVenta,
  TranspaisIDToken
} from '../../utilities/atoms'
import { usePinpad } from '../../contexts/pinpad.context'
import Footer from './components/Footer'
import transpaisid from '../../services/transpaisid'
import { isEmpty } from 'lodash'

const Login = () => {
  const {loggerDebug, loggerError} = useLogger()
  const {loading} = usePinpad()
  const navigate = useNavigate()

  const [localLoading, setLocalLoading] = useState(false)

  const {showModal, setupModal} = useModal()

  const [canContinue, setCanContinue] = useState(false)

  const [mac, setMac] = useState('')
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [userID, setUserID] = useState('')
  const [passUserId, setPassUserId] = useState('')

  const [cveUsuario, setCveUsuario] = useAtom(claveUsuario)
  const [usuarioId, setUsuarioId] = useAtom(usuarioID)
  const [puntoVentaId, setPuntoVentaId] = useAtom(puntoVentaID)
  const [estacionId, setEstacionId] = useAtom(estacionID)
  const [token, setToken] = useAtom(accessToken)
  const [transpaisIDToken, setTranspaisIDToken] = useAtom(TranspaisIDToken)
  const [nombPuntoVenta, setNombPuntoVenta] = useAtom(nombrePuntoVenta)

  useEffect(() => {
    if (mac.trim() !== '' && user.trim() !== '' && pass.trim() !== '' && userID.trim() !== '' && passUserId.trim() !== '') {
      setCanContinue(true)
    } else {
      setCanContinue(false)
    }
  }, [mac, user, pass, userID, passUserId])

  useEffect(() => {
    loggerDebug('check')
    loggerDebug(cveUsuario, usuarioId, puntoVentaId, estacionId, token, nombPuntoVenta)
    if (cveUsuario.trim() !== '' && usuarioId !== 0 && puntoVentaId !== 0 && estacionId !== 0 && token.trim() !== '' && nombPuntoVenta.trim() !== '') {
      loggerDebug('redirect')
      setLocalLoading(false)
      navigate(`${ BASE_URL }/`)
    }
  }, [cveUsuario, usuarioId, puntoVentaId, estacionId, token, nombPuntoVenta])

  useEffect(() => {
    if (!isEmpty(transpaisIDToken)){
      login()
    }
  },[transpaisIDToken])

  const login = () => {
    setLocalLoading(true)
    loggerDebug(mac, user, pass)
    gateway
      .login(mac, user, pass)
      .then((response) => {
        loggerDebug(response.data)
        const data = response.data

        loggerDebug(data)

        setCveUsuario(data.cveUsuario)
        setUsuarioId(data.usuario_id)
        setPuntoVentaId(data.puntoVenta_id)
        setEstacionId(data.estacion_id)
        setToken(data.token)
        setNombPuntoVenta(data.nombPuntoVenta)

        Sentry.setUser({
          id: data.usuario_id,
          username: data.cveUsuario,
          pos_id: data.puntoVenta_id,
          pos: data.nombPuntoVenta,
        })
      })
      .catch(error => {
        loggerError(error)
        const data = error.response.data
        loggerError(data)

        setLocalLoading(false)
        setupModal({
          title: 'Atención',
          content: (<><p>{ data.error }</p></>),
        })
        showModal()
      })
  }

  const signin = () => {
    transpaisid.signIn(userID, passUserId)
      .then((response) => {
        console.log(response)
        setTranspaisIDToken(response.data.access_token)
        Sentry.setContext('signupGuest', {
          email: userID,
        })
        
      }).catch(error => {
        loggerError(error)
        const data = error.response.data
        console.log(error)
        loggerError(data)

        setLocalLoading(false)
        setupModal({
          title: 'Atención',
          content: (<><p>{ data.message }</p></>),
        })
        showModal()
      })
  }

  return (
    <>
      <Loader show={ loading || localLoading }/>
      {/*----Page----*/ }
      <Styled.Page className={ ['section-full'].join(' ') }>
        <Styled.Content className={ ['section-flex'].join(' ') }>
          <Styled.Top className={ ['logo'].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-dark'].join(' ') }/>
          </Styled.Top>
          <Styled.LoginForm>
            <Styled.Card>
              <h1>Configuración de kiosco</h1>
                <Styled.FieldGroup>
                  <label>MAC</label>
                  <input type="text" value={ mac } onChange={ e => setMac(e.target.value) }/>
                </Styled.FieldGroup>
              <Styled.FieldGroupContent>
                <Styled.FieldGroup>
                  <label>Usuario</label>
                  <input type="text" value={ user } onChange={ e => setUser(e.target.value) }/>
                </Styled.FieldGroup>
                <Styled.FieldGroup>
                  <label>Contraseña</label>
                  <input type="password" value={ pass } onChange={ e => setPass(e.target.value) }/>
                </Styled.FieldGroup>
              </Styled.FieldGroupContent>
              <Styled.FieldGroupContent>
                <Styled.FieldGroup>
                  <label>Transpais ID</label>
                  <input type="text" value={ userID } onChange={ e => setUserID(e.target.value) }/>
                </Styled.FieldGroup>
                <Styled.FieldGroup>
                  <label>Contraseña Transpais ID</label>
                  <input type="password" value={ passUserId } onChange={ e => setPassUserId(e.target.value) }/>
                </Styled.FieldGroup>
              </Styled.FieldGroupContent>
              
              <Button
                type="positive"
                disabled={ !canContinue }
                onClick={ signin }
              >
                Iniciar
              </Button>
            </Styled.Card>
          </Styled.LoginForm>
          <Footer/>
        </Styled.Content>
        <Styled.Background>
          <Styled.BackgroundImage/>
        </Styled.Background>
      </Styled.Page>
    </>
  )
}

export default Login
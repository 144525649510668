import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../../components/navigation/PrivateRoute'

import { BASE_URL } from '../../utilities/constants'

import PrintTicket from './PrintTicket'

const routes = (
  <Route exact path={`${BASE_URL}/adminTikets`}>
    {/* <Route exact path="search" element={<ProtectedRoute component={ <SearchTicket/>}/>}/> */}
    <Route exact path="tickets" element={<ProtectedRoute component={ <PrintTicket/>}/>}/>
    {/* <Route exact path="search" element={<ProtectedRoute component={ <BookingSearch/> }/>}/>
    <Route exact path="trips" element={<ProtectedRoute component={ <BookingTrips/> }/>}/>
    <Route exact path="bus" element={<ProtectedRoute component={ <BookingBus/> }/>}/>
    <Route exact path="review" element={<ProtectedRoute component={ <BookingReviewOrder/> }/>}/>
    <Route exact path="confirmed" element={<ProtectedRoute component={ <BookingConfirmed/> }/>}/> */}
  </Route>
)

export default routes
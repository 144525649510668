import React, { useEffect, useRef, useState } from 'react'
import styles from './TranspaisCarrousel.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useLogger } from '../../contexts/logger.context'

const TranspaisCarrousel = ({children, width = '100%'}) => {

  const carrouselRef = useRef(null)

  const {loggerDebug, loggerLog, loggerError} = useLogger()

  const [currentTripsScroll, setCurrentTripsScroll] = useState(0)
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)
  const previousPage = (event) => {
    carrouselRef.current?.scrollBy({left: width * -1, behavior: 'smooth'});
  }

  const nextPage = (event) => {
    carrouselRef.current?.scrollBy({left: width, behavior: 'smooth'});
  }

  const handleScroll = (e) => {
    loggerDebug(carrouselRef.current.scrollLeft)
    loggerDebug(carrouselRef.current.offsetWidth )
    loggerDebug(carrouselRef.current.scrollWidth )

    setCurrentTripsScroll(carrouselRef.current.scrollLeft)
  }

  useEffect(() => {


    if (carrouselRef.current.scrollWidth > carrouselRef.current.offsetWidth) {
      setShowRight(true)
    } else {
      setShowRight(false)
    }
  }, [carrouselRef])

  useEffect(() => {
    if (currentTripsScroll > 10) {
      setShowLeft(true)
    } else {
      setShowLeft(false)
    }

    if ((carrouselRef.current.scrollLeft + carrouselRef.current.offsetWidth) >= carrouselRef.current.scrollWidth - 10) {
      setShowRight(false)
    } else {
      setShowRight(true)
    }
  }, [currentTripsScroll])

  return (
    <div className={ [styles.component].join(' ') }>
      {showLeft ? (<div className={ [styles.scrollBtn, styles.left].join(' ') } onClick={previousPage}>
        <FontAwesomeIcon icon={ solid('circle-chevron-left') } className={ styles.icons }/>
      </div>) : null}

      <div className={ [styles.carrousel].join(' ') } style={{maxWidth: width}} ref={carrouselRef} onScroll={ handleScroll }>
        { children }
      </div>

      {showRight ? (<div className={ [styles.scrollBtn, styles.right].join(' ') } onClick={nextPage}>
        <FontAwesomeIcon icon={ solid('circle-chevron-right') } className={ styles.icons }/>
      </div>) : null}
    </div>
  )
}

export default TranspaisCarrousel
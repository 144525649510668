const decode = (jwt) => {
    // Split the JWT into its parts
    const parts = jwt.split('.');

    // Check if the JWT has three parts
    if (parts.length !== 3) {
        throw new Error('Invalid JWT format');
    }

    // Decode and parse the payload (second part)
    const payloadBase64 = parts[1];
    return JSON.parse(atob(payloadBase64));
}

export const getTID = (jwt) => {
    const payload = decode(jwt)

    return payload.sub
}
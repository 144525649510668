import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import transpais from '../../services/transpais'
import { format, parseISO } from 'date-fns'

import { useNavigate, Link } from 'react-router-dom'
import { useModalView } from '../../contexts/modalview.context'
import { useLogger } from '../../contexts/logger.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'
import { TripType } from '../../components/transpais/enums'
import { isEmpty } from 'lodash'

import { BASE_URL } from '../../utilities/constants'

import {
  claveUsuario,
  usuarioID,
  puntoVentaID,
  estacionID,
  accessToken,
  nombrePuntoVenta,
  bookingSessionOrigin,
  bookingSessionDestination,
  bookingSessionDepartureTrip,
  bookingSessionDepartureDisponibilidad,
  bookingSessionDepartureSelectedTickets,
  bookingSessionDepartureBlockedTickets,
  bookingSessionDepartureDate,
  bookingSessionReturningDate,
  bookingSessionTripConfig,
  bookingSessionReturningTrip,
  bookingSessionReturningBlockedTickets,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  usuarioEmpresa,
  TranspaisIDToken,
  sessionCanReserve
} from '../../utilities/atoms'

import styles from './BookingTrips.module.scss'
import global from '../booking/BookingGlobal.module.scss'
import {
  Button, Carrousel, Departure, Timer, TripData, Loader
} from '../../components/transpais'
import { DepartureViewVariant } from '../../components/transpais/enums'

const BookingTrips = () => {

  const DataTr = {
    date: 'Mar 20, Nov', precio: 505.00,
  }

  const backReturn = useRef(false)
  const [currentDateIndex, setCurrentDateIndex] = useState(2)
  const [gotoNextStep, setGotoNextStep] = useState(false)
  const [tripPages, setTripsPages] = useState(null)
  const [trips, setTrips] = useState([])

  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const {loggerDebug} = useLogger()
  const {loading, changeCompany} = usePinpad()
  const {showTimer, hideTimer, setupTimer} = useTimer()

  const [cveUsuario,] = useAtom(claveUsuario)
  const [usuarioEmpresaId, setUsuarioEmpresaId] = useAtom(usuarioEmpresa)
  const [usuarioId,] = useAtom(usuarioID)
  const [puntoVentaId,] = useAtom(puntoVentaID)
  const [estacionId,] = useAtom(estacionID)
  const [token,] = useAtom(accessToken)
  const [nombPuntoVenta,] = useAtom(nombrePuntoVenta)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  const [bookingOrigin, setBookingOrigin] = useAtom(bookingSessionOrigin)
  const [bookingDestination, setBookingDestination] = useAtom(bookingSessionDestination)
  const [bookingDepartureDate,] = useAtom(bookingSessionDepartureDate)
  const [bookingReturningDate,] = useAtom(bookingSessionReturningDate)

  const [bookingDepartureTrip, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  const [bookingDepartureDisponibilidad, setBookingDepartureDisponibilidad] = useAtom(bookingSessionDepartureDisponibilidad)
  const [bookingDepartureSelectedTickets, setBookingDepartureSelectedTickets] = useAtom(bookingSessionDepartureSelectedTickets)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [sessionReserve, setSessionCanReserve] = useAtom(sessionCanReserve)

  const [localLoading, setLocalLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const searched = useRef(false)
  const navigate = useNavigate()

  const searchForTrips = () => {
    if (!searched.current) {
      searched.current = true
      console.log('busco viajes')
      setLocalLoading(true)
      setNotFound(false)
      let _origin = bookingOrigin.id
      let _destination = bookingDestination.id
      let _date = parseISO(bookingDepartureDate)
  
      if (bookingTripConfig.redondo === true) {
        console.log('entro parametros de redondo')
        console.log(parseISO(bookingReturningDate))
        console.log('fecha de salida' + _date)
        _origin = _destination
        _destination = bookingOrigin.id
        _date = parseISO(bookingReturningDate)
        //showTimer()
      } else {
        console.log('no entro redondo parametros')
      }
  
      transpais.search(transpaisIdToken, bookingDepartureTripId, _origin, _destination, bookingTripConfig.redondo, format(_date, 'dd/MM/yyyy'), {
        cveUsuario, usuarioId, puntoVentaId, estacionId, token, nombPuntoVenta,
      })
        .then((response) => {
          setLocalLoading(false)
          const _departures = response.data.departures
          const _tripId = response.data.trip
          const _usuarioEmpresaId = response.data.usuarioEmpresaId
  
          setUsuarioEmpresaId(_usuarioEmpresaId)
  
          setTrips(_departures)
          if (_departures.lenght === 0) {
            setNotFound(true)
            searched.current = false
          } else {
            setNotFound(false)
            searched.current = true
          }
          if (bookingDepartureTripId === null) {
            setBookingDepartureTripId(_tripId)
          } else {
            setBookingReturningTripId(_tripId)
          }
  
        }).catch((error) => {
        setLocalLoading(false)
        setNotFound(true)
  
      })
    }
  }

  const selectTrip = (trip) => {
    loggerDebug('selectTrip', trip)
    setLocalLoading(true)

    let _empresaId = trip.empresa
    console.log('empresa', bookingDepartureTripId, _empresaId)
    if (bookingDepartureTrip !== null) {
      console.log('empresa', bookingDepartureTrip, _empresaId)
      _empresaId = trip.empresa !== bookingDepartureTrip.empresa ? usuarioEmpresaId : _empresaId
    }

    transpais.credentials(bookingDepartureTripId, usuarioId, _empresaId)
      .then((response) => {
        Sentry.setContext('Trip', {
          empresa: _empresaId,
          name: response.data.name,
          value: response.data.value,
          redondo: bookingTripConfig.redondo === true ? 'true' : 'false',
          viaje: trip
        })
        setLocalLoading(false)
        const _user = response.data.name
        const _password = response.data.value

        changeCompany({
          empresaId: trip.empresa,
          name: _user,
          value: _password,
        })
        if (bookingTripConfig.redondo === false) {
          setBookingDepartureTrip(trip)
        } else {
          setBookingReturningTrip(trip)
        }
        setTripsPages([])
        setGotoNextStep(true)
      }).catch((error) => {
      setLocalLoading(false)

    })
  }

  const onBack = () => {
    if (backReturn.current === false) {
      backReturn.current = true
      console.log('setTrue-> ' + backReturn)

      if (bookingTripConfig.redondo === true) {
        let __bookingTripConfig = bookingTripConfig
        __bookingTripConfig.redondo = false
        setBookingTripConfig(__bookingTripConfig)
        setGotoNextStep(true)
        setBookingReturningTrip(null)
        setLocalLoading(true)
        transpais.unblock(
          transpaisIdToken,bookingDepartureTripId, bookingDepartureBlockedTickets.map((ticket) => ticket.id)
        ).then((response) => {
          setBookingReturningBlockedTickets([])
          setBookingDepartureBlockedTickets([])
          hideTimer()
        }).catch((error) => {
          setLocalLoading(false)
          setBookingReturningBlockedTickets([])
          setBookingDepartureBlockedTickets([])
          hideTimer()
        })
      } else {
        setBookingOrigin({})
        setBookingDestination({})
        setBookingDepartureTripId(null)
        setTrips([])
        setBookingDepartureTrip(null)
        setBookingDepartureDisponibilidad({})
        setBookingDepartureSelectedTickets([])
        setBookingDepartureBlockedTickets([])
        hideTimer()
      }
    }
  }

  useEffect(() => {
    if ( !isEmpty(sessionReserve) && bookingTripConfig.redondo) {
      setSessionCanReserve({})
    }
  },[])

  useEffect(() => {
    console.log('entro')
    if (bookingOrigin != null && bookingDestination != null) {
      searchForTrips()
    }
  }, [bookingOrigin, bookingDestination])

  useEffect(() => {
    console.log(bookingOrigin)
    console.log(bookingDestination)
    console.log(trips)
    console.log(bookingDepartureDisponibilidad)
    if (Object.entries(bookingOrigin).length === 0
      && Object.entries(bookingDestination).length === 0
      && bookingDepartureTripId === null
      && Object.entries(trips).length === 0
      && bookingDepartureTrip === null
      && Object.entries(bookingDepartureDisponibilidad).length === 0
      && bookingDepartureSelectedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
    ) {
      navigate(`${ BASE_URL }/reservationTrips/search`)
    }
  }, [bookingOrigin, bookingDestination, bookingDepartureTripId, trips, bookingDepartureTrip, bookingDepartureDisponibilidad, bookingDepartureSelectedTickets, bookingDepartureBlockedTickets])

  useEffect(() => {
    if (trips !== null && usuarioEmpresaId !== 0) {
      let _trips = trips
      let _tripsPages = []
      console.log(_trips)
      while (_trips.length) {
        _tripsPages.push(_trips.splice(0, 6))
      }
      setTripsPages(_tripsPages)
      console.log(_tripsPages)
    }
  }, [trips, usuarioEmpresaId])

  useEffect(() => {
    if ((bookingDepartureTrip !== null && Object.keys(bookingDepartureTrip).length !== 0 && gotoNextStep)
      || (bookingReturningTrip !== null && Object.keys(bookingReturningTrip).length !== 0 && gotoNextStep)) {
      loggerDebug('Ya almacene la corrida')
      navigate(`${ BASE_URL }/reservationTrips/bus`)
      // TODO: cargador, consulta de corrida
    }
  }, [bookingDepartureTrip, gotoNextStep])

  return (
    <>
      <Loader show={ loading || localLoading }/>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>

          <div className={ ['logo', bookingTripConfig.redondo === true ? 'roundAlign' : null ,styles.logo].join(' ') }>
            <figure className={  ['logo-image', 'logo-image-transpais-light', bookingTripConfig.redondo === true ? 'logo-small' : null].join(' ') }/>
            { bookingTripConfig.type === TripType.round ? <div className={ styles.toPill }>
              <div className={ styles.pill }>
                <p>{bookingDepartureBlockedTickets.length === 0 ? 'Selecciona tu viaje de ida' : 'Selecciona tu viaje de regreso' }</p>
              </div>
            </div> : null}
            <Timer variant={ 'clock' }/>
          </div>

          <div className={ styles.middle }>

            <div className={ global.content }>
              <div className={ styles.tripsHeader }>
                <TripData variant={ 'brief' } data={ {
                  origin: bookingOrigin,
                  destination: bookingDestination,
                  returning: bookingTripConfig.redondo
                } }/>

                <TripData variant={ 'meta' }
                  meta={ {departure: bookingDepartureTrip, returning: bookingReturningTrip} }
                />
              </div>

              <hr/>

              { tripPages !== null && tripPages.length > 0 ? <div className={ [styles.departures].join(' ') }>
                <Carrousel width={ 1856 }>
                  <div className={ styles.pages }>
                    { tripPages?.map((page, i) => (
                      <div key={ `tp_${ i }` } className={ styles.departuresPage }>
                        { page.map((trip) => (
                          <Departure key={ `tpd_${ trip.id }` } onClick={ () => selectTrip(trip) }
                                     variant={ DepartureViewVariant.full } data={ trip }/>)) }
                      </div>
                    )) }
                  </div>
                </Carrousel>
              </div> : notFound === true ? <div className={ styles.departuresEmpty }>
                <div className={ styles.instructions }>
                  <div className={ styles.retry } onClick={() => {
                    searched.current = false
                    searchForTrips()
                  }}>Reintentar</div>
                  <h4>¡Ops! no encontré viajes a tu destino, puedes reintentar la búsqueda o cambia la fecha.</h4>
                </div>
              </div> : null }
            </div>

          </div>
        </div>

        <footer className={ global.bottom }>
          <div>
            <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button>
          </div>
          <div className={ global.middle }>

          </div>
          <div>
            {/*<Button to={ `${ BASE_URL }/booking/bus` } type="positive" right-icon="chevron-right">Continuar</Button>*/ }
          </div>
        </footer>
      </section>
    </>
  )

}

export default BookingTrips
import * as Styled from './Footer.styled'
import React from 'react'
import { getYear } from 'date-fns'

import '../../../scss/Layout.scss'

const Footer = () => {
  return <Styled.Footer className={ ['footer'].join(' ') }>
    <div className="legal-copy">Transpais Único S.A. de C.V. ©{getYear(Date.now())}</div>
    <div className="legal-version">{ process.env.REACT_APP_VERSION } ({process.env.REACT_APP_BUILD})</div>
  </Styled.Footer>
}

export default Footer
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import * as Styled from './TranspaisModal.styled'
import { DestructiveButton, PositiveButton, PositiveDefaultButton } from './Transpais.styled'

const TranspaisModal = ({
                          show,
                          title,
                          children,
                          positiveButtonTitle,
                          negativeButtonTitle,
                          onClose,
                          onAccept,
                          buttonType,
                          onExtra,
                          onExtraTwo,
                          onExtraAccept,
                          extraButtonTittle,
                          extraTwoButtonTittle,
                          positiveExtraButtonTittle,
                          cancelable
                        }) => {

  if (!show) {
    return null
  }

  return (
    <Styled.Modal>
      <Styled.ModalContent>
        {cancelable && <Styled.ModalClose onClick={ onClose }>
          <FontAwesomeIcon icon={ solid('xmark') } size={'2xl'}/>
        </Styled.ModalClose> }
        <Styled.ModalHeader>
          <Styled.ModalTitle>{ title }</Styled.ModalTitle> 
        </Styled.ModalHeader>
        <Styled.ModalBody>
          { children }
        </Styled.ModalBody>
        <Styled.ModalFooter>
          { extraButtonTittle && 
          <PositiveButton type={ buttonType } onClick={ onExtra }>{ extraButtonTittle }</PositiveButton>}
          { extraTwoButtonTittle && 
            <PositiveDefaultButton type={ buttonType } onClick={ onExtraTwo }>{ extraTwoButtonTittle }</PositiveDefaultButton>}
          { positiveExtraButtonTittle && 
            <PositiveDefaultButton type={ buttonType } onClick={ onExtraAccept }>{ positiveExtraButtonTittle }</PositiveDefaultButton>}
        </Styled.ModalFooter>

        <Styled.ModalFooter>
          { negativeButtonTitle &&
          <DestructiveButton type={ buttonType }
                             onClick={ onClose }>{ negativeButtonTitle ?? 'Cancelar' }</DestructiveButton> }
          { positiveButtonTitle &&
            <PositiveButton type={ buttonType } onClick={ onAccept }>{ positiveButtonTitle }</PositiveButton> }
        </Styled.ModalFooter>
        
        
      </Styled.ModalContent>
    </Styled.Modal>
  )
}

export default TranspaisModal

import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../../components/navigation/PrivateRoute'

import { BASE_URL } from '../../utilities/constants'

import BookingSearch from './BookingSearch'
import BookingTrips from './BookingTrips'
import BookingBus from './BookingBus'
import BookingReviewOrder from './BookingReviewOrder'
import BookingConfirmed from './BookingConfirmed'

const routes = (
  <Route exact path={`${BASE_URL}/booking`}>
    <Route exact path="search" element={<ProtectedRoute component={ <BookingSearch/> }/>}/>
    <Route exact path="trips" element={<ProtectedRoute component={ <BookingTrips/> }/>}/>
    <Route exact path="bus" element={<ProtectedRoute component={ <BookingBus/> }/>}/>
    <Route exact path="review" element={<ProtectedRoute component={ <BookingReviewOrder/> }/>}/>
    <Route exact path="confirmed" element={<ProtectedRoute component={ <BookingConfirmed/> }/>}/>
  </Route>
)

export default routes
export const Colors = {
  primary: '#1D2A7B',
  secondary: '#4F5CAD',
  icon: '#6E80A9',
  icon_light: '#96A8D1',

  text: {
    primary: '#212121',
    primary_important: '#1D2A7B',
    secondary: '#6A6A6A',
    disabled: '#FFFFFF',
  },

  tertiary: '#F2F2F2',
  important: '#FF5F00',

  disabled: '#D4D4D4',

  done: '#2CC682',

  notice_background: '#F4F7FC',

  primary_gradient: 'linear-gradient(180deg, #1F3599, 0%, #172B85, 100%)',
}
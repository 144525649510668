import React, { createRef, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
//import { GlobalDebug } from './utilities/remove-console'

import Login from './modules/dashboard/Login'
import Dashboard from './modules/dashboard/Dashboard'
import ProtectedRoute from './components/navigation/PrivateRoute'
import ReservationRoutes from './modules/reservations/routes'
import BookingRoutes from './modules/booking/routes'
import ReprintRoutes from './modules/reprints/routes'
import ReservationTripsRoutes from './modules/reservationTrips/routes'
import adminTiketsRoutes from './modules/adminTikets/routes'

//import { Loader } from './components/transpais'

// eslint-disable-next-line import/no-anonymous-default-export
//export default () => <PinpadProvider>
//  <App/>
//</PinpadProvider>
import './scss/Layout.scss'
import './scss/Animations.scss'
import {
  BASE_URL,
  // IDLE_TIMEOUT_SECONDS,
  IDLE_PROMPT_SECONDS,
  SAVER_TIMEOUT_SECONDS,
  IDLE_DONE_TIMEOUT_SECONDS, IDLE_DONE_PROMPT_SECONDS, RELOAD_TOUCH_COUNT, DEBUG_TIMERS, DEBUG_PINPAD
} from './utilities/constants'
import { useAtom } from 'jotai'
import { idleDoneTimerPaused, idleTimerPaused, onAdmin, 
  bookingSessionDepartureBlockedTickets, 
  bookingSessionReturningBlockedTickets,
  bookingSessionTripConfig,
  bookingSessionReturningTripId,
  bookingSessionDepartureTripId,
  claveUsuario,
  usuarioID,
  puntoVentaID,
  estacionID,
  accessToken,
  TranspaisIDToken,
  nombrePuntoVenta
} from './utilities/atoms'
import transpais from './services/transpais'
import { Countdown } from './components/transpais'
import { useModal } from './contexts/modal.context'
import TranspaisScreenSaver from './components/transpais/TranspaisScreenSaver'
import styles from './modules/reservations/ReservationLookup.module.scss'
import Keyboard from 'react-simple-keyboard'
import { useLogger } from './contexts/logger.context'
import { useModalView } from './contexts/modalview.context'

const App = () => {

  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)

  const adminPass = createRef()
  const idleDoneTimeOut = createRef(false)

  const [idleTimeDebug, setIdleTimeDebug] = useState(0)
  const [idleDoneTimeDebug, setIdleDoneTimeDebug] = useState(0)
  const [idleScreenDebug, setIdleScreenDebug] = useState(0)

  const [isOnAdmin, setIsOnAdmin] = useAtom(onAdmin)
  const [idlePaused, setIdlePaused] = useAtom(idleTimerPaused)
  const [idleDonePaused, setIdleDonePaused] = useAtom(idleDoneTimerPaused)

  const navigate = useNavigate()
  const location = useLocation()
  const {showModal, hideModal, setupModal} = useModal()
  const {destroyModalView} = useModalView()
  const {loggerDebug, loggerLog} = useLogger()

  const [showScreen, setShowScreen] = useState(false)
  const [showLogout, setShowLogout] = useState(false)

  //For logout set variables on null
  const [cveUsuario, setCveUsuario] = useAtom(claveUsuario)
  const [usuarioId, setUsuarioId] = useAtom(usuarioID)
  const [puntoVentaId, setPuntoVentaId] = useAtom(puntoVentaID)
  const [estacionId, setEstacionId] = useAtom(estacionID)
  const [token, setToken] = useAtom(accessToken)
  const [transpaisIDToken, setTranspaisIDToken] = useAtom(TranspaisIDToken)
  const [nombPuntoVenta, setNombPuntoVenta] = useAtom(nombrePuntoVenta)

  /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
  useEffect(() => {
    loggerDebug(process.env.REACT_APP_ENVIRONMENT)

    //if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    //  GlobalDebug(false, true)
    //}
  }, [])

  const onIdle = () => {
    forceReset()
  }

  const onPrompt = () => {
    loggerLog('prompting the user')
    if (location.pathname !== '/' && location.pathname !== '/login') {
      idleDoneTimeOut.current = true
      //setShowIdleModal(true)
      //loggerLog(!idlePaused, !idleDonePaused)
      setupModal({
        title: '¿Aún estás ahí?',
        content: <p>Si no, cerraremos esta sesión en: <Countdown
          date={ Date.now() + (idlePaused ? !idleDonePaused ? idleDoneTimer.getRemainingTime() : '' : idleTimer.getRemainingTime()) }
          type={ 'simple' } format={ 'm:s' }/></p>,
        actions: {
          positive: {
            title: 'Aquí sigo',
            onClick: () => {
              idleDoneTimeOut.current = false
              keepActive()
              hideModal()
            }
          }
        }
      })
      showModal()
    }
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
    //loggerLog('user is active', event)
    //setShowIdleModal(false)
    //hideModal()
  }

  const onAction = (event) => {
    //loggerLog('user did something', event)
    if (!idleTimer.isPrompted()) {
      //loggerDebug('puedo activarme')
      keepActive()
    }
  }

  const idleTimer = useIdleTimer({
    // timeout: 1000 * IDLE_TIMEOUT_SECONDS,
    promptTimeout: 1000 * IDLE_PROMPT_SECONDS,
    // onPrompt,
    onIdle,
    onActive,
    onAction,
    debounce: 250,
    startManually: true,
    name: 'activity-timer'
  })

  const idleDoneTimer = useIdleTimer({
    timeout: 1000 * IDLE_DONE_TIMEOUT_SECONDS,
    promptTimeout: 1000 * IDLE_DONE_PROMPT_SECONDS,
    // onPrompt,
    onIdle,
    onActive,
    onAction,
    debounce: 250,
    startManually: true,
    name: 'done-timer'
  })

  const onScreenIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    //loggerDebug('Idle')
    //loggerDebug(location.pathname)
    loggerDebug('showScreenSaver')
    //forceReset()
    hideModal()
    destroyModalView()
    idleTimer.pause()
    setShowScreen(true)
  }

  const onScreenActive = (event) => {
    loggerLog('back')
    //keepActive()
    idleTimer.activate()
    setShowScreen(false)
  }

  const onScreenAction = (event) => {
    //loggerLog('user did something', event)
  }

  const screenSaverTimer = useIdleTimer({
    timeout: 1000 * SAVER_TIMEOUT_SECONDS,
    //promptTimeout: 1000 * IDLE_PROMPT_SECONDS,
    //onPrompt,
    onIdle: onScreenIdle,
    onActive: onScreenActive,
    onAction: onScreenAction,
    debounce: 250,
    startManually: true,
    name: 'screen-saver-timer'
  })

  useEffect(() => {
    if (idlePaused === true) {
      loggerDebug('pause timer')
      idleTimer.pause()
      screenSaverTimer.pause()
    } else {
      loggerDebug('start timer')
      idleTimer.start()
      screenSaverTimer.start()
    }
  }, [idlePaused])

  useEffect(() => {
    if (!showScreen) {
      idleTimer.activate()
    }
  }, [showScreen])

  useEffect(() => {
    if (idleDonePaused === false) {
      setIdlePaused(true)
      loggerDebug('pause timer y start done')
      idleDoneTimer.start()
    } else {
      setIdlePaused(false)
      loggerDebug('start timer and done')
      idleDoneTimer.pause()
    }
  }, [idleDonePaused])

  const logout = () => {
    setTranspaisIDToken('')
    setCveUsuario('')
    setUsuarioId(0)
    setPuntoVentaId(0)
    setEstacionId(0)
    setToken('')
    setNombPuntoVenta('')
    window.location = 'kioskexit1337'
  }

  const tickets = () => {
    navigate(`${ BASE_URL }/adminTikets/tickets`)
  }

  const keepActive = () => {
    //loggerDebug('keep')
    screenSaverTimer.activate()
    //loggerDebug('idle paused', idlePaused)
    if (!idlePaused && idleDonePaused) {
      //loggerDebug('Reset idleTimer')
      //console.log(idleTimer)
      idleTimer.activate()
    }
    //loggerDebug('done paused', idleDonePaused)
    if ((!idleDonePaused && idlePaused) && idleDoneTimeOut.current === false) {
      loggerDebug('reset done')
      idleDoneTimer.activate()
    }
  }

  const unblockTickets = (_tripId, _bookingTicket) => {
    transpais.unblock(
      _tripId, _bookingTicket.map((ticket) => ticket.id)
    ).then((response) => {
        setBookingDepartureBlockedTickets([])
        setBookingReturningBlockedTickets([])
    }).catch((error) => {
        setBookingDepartureBlockedTickets([])
        setBookingReturningBlockedTickets([])
    })
  }

  const forceReset = () => {
    if (bookingDepartureBlockedTickets.length > 0) {
      let _bookingTicket = bookingDepartureBlockedTickets
      unblockTickets(bookingDepartureTripId,_bookingTicket)
      if (bookingReturningBlockedTickets.length > 0) {
        _bookingTicket = bookingReturningBlockedTickets
        unblockTickets(bookingReturningTripId,_bookingTicket)
      }
    }
    hideModal()
    destroyModalView()
    setIdleDonePaused(true)
    //screenSaverTimer.activate()
    idleTimer.activate()
      //idleDoneTimer.activate()
    loggerDebug(location.pathname)
    if (location.pathname !== '/' && location.pathname !== '/login') {
      loggerDebug('forced')
      navigate(`${ BASE_URL }/`)
    }
  }

  document.addEventListener('contextmenu', event => event.preventDefault())

  useEffect(() => {
    const debug = setInterval(() => {
      setIdleTimeDebug(Math.round(idleTimer.getRemainingTime() / 1000))
      setIdleDoneTimeDebug(Math.round(idleDoneTimer.getRemainingTime() / 1000))
      setIdleScreenDebug(Math.round(screenSaverTimer.getRemainingTime() / 1000))
    }, 1000)

    return () => {
      clearInterval(debug)
    }
  }, [])

  //const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  const closeBrowser = () => {
    setIsOnAdmin(true)
    setupModal({
      title: 'Transpais',
      content: <>
        <p>Introduce la contraseña de administrador.
          <br/>
          <input className={ 'passInput' } type={ 'password' }
                 ref={ adminPass }/>
          <div
            className={ [styles.keyboardWrapper].join(' ') }>
            <Keyboard
              onChange={ (input) => {
                loggerDebug('Input changed', input)
              } }
              onKeyPress={ (button) => {
                loggerDebug('Button pressed', button)
                if (button === '{esc}') {
                  //setShowScreenKeyboard(false)
                } else if (button === '{bksp}') {
                  //setBarcodeInputValue(barcodeInputValue.slice(0, -1))
                  adminPass.current.value = adminPass.current.value.slice(0, -1)
                } else {
                  //setCodeKey(button)
                  adminPass.current.value += button
                }
              } }
              layout={ {
                default: [
                  '1 2 3', '4 5 6', '7 8 9', ' 0 {bksp}',
                ]
              } }
              display={ {
                '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
              } }
            />
          </div>
        </p>
      </>,
      actions: {
        positive: {
          title: 'Continuar',
          onClick: () => {
            loggerDebug(adminPass.current.value)
            if (adminPass.current.value === process.env.REACT_APP_ADMIN_PASS) {
              setShowLogout(true)
            } else {
              setIsOnAdmin(false)
              hideModal()
            }
          }
        },
        negative: {
          title: 'Cancelar',
          onClick: () => {
            setIsOnAdmin(false)
            hideModal()
          }
        },
      }
    })
    showModal()
  }

  const close = () => {
    setupModal({
      title: 'Transpais',
      content: <>
        <p>Selecciona la opción deseada.
          <br/>
        </p>
      </>,
      cancelable: true,
      actions: {
        extra: {
          title: 'Cerrar kiosco',
          onClick: () => {
            loggerDebug('Saliendo del kiosco')
            window.location = 'kioskexit1337'
          }
        },
        positiveExtra: {
          title: 'Tickets',
          onClick: () => {
            tickets()
            hideModal()
          }
        },
        // extraTwo: {
        //   title: 'Logout',
        //   onClick: () => {
        //     loggerDebug('logout kiosco')
        //     logout()
        //   }
        // },
      }
    })
    showModal()
    setShowLogout(false)
  }

  useEffect(() => {
    if(showLogout) {
      close()
    }
  },[showLogout])

  return (
    <>
      {DEBUG_TIMERS && <div className={'timersDebug'}>
        <p>idle: {idleTimeDebug}</p>
        <p>done: {idleDoneTimeDebug}</p>
        <p>screen: {idleScreenDebug}</p>
        <p>pinpad: {DEBUG_PINPAD ? 'Si' : 'No'}</p>
      </div>}
      <div className={ 'clicker' } onClick={ (e) => {
        if (e.detail === RELOAD_TOUCH_COUNT) {
          closeBrowser()
        }
      } }></div>
      <TranspaisScreenSaver show={ showScreen }/>
      <Routes>
        <Route exact path="/login" element={ <Login/> }/>
        <Route exact path="/" element={ <ProtectedRoute component={ <Dashboard/> }/> }/>
        { ReservationRoutes }
        { BookingRoutes }
        { ReprintRoutes }
        { ReservationTripsRoutes}
        { adminTiketsRoutes }
      </Routes>
    </>
  )
}

export default Sentry.withProfiler(App)

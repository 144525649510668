import React, { forwardRef } from 'react'
import { QRCode } from 'react-qrcode-logo'

import styles from './ReservationPrintableTicket.module.scss'
import {useAtom} from 'jotai'
import {nombrePuntoVenta, TranspaisIDToken} from '../../utilities/atoms'
import { currency } from '../../components/transpais/helpers/currency'
import {getTID} from "../../utilities/token";

const ReservationPrintableTicket = forwardRef(({ticket, payment, confirmation}, ref) => {
  const [transpaisIDToken,] = useAtom(TranspaisIDToken)
  const [nombPuntoVenta,] = useAtom(nombrePuntoVenta)

  return (
    <div ref={ref} className={styles.printable}>
      <div className={styles.datetime}>
        <div className={styles.date}>{payment.date}</div>
        <div className={styles.id}>{nombPuntoVenta}</div>
        <div className={styles.time}>{payment.time}</div>
      </div>
      <header className={styles.header}>
        <h1 className={styles.company}>{ ticket?.empresa_nombre || ticket?.razonsocial } - { ticket.empresa_rfc }</h1>
        <p>{ticket.direccion}</p>
      </header>
      <div className={styles.userData}>
        <div className={[styles.spread, styles.mb10].join(' ')}>
          <div className={styles.data}>Pasajero: <strong className={styles.big}>{ticket.nombPasajero}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}>Origen: <strong>{ticket.descOrigen}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}>Destino: <strong>{ticket.descDestino}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}>Fecha: <strong>{ticket.fecViaje}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}>Hora: <strong>{ticket.horaViaje}</strong></div>
          {/*<div className={styles.data}><strong>ID:</strong> {ticket.corridaId}</div>*/}
        </div>
        <div className={styles.split}>
          <div className={styles.data}>Unidad: <strong>-</strong></div>
          {/*<div className={styles.data}><strong>Asiento:</strong> {ticket.numAsiento}</div>*/}
          {/*<div className={styles.data}>{ticket.descClase}</div>*/}
        </div>
        <div className={styles.split}>
          {/*<div className={styles.data}><strong>Unidad:</strong></div>*/}
          <div className={styles.data}>Asiento: <strong>{ticket.numAsiento}</strong></div>
          <div className={[styles.data, styles.center].join(' ')}><span className={styles.important}>{ticket.tipoPasajero}</span></div>
          <div className={[styles.data, styles.right].join(' ')}><span className={styles.important}>{ticket.descClase}</span></div>
        </div>
      </div>
      <div className={styles.paymentWrapper}>
        <div className={styles.qr}>
          <QRCode value={ticket.ikey}
                  size={116}
                  quietZone={5}
                  fgColor={'#000000'}
                  bgColor={'#FFFFFF'}
                  /*qrStyle={'dots'}
                  eyeRadius={[
                    {
                      outer: [30, 30, 0, 30],
                      inner: 2,
                    },
                    {
                      outer: [30, 30, 30, 0],
                      inner: 2,
                    },
                    {
                      outer: [30, 0, 30, 30],
                      inner: 2,
                    },
                  ]}*/
                  //logoImage={require('../../assets/images/icon.png')}
                  //logoWidth="24"
                  removeQrCodeBehindLogo={false}/>
        </div>
        <div className={styles.payment}>
          <div className={[styles.split, styles.fixed].join(' ')}>
            <div className={styles.data}>Pasaje:</div>
            <div className={[styles.data, styles.right].join(' ')}>{currency(ticket.precio)}</div>
          </div>
          <div className={[styles.split, styles.fixed].join(' ')}>
            <div className={styles.data}>Servicios:</div>
            <div className={[styles.data, styles.right].join(' ')}>{currency(0)}</div>
          </div>
          <div className={[styles.split, styles.fixed].join(' ')}>
            <div className={[styles.data].join(' ')}><strong>Total:</strong></div>
            <div className={[styles.data, styles.right].join(' ')}><strong>{currency(ticket.precio)}</strong></div>
          </div>

          <div className={styles.services}>
            <div className={[styles.split, styles.fixed].join(' ')}>
              <div className={styles.data}>Asistencia médica:</div>
              <div className={[styles.data, styles.right].join(' ')}>{currency(0)}</div>
            </div>
            <div className={[styles.split, styles.fixed].join(' ')}>
              <div className={styles.data}>Reembolso flexible:</div>
              <div className={[styles.data, styles.right].join(' ')}>{currency(0)}</div>
            </div>
            <div className={[styles.split, styles.fixed].join(' ')}>
              <div className={styles.data}>Box lunch:</div>
              <div className={[styles.data, styles.right].join(' ')}>{currency(0)}</div>
            </div>
            {/*<div className={[styles.split, styles.fixed].join(' ')}>*/}
            {/*  <div className={styles.data}><strong>IVA:</strong></div>*/}
            {/*  <div className={styles.data}>{currency(0)}</div>*/}
            {/*</div>*/}

            <div className={[styles.split, styles.fixed].join(' ')}>
              <div className={[styles.data].join(' ')}>{confirmation.formaPago1}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.split, styles.folios].join(' ')}>
        <div className={styles.data}><strong>IKEY:</strong> {ticket.ikey}</div>
        <div className={styles.data}><strong>FOLIO:</strong> {ticket.numFolioSistema}</div>
      </div>
      <div className={styles.disclaimer}>
        <p>*Boleto válido para la fecha y hora indicada.</p>
        <p>Si tu boleto fue adquirido con algún descuento, es necesario que presentes al operador la documentación
          que avale dicho descuento, de lo contrario deberás cubrir la diferencia correspondiente al costo del pasaje.</p>
        <p>Conserva durante todo tu viaje esta contraseña para tener derecho al seguro de viajero.</p>
        <p>La responsabilidad por pérdida o daño al equipaje procederá solo con el boleto debidamente documentado y
          registrado y con el talón entregado por el transportista; se limita al valor real que no debe ni puede exceder
          de 20 salarios mínimos diarios vigentes en la Ciudad de México, a no ser que se declare un valor superior y
          se pague cada vez que se entregue el equipaje al seguro correspondiente.</p>
      </div>
      <hr className={styles.cut}/>
      <div className={styles.userData}>
        <div className={styles.spread}>
          <div className={styles.data}><strong>Pasajero:</strong> {ticket.nombPasajero}</div>
          <div className={styles.data}><strong>{ticket.tipoPasajero}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}><strong>De:</strong> {ticket.descOrigen}</div>
          <div className={styles.data}><strong>A:</strong> {ticket.descDestino}</div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}><strong>Fecha:</strong> {ticket.fecViaje}</div>
          <div className={styles.data}><strong>Hora:</strong> {ticket.horaViaje}</div>
          <div className={styles.data}><strong>PIN:</strong> {getTID(transpaisIDToken)}</div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}><strong>Unidad:</strong></div>
          <div className={styles.data}><strong>Asiento:</strong> {ticket.numAsiento}</div>
          <div className={styles.data}>{ticket.descClase}</div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}><strong>Folio:</strong> {ticket.numFolioSistema}</div>
          <div className={styles.data}>{confirmation.formaPago1}</div>
          <div className={styles.data}><strong>{currency(ticket.precio)}</strong></div>
        </div>
      </div>
      <div className={[styles.datetime, styles.last].join(' ')}>
        <div className={styles.date}>{payment.date}</div>
        <div className={styles.id}>{nombPuntoVenta}</div>
        <div className={styles.time}>{payment.time}</div>
      </div>
      <hr className={[styles.cut, styles.cutMargin].join(' ')}/>
    </div>
  )
})

export default ReservationPrintableTicket

import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import moment from 'moment'

import { Button, IconButton, Loader } from '../../components/transpais'
import ReservationTicket from './ReservationTicket'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL, RESERVATION_LIMIT_TIME, DEBUG_PINPAD } from '../../utilities/constants'

import {
  accessToken,
  estacionID,
  puntoVentaID,
  reservationConfirmation,
  reservationFolio,
  pinpadPayment,
  reservationTickets, usuarioID,
} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'

import gateway from '../../services/gateway'

import '../../scss/Layout.scss'
import global from './ReservationGlobal.module.scss'
import styles from './ReservationReview.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLogger } from '../../contexts/logger.context'
import { Breadcrumbs } from '@sentry/react'
import { currency } from '../../components/transpais/helpers/currency'

const ReservationReview = () => {

  const ticketsArea = useRef(null)

  const redondo = useRef(false)
  const viajeIda = useRef(null)
  const viajeRegreso = useRef(null)

  const [localLoading, setLocalLoading] = useState(false)
  const [token,] = useAtom(accessToken)
  const [usuario_id,] = useAtom(usuarioID)
  const [punto_venta_id,] = useAtom(puntoVentaID)
  const [estacion_id,] = useAtom(estacionID)
  const [tickets, setTickets] = useAtom(reservationTickets)
  const [folio, setFolio] = useAtom(reservationFolio)
  const [, setPayment] = useAtom(pinpadPayment)
  const [isConfirming, setIsConfirming] = useState(false)
  const [confirmation, setConfirmation] = useAtom(reservationConfirmation)

  const [currentTicketsScroll, setCurrentTicketsScroll] = useState(0)
  const [showScrollBottom, setShowScrollBottom] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSetDataCharge, setCanSetDataCharge] = useState(false)

  //const alert = withReactContent(Swal)
  const navigate = useNavigate()
  const {showModal, hideModal, setupModal} = useModal()
  const {loggerDebug, loggerLog, loggerError} = useLogger()

  // const [charge, setCharge] = useState()
  const {loading, readCard, charge, companyName, onError} = usePinpad()

  const dataCharge = {
    "reference": "KV010054798390-Y28D",
    "response": "approved",
    "foliocpagos": "100322235",
    "auth": "791700",
    "cd_response": "00",
    "cd_error": "",
    "nb_error": "",
    "time": "17:03:58",
    "date": "13/12/2022",
    "nb_company": "PRUEBAS INTEGRACIONES DLL",
    "nb_merchant": "7628597 VMC",
    "nb_street": "CORREGIDORA 92 COL. ZACAYUCAN PENA POBRE, DF",
    "cc_type": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "tp_operation": "VENTA",
    "cc_name": "",
    "cc_number": "4240",
    "cc_expmonth": "01",
    "cc_expyear": "26",
    "amount": "175.00",
    "voucher_comercio": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n@cnn 9249TDUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n@br \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "voucher_cliente": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@br \n@cnb -C-L-I-E-N-T-E- \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "friendly_response": "Agregado en QA",
    "appid": "A0000000041010",
    "appidlabel": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "arqc": "04F98E9383517767"
  }

  const cancel = () => {
    setupModal({
      title: 'Atención',
      content: <p>¿Estás seguro de cancelar la operación?</p>,
      actions: {
        positive: {
          title: 'Continuar en el pago',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Cancelar operación',
          onClick: () => {
            setTickets([])
            setFolio('')
            navigate(`${ BASE_URL }/reservations/lookup`)
            hideModal()
          }
        },
      }
    })
    showModal()
  }

  const willPay = () => {
    const expiration = moment(tickets[0].fecHorLiberaReservacion, 'DD-MM-YYYY HH:mm').subtract(RESERVATION_LIMIT_TIME, 'seconds') //"06/12/2022 18:19"

    if (!moment().isBefore(expiration)) {
      loggerError(`Error for ${ folio }, no hay suficiente tiempo para realizar tu pago ${ tickets[0].fecHorLiberaReservacion }.`)
      setupModal({
        title: 'Atención',
        content: <p>Tu reserva está próxima a expirar, no es posible continuar con el proceso de pago.</p>,
      })
      showModal()
      return
    }

    const ticketsToPayment = tickets.filter((ticket) => {
      return ticket.selected
    })
    const validatePaymentTicket = ticketsToPayment.some(item => [1, 3, 4, 5].includes(item.categoriaId))

    const unpaidTickets = tickets.filter((ticket) => {
      return !ticket.selected
    })
    let ticketsToPay = unpaidTickets
    if (ticketsToPay && ticketsToPay.length > 0) {
      ticketsToPay = unpaidTickets.some(item => [1, 3, 4, 5].includes(item.categoriaId))
    } else {
      ticketsToPay = true
    }
    
    if (validatePaymentTicket && ticketsToPay) {
      setupModal({
      title: 'Pago',
      content: <>
        <p>¿Deseas continuar con el pago de tu reserva?</p>
        <p className={ styles.importantText }>Sigue las instrucciones en el lector de tarjeta</p>
      </>,
      actions: {
        positive: {
          title: 'Pagar',
          onClick: () => {
            if ( DEBUG_PINPAD  === 'Si') {
              setCanSetDataCharge(true)
            }
            const reference = `KR${ folio }-${ Math.random().toString(36).slice(2, 6) }`
            loggerDebug(reference)
            const total = tickets.reduce((accumulator, object) => {
              const precio = object.selected ? object.precioBoleto : 0
              return accumulator + precio
            }, 0)
            //continueDebug(reference, total)
            onError((type, code, message) => {
              setCanSetDataCharge(false)
              let msg = null
              loggerDebug(type, code, message)
              switch (code) {
                case '10':
                  msg = <p>Haz cancelado la operación, puedes intentarlo de nuevo.</p>
                  break
                case '11':
                  msg = <p>No fue posible leer tu tarjeta en el tiempo establecido, inténta pagar una
                    vez más.</p>
                  break
                case '16':
                  msg = <p>No fue posible leer tu tarjeta, inténtalo nuevamente.</p>
                  break
                case '408':
                  msg = <p>{ message }</p>
                  break
                default:
                //msg = <p>Otro: {code}</p>
              }
              loggerLog(msg)
              setupModal(genericErrorMessage(msg))
              showModal()
            })
            readCard(reference, total)
            //readCard(reference, 151000)
            //DEBUG: comentar aqui
            // setupModal(genericErrorMessage(<p>No fue posible leer tu tarjeta, inténtalo nuevamente.</p>, () => {
            //   waitingPayment(false)
            //   hideModal()
            //   console.log('entro console log --------------------')
            // }))
            // showModal()
            // setTimeout( () => {
              // setCharge(
              //   {
              //     "reference": "KV010054798390-Y28D",
              //     "response": "approved",
              //     "foliocpagos": "100322235",
              //     "auth": "791700",
              //     "cd_response": "00",
              //     "cd_error": "",
              //     "nb_error": "",
              //     "time": "17:03:58",
              //     "date": "13/12/2022",
              //     "nb_company": "PRUEBAS INTEGRACIONES DLL",
              //     "nb_merchant": "7628597 VMC",
              //     "nb_street": "CORREGIDORA 92 COL. ZACAYUCAN PENA POBRE, DF",
              //     "cc_type": "DEBITO/TOKA INTERNACIONAL/MasterCard",
              //     "tp_operation": "VENTA",
              //     "cc_name": "",
              //     "cc_number": "4240",
              //     "cc_expmonth": "01",
              //     "cc_expyear": "26",
              //     "amount": "175.00",
              //     "voucher_comercio": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n@cnn 9249TDUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n@br \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
              //     "voucher_cliente": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@br \n@cnb -C-L-I-E-N-T-E- \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
              //     "friendly_response": "Agregado en QA",
              //     "appid": "A0000000041010",
              //     "appidlabel": "DEBITO/TOKA INTERNACIONAL/MasterCard",
              //     "arqc": "04F98E9383517767"
              //   }
              // )
            // }, 10000 )
            //DEBUG: comentar hasta aqui

            hideModal()
          }
        },
        negative: {
          title: 'No'
        }
      }
    })
    showModal()
    } else {
      setupModal({
        title: 'Atención',
        content: <><p>Para garantizar la seguridad y el bienestar de todos los pasajeros, hemos establecido la siguiente regla:</p>
        <p>Solo puedes realizar el pago de boletos para menores o mascotas <strong>junto con, al menos, un boleto de adulto</strong>. Esta medida se implementa para asegurar que los pasajeros más jóvenes o las mascotas viajen acompañados y reciban la atención adecuada durante el trayecto.</p>
        <p>Si necesitas adquirir boletos para menores o mascotas, asegúrate de incluir también un boleto de adulto en tu transacción. ¡Gracias por tu comprensión!</p></>,
      })
      showModal()
    }
  }

  useEffect(() => {
    let _charge = charge
    if ( canSetDataCharge ) {
      _charge = dataCharge
    }
    if (_charge != null) {
      setPayment(_charge)
      //loggerDebug('set 1')
      setLocalLoading(true)
      if (!isConfirming) {
        setIsConfirming(true)

        const ticketsToPayment = tickets.filter((ticket) => {
          return ticket.selected
        })

        const total = ticketsToPayment.reduce((accumulator, object) => {
          return accumulator + object.precioBoleto
        }, 0)

        confirmReservation(token, folio, ticketsToPayment, total, _charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id)

      }
    }
  }, [charge, canSetDataCharge])

  const confirmReservation = (token, folio, ticketsToPayment, total, charge, correo, usuario_id, punto_venta_id, estacion_id, retries = 5) => {
    gateway.reservationConfirm(token, folio, ticketsToPayment, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id)
      .then((response) => {
        const data = response.data
        loggerDebug(data)
        if (data.confirmada === false || data.hayError === true) {
          Sentry.setContext('Confirmation', {
            token,
            folio,
            tickets: ticketsToPayment,
            total,
            charge,
            correo: tickets[0].correo,
            usuario_id,
            punto_venta_id,
            estacion_id,
            data
          })
          Sentry.addBreadcrumb({
            category: 'ibus',
            message: 'Reintento confirmar',
            level: 'error',
          })
          Sentry.captureMessage('Reintento confirmar en respuesta normal.')
          if (retries >= 0) {
            confirmReservation(token, folio, ticketsToPayment, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
          } else {
            setupModal({
              title: 'Atención',
              content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
            })
            showModal()
            setLocalLoading(false)
            setIsConfirming(false)
          }
          return
        }
        setConfirmation(data)
      })
      .catch((error) => {
        setCanSetDataCharge(false)
        Sentry.setContext('Confirmation', {
          token,
          folio,
          tickets: ticketsToPayment,
          total,
          charge,
          correo: tickets[0].correo,
          usuario_id,
          punto_venta_id,
          estacion_id,
          error
        })
        Sentry.addBreadcrumb({
          category: 'ibus',
          message: 'Reintento confirmar',
          level: 'error',
        })
        Sentry.captureException(error)
        if (retries >= 0) {
          confirmReservation(token, folio, ticketsToPayment, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
        } else {
          setupModal({
            title: 'Atención',
            content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
          })
          showModal()
          setLocalLoading(false)
          setIsConfirming(false)
        }
      })
  }

  useEffect(() => {
    if (confirmation != null && Object.keys(confirmation).length !== 0) {
      loggerDebug('Confirmation', confirmation)
      loggerDebug('set false')
      setLocalLoading(false)
      navigate(`${ BASE_URL }/reservations/confirmed`)
    }
  }, [confirmation])

  useEffect(() => {
    loggerDebug('Proceso boletos')
    let fecha = null
    tickets.forEach(ticket => {
      if (fecha == null) {
        fecha = ticket.fecHorViaje
      } else if (fecha !== ticket.fecHorViaje) {
        redondo.current = true
        const viaje1 = moment(fecha, 'DD/MM/YYYY HH:mm')
        const viaje2 = moment(ticket.fecHorViaje, 'DD/MM/YYYY HH:mm')
        if (viaje1.isBefore(viaje2)) {
          viajeIda.current = viaje1
          viajeRegreso.current = viaje2
        } else {
          viajeIda.current = viaje2
          viajeRegreso.current = viaje1
        }
      }
    })
    if (redondo.current === false && fecha != null) {
      viajeIda.current = moment(fecha, 'DD/MM/YYYY HH:mm')
    }
  }, [])

  useEffect(() => {
    const exists = !!tickets.find((ticket) => {
      return ticket.selected === true
    })

    setCanContinue(exists)

    loggerDebug(tickets)
    const empresaId = tickets[0].itemDatosConexion.empresaId
    Sentry.setTag('company', empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX')
    Sentry.setContext('Reservation', {
      folio: folio,
      company: empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX',
      tickets: tickets.map((ticket) => {
        return ticket
      })
    })
    if (tickets) {
      tickets.map((ticket) => {
        ticket.seat = ticket.numAsiento
        ticket.tipo = ticket.categoriaId
        ticket.precio = ticket.precioBoleto
      })
    }
  }, [tickets])

  useEffect(() => {
    //loggerDebug(ticketsArea.current.scrollTop)
    //loggerDebug(ticketsArea.current.offsetHeight)
    //loggerDebug(ticketsArea.current.scrollHeight)
    if (ticketsArea.current.scrollHeight > ticketsArea.current.offsetHeight) {
      setShowScrollBottom(true)
    } else {
      setShowScrollBottom(false)
    }
  }, [ticketsArea])

  const handleScroll = (e) => {
    setCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  useEffect(() => {
    //loggerDebug(ticketsArea.current.scrollTop)
    //loggerDebug(ticketsArea.current.offsetHeight)
    //loggerDebug(ticketsArea.current.scrollHeight)
    if (currentTicketsScroll > 0) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false)
    }

    if ((ticketsArea.current.scrollTop + ticketsArea.current.offsetHeight) === ticketsArea.current.scrollHeight) {
      setShowScrollBottom(false)
    } else {
      setShowScrollBottom(true)
    }
  }, [currentTicketsScroll])

  return (
    <>
      <Helmet>
      </Helmet>
      {/*----Page----*/ }
      <Loader show={ loading || localLoading }/>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.toPay }>
              <FontAwesomeIcon
                icon={ solid('arrow-down') }
                bounce
                className={ styles.pointer }
              />
              <div className={ styles.pill }>
                <p>Selecciona los boletos que quieras pagar</p>
              </div>
            </div>
          </div>
          <div className={ styles.middle }>
            <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { viajeIda && tickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                                     redondo={ viajeIda.current && viajeRegreso.current }
                                     regreso={ viajeIda.current?.format('DD/MM/YYYY HH:mm') !== ticket.fecHorViaje }
                                     onClick={ (t) => {
                                       loggerDebug(t)
                                       t.selected = !t.selected
                                       const tmpTickets = tickets.slice()
                                       setTickets(tmpTickets)
                                     } }/>
                )) }
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
            </div>
            <div className={ styles.resume }>
              <div className={ styles.card }>
                <div className={ styles.cols }>
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Origen</h2>
                    <span className={ styles.value }>{ tickets[0].descOrigen }</span>
                  </div>
                  { redondo.current && <div className={ styles.redondo }>
                    <svg className={ [styles.icon].join(' ') }>
                      <use xlinkHref={ `#refresh` }/>
                    </svg>
                  </div> }
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Destino</h2>
                    <span className={ styles.value }>{ tickets[0].descDestino }</span>
                  </div>
                </div>
                <div className={ styles.cols }>
                  <div className={ styles.data }>
                    <h2>Fecha y hora de viaje</h2>
                    <span
                      className={ styles.value }>{ viajeIda.current?.format('DD/MM/YYYY HH:mm') }{ redondo.current &&
                      ' › ' + viajeRegreso.current?.format('DD/MM/YYYY HH:mm')
                    }</span>
                  </div>
                </div>
              </div>
              <div className={ styles.important }>
                <p>Tus boletos serán enviados al correo:</p>
                <span className={ styles.email }>{ tickets[0].correo }</span>
              </div>
              <div className={ [styles.card, styles.total].join(' ') }>
                <div>
                  <h2>Total a pagar</h2>
                  <span className={ styles.precio }>{ currency(tickets.reduce((accumulator, object) => {
                    const precio = object.selected ? object.precioBoleto : 0
                    return accumulator + precio
                  }, 0)) }</span>
                  <p>Podrás realizar tu pago utilizando únicamente tu tarjeta de débito o crédito,
                    visa o
                    mastercard.</p>
                  <div className={ styles.cardsLogos }>
                    <img src={ require('../../assets/images/visa.svg').default } alt="VISA"/>
                    <img src={ require('../../assets/images/mastercard.svg').default }
                         alt="mastercard"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
            <Button onClick={ cancel } type="destructive" left-icon="trash">Cancelar</Button>
          </div>
          <div className={ global.middle }>

          </div>
          <div>
            <Button type="positive" right-icon="chevron-right" disabled={ !canContinue }
                    onClick={ willPay }>Pagar</Button>
          </div>
        </footer>
      </section>
    </>
  )
}

export default ReservationReview

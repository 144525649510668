import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'

const TranspaisTripAmenity = {
  icon: (image) => {
    switch (image) {
      case 'tag_petfriendly':
        return solid('paw')
      default:
        return null
    }
  },
}

export default TranspaisTripAmenity
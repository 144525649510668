import React from 'react'

export const genericErrorMessage = (message, callback = null) => {
  console.log(message)
  const msg = message ?? <p>Lo sentimos, no fue posible realizar el cobro, por favor utiliza una tarjeta diferente.</p>
  return ({
    title: 'Pago no realizado',
    content: <>
      { msg }
    </>,
    actions: {
      negative: {
        title: 'Cerrar',
        onClick: callback !== null ? () => callback() : null
      }
    }
  })
}
export const SAVER_TIMEOUT_SECONDS = 60
// export const IDLE_TIMEOUT_SECONDS = 0
export const IDLE_PROMPT_SECONDS = 11
export const IDLE_DONE_TIMEOUT_SECONDS = 600
export const IDLE_DONE_PROMPT_SECONDS = 6

export const BASE_URL = ''
export const RESERVATION_CODE_MIN_SIZE = 12
export const RESERVATION_CODE_MAX_SIZE = 12
export const RESERVATION_LIMIT_TIME = 300

export const RELOAD_TOUCH_COUNT = 3
export const LOADING_TIMEOUT = 90000

export const DEBUG_TIMERS = process.env.REACT_APP_DEBUG_TIMERS
export const DEBUG_PINPAD = process.env.REACT_APP_DEBUG_PINPAD
export const DEBUG_SEND_CONSULTA = process.env.REACT_APP_DEBUG_SEND_CONSULTA
export const VER_APP = process.env.REACT_APP_VER_APP

const categoria = {
  "Adulto": 1,
  "Menor": 2,
  "Adultomayor": 3,
  "Estudiante": 4,
  "Profesor": 5,
  "Mascota": 6,
}

export class TicketType {
  static Adulto = 1
  static Menor = 2
  static AdultoMayor = 3
  static Estudiante = 4
  static Profesor = 5
  static Mascota = 6
  static Preferente = 368
  

  static label(id) {
    if ( typeof id !== 'number') {
      id = categoria[id]
    }
    
    switch (id) {
      case this.Adulto: return 'Adulto'
      case this.Menor: return 'Menor'
      case this.AdultoMayor: return 'Adulto Mayor'
      case this.Estudiante: return 'Estudiante'
      case this.Profesor: return 'Profesor'
      case this.Mascota: return 'Mascota'
      case this.Preferente: return 'Preferente'
      default: return 'Desconocido'
    }
  }

  static icon(id) {
    if ( typeof id !== 'number') {
      id = categoria[id]
    }

    switch (id) {
      case this.Adulto: return 'user-tie'
      case this.Menor: return 'child'
      case this.AdultoMayor: return 'person-cane'
      case this.Estudiante: return 'user-graduate'
      case this.Profesor: return 'chalkboard-user'
      case this.Mascota: return 'paw'
      case this.Preferente: return 'wheelchair'
      default: return 'Desconocido'
    }
  }
}

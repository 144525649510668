import React, { useEffect, useRef, useState } from 'react'
import { useLogger } from '../../../contexts/logger.context'
import { useKey } from 'react-use'

import Keyboard from 'react-simple-keyboard'
import PlacesViewSection from './PlacesViewSection'

import styles from './PlacesView.module.scss'

const PlacesView = ({shortcuts = null, items, onChange}) => {

  const keyboard = useRef()
  const inputRef = useRef(null)
  const cursorPosition = useRef(0)
  const enableCursor = useRef(false)
  const [keyboardLayout, setKeyboardLayout] = useState('default')

  const {loggerDebug} = useLogger()

  const [search, setSearch] = useState('')
  const [searchParam] = useState(['label','cve'])
  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)

  const [filteredPlaces, setFilteredPlaces] = useState([])

  const selectPlace = (item) => {
    onChange(item)
  }

  const handleClick = () => {
    cursorPosition.current = inputRef.current.selectionStart
    if(cursorPosition.current === search.length) {
      enableCursor.current = false
    } else {
      enableCursor.current = true
    }
  }

  function onKeyboardKeyPress(button) {
    loggerDebug('Button pressed', button)
    if (button === '{esc}') {
      setShowScreenKeyboard(false)
    } else if (button === '{bksp}') {
      if (enableCursor.current) {
        if (cursorPosition.current > 0) {
          setSearch(search.slice(0, cursorPosition.current -1) + search.slice(cursorPosition.current))
          cursorPosition.current = cursorPosition.current - 1
        }
      }else{
        setSearch(search.slice(0, -1))
      }
      // setSearch(search.slice(0, -1))
    } else if (button === '{shift}' || button === '{lock}') {
      handleShift()
    } else {
      setCodeKey(button)
    }
  }

  const setCodeKey = (key) => {
    loggerDebug(key)
    //if (barcodeInputValue.length >= RESERVATION_CODE_MAX_SIZE) {
    //loggerDebug('exit and not setting new char')
    //return
    //}

    if (key !== 'Enter' && !(key.includes('{') && key.includes('}'))) {
      //if (!isReading) {
      //  setIsReading(true)
      //}
      enableCursor.current ? setKeyPosition(key) : setSearch(search + key)
      // setSearch(search + key)
    } else if (key === '{at}') {
      enableCursor.current ? setKeyPosition('@') : setSearch(search + '@')
      // setSearch(search + '@')
    } else if (key === '{space}') {
      enableCursor.current ? setKeyPosition(' ') : setSearch(search + ' ')
      // setSearch(search + ' ')
    } else if (key === '{dotcom}') {
      enableCursor.current ? setKeyPosition('.com') : setSearch(search + '.com')
      // setSearch(search + '.com')
    }
  }

  function setKeyPosition (key) {
    console.log(cursorPosition.current)
    console.log(search)
    let addSearch = search.substring(0, cursorPosition.current) + key + search.substring(cursorPosition.current)
    console.log(addSearch)
    let increment = key === '.com' ? 4 : 1
    cursorPosition.current = cursorPosition.current + increment
    setSearch(addSearch)
  }

  function handleShift() {
    loggerDebug(keyboard.current)
    const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default'
    setKeyboardLayout(newLayoutName)
  }

  useEffect(() => {
    if (search === '') {
      setFilteredPlaces(items)
    } else {
      const _items = items.filter((item) => {
        return searchParam.some((param) => {
          return (
            item[param]
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
          )
        })
      })
      setFilteredPlaces(_items)
    }
  }, [search])

  return (<div className={ styles.view }>
      <div className={ styles.container }>
        <div className={ styles.search }>
          <input type="text" placeholder="Escriba una ciudad o destino" value={ search }
                 onFocus={ () => setShowScreenKeyboard(true) }
                 onChange={ (e) => setSearch(e.target.value) }
                 ref={inputRef} onClick={handleClick}/>
        </div>

        { (shortcuts != null && search === '') &&
          <PlacesViewSection title={ 'Búsquedas recientes' } items={ shortcuts } onClick={ selectPlace }/> }
        <PlacesViewSection title={ '' } items={ filteredPlaces } onClick={ selectPlace }/>

      </div>

      <div className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ') }>
        <Keyboard keyboardRef={ r => (keyboard.current = r) }
          //onChange={onKeyboardChange}
                  onKeyPress={ onKeyboardKeyPress }
                  layoutName={ keyboardLayout }
                  layout={ {
                    default: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      '{blank} q w e r t y u i o p',
                      '{lock} a s d f g h j k l \u00f1 {blank}',
                      '{shift} z x c v b n m . - {shift}',
                      '{space} {at} {dotcom}',
                    ],
                    shift: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      'Q W E R T Y U I O P \u0308',
                      '{lock} A S D F G H J K L \u00d1 {blank}',
                      '{shift} {blank} Z X C V B N M _ {shift}',
                      '{space} {at} {dotcom}',
                    ],
                  } }
                  display={ {
                    '{at}': `<svg class="key-icon"><use xlink:Href="#at"/></svg>`,
                    '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                    '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                    '{blank}': ` `,
                    '{space}': ` `,
                    '{lock}': `<svg class="key-icon"><use xlink:Href="#font-case"/></svg>`,
                    '{shift}': `<svg class="key-icon"><use xlink:Href="#up"/></svg>`,
                    '{dotcom}': `.com`,
                  } }
        />
      </div>
    </div>
  )
}

export default PlacesView
import styled from 'styled-components'
import { rem } from '../../../styles/functions.styled'
import { FontWeight } from '../../../styles/typography.styled'

export const Footer = styled.footer`
  color: white;
  display: flex;
  flex-direction: row;
  font-size: ${rem(12)};
  font-weight: ${FontWeight.medium};
  justify-content: space-between;
  padding: 10px;
`
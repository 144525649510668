import React, { useEffect, useState, useRef } from 'react'

import styles from './TranspaisTimer.module.scss'
import { differenceInSeconds, parseISO } from 'date-fns'
import { useAtom } from 'jotai'
import { bookingSessionTripConfig } from '../../utilities/atoms'

const TranspaisTimer = ({variant, blur, active, timeout, onTimeout, waiting}) => {

  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [dateState, setDateState] = useState(new Date())

  const [remainingTime, setRemainingTime] = useState(timeout ? differenceInSeconds(parseISO(timeout), new Date()) : null)
  const [remainingTimeValue, setRemainingTimeValue] = useState('-')


  useEffect(() => {
    if (variant === 'clock') {
      const _clock = setInterval(() => setDateState(new Date()), 1000)
      return () => clearInterval(_clock)
    } else if (variant === 'timer') {
      const _timer = setInterval(() => {
        //console.log(timeout, parseISO(timeout), new Date())
        //console.debug(differenceInSeconds(parseISO(timeout), new Date()))
        if (remainingTime > 0) {
          setRemainingTime(differenceInSeconds(parseISO(timeout), new Date()))
        }
      }, 1000)
      return () => clearInterval(_timer)
    }
  }, [])

  //const [timer, setTimer] = useState('10:00')

  useEffect(() => {
    if (variant === 'timer') {
      if (remainingTime !== null) {
        if (remainingTime >= 0) {
          const total = remainingTime
          const seconds = Math.floor(total % 60)
          const minutes = Math.floor((total / 60) % 60)
          const hours = Math.floor(((total / 60) / 60) % 24)
          //console.debug(total, hours, minutes, seconds)

          let timeString = `${ minutes }:${ seconds < 10 ? `0${ seconds }` : seconds }`
          if (hours > 0) {
            if (minutes < 10) {
              timeString = `0${ timeString }`
            }
            timeString = `${ hours }:${ timeString }`
          }
          setRemainingTimeValue(`${ timeString }`)
        } else {
          console.log('done', remainingTime)
          setRemainingTimeValue(`0:00`)
          setRemainingTime(-1)
          if (active && !waiting) {
            onTimeout()
          }
        }
      } else {
        setRemainingTimeValue(`-`)
      }
    }
  }, [remainingTime])

  // const startTimer = (e) => {
  //   let {total, minutes, seconds}
  //     = getTimeRemaining(e)
  //   if (total >= 0) {

  // update the timer
  // check if less than 10 then we need to
  // add '0' at the beginning of the variable
  //     setTimer(
  //       // (hours > 9 ? hours : '0' + hours) + ':' +
  //       (minutes > 9 ? minutes : '0' + minutes) + ':'
  //       + (seconds > 9 ? seconds : '0' + seconds)
  //     )
  //   }
  // }

  // const clearTimer = (e) => {
  //
  //   // If you adjust it you should also need to
  //   // adjust the Endtime formula we are about
  //   // to code next
  //   setTimer('10:00')
  //
  //   // If you try to remove this line the
  //   // updating of timer Variable will be
  //   // after 1000ms or 1sec
  //   if (Ref.current) clearInterval(Ref.current)
  //   const id = setInterval(() => {
  //     startTimer(e)
  //   }, 1000)
  //   Ref.current = id
  // }

  // const getDeadTime = () => {
  //   let deadline = new Date()
  //
  //   // This is where you need to adjust if
  //   // you entend to add more time
  //   deadline.setSeconds(deadline.getSeconds() + 6000)
  //   return deadline
  // }
  //
  // useEffect(() => {
  //   clearTimer(getDeadTime())
  // }, [])

  const icon = (icon, theme) => {
    return (
      <div className={ styles.dayStatus }>
        <svg className={ [styles.icon, styles[theme]].join(' ') }>
          <use xlinkHref={ `#${ icon }` }/>
        </svg>
      </div>
    )
  }

  const clock = () => (
    <div className={ [styles.clock, blur && styles.blur].join(' ') }>
    <span>{ dateState.toLocaleString('es-MX', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }) }</span>
      { icon(dateState.getHours() >= 18 ? 'moon' : 'sun-bright', dateState.getHours() >= 18 ? 'dark' : 'light') }
    </div>
  )

  const timer = () => (
    <div className={ [styles.clock, styles.timer/*, bookingTripConfig.redondo !== false ? styles.timerRound : null*/, blur && styles.blur].join(' ') }>
      { icon('stopwatch', 'light') }
      <span className={styles.timer}>{ remainingTimeValue }</span>
      {/* { icon(dateState.getHours() >= 18 ? 'moon' : 'sun-bright', dateState.getHours() >= 18 ? 'dark' : 'light') } */ }
    </div>
  )

  const renderView = () => {
    switch (variant) {
      case 'clock':
        return clock()
      case 'timer':
        return timer()
      default:
        return 'foo'
    }
  }

  return renderView()
}

export default TranspaisTimer

/*----- Colors -----*/
/*export const colors = {
  call_to_action: `#2CC682`,
  call_to_action_secondary: `#22B876`,

  neutral: `#162687`,
  neutral_secondary: `#0F1955`,

  destructive: `#F44141`,
  destructive_secondary: `#d03737`,

  disabled: `#64748B`,
  text_disabled: `#94A3B8`,

  tertiary: `#F4F7FC`,
  body: `#6E80A9`,
}*/

export const Font = {
  default: "'Gotham', sans-serif"
}

export const FontWeight = {
  light: 300,
  regular: 400,
  medium: 600,
  bold: 700,
}
import React from 'react'
import './TranspaisIconButton.scss'

const TranspaisIconButton = (props) => {

  const icon = (icon) => {
    return (
      <span className="icon-wrapper">
          <svg className="icon">
            <use xlinkHref={`#${icon}`}/>
          </svg>
        </span>
    )
  }

  return (
    <button className={['transpais-icon-button', `transpais-icon-button-style-${props.type}`].join(' ')} onClick={props.onClick}>
      {icon(props.icon)}
    </button>)
}

export default TranspaisIconButton

import React, { useEffect, useRef, useState } from 'react'
import styles from './TranspaisInputAutocomplete.module.scss'


const TranspaisInputAutocomplete = ({items, onFocus, trigger = '', valueInput, onChange, handleClicked}) => {
  const [filteredItems, setFilteredItems] = useState([])
  const [search, setSearch] = useState('')
  const [showItems, setShowItems] = useState(false)
  const inputRef = useRef(null)

  const addItem = (event, message) => {
      let _item = valueInput.split(trigger)[0] + trigger + message
      onChange(_item)
      setShowItems(false)
      setSearch('')
  }
  const handleClick = () => {
    handleClicked(inputRef.current.selectionStart)
  }

  useEffect(() => {
      if (valueInput.slice(-1) === trigger && !showItems) {
          setFilteredItems(items)
          setShowItems(true)
      } else if (valueInput.indexOf(trigger) === -1 && showItems) {
          setShowItems(false)
      } else if(valueInput.indexOf(trigger) !== -1) {
          let _search = valueInput.slice(valueInput.indexOf(trigger) + 1)
          setSearch(_search)
      } else {
          setSearch('')
          setFilteredItems(items)
      }
  },[valueInput])

  useEffect(() => {
    if (search === '') {
      setFilteredItems(items)
    } else {
      const _items = items.filter(
      (el) => el.toLowerCase().includes(search.toLowerCase())
      )
      setFilteredItems(_items)
    }
  }, [search])

  const keyboardShow = (e, bool) => {
      onFocus(bool)
  }

  return (
      <>
      <input type="text" value={ valueInput } placeholder="Escribe tu correo electrónico"
          onFocus={ (e) => keyboardShow(e , true) }
          onChange={ (e) => {
          onChange(e.target.value)
          } }
          ref={inputRef}
          onClick={handleClick}
          />
      {
          showItems ? <div className={ styles.list }>
          { filteredItems.map((item) => (
          <div className={ styles.item } onClick={ event => addItem(event, item) }>
              <p className={ styles.mail }>{ valueInput.split(trigger)[0] }<span>@{ item }</span></p>
          </div>
          )) }
      </div> : null
      }
      </>
  )

}
export default TranspaisInputAutocomplete
import React, { useEffect, useReducer, useState } from 'react'

import styles from './TranspaisSegmentedControl.module.scss'

const TranspaisSegmentedControl = ({items, selected, onChange}) => {
  const [selectedIndex, setSelectedIndex] = useState(selected)

  const select = (index) => {
    setSelectedIndex(index)
    onChange(index)
  }

  useEffect(() => {
    setSelectedIndex(selected)
  }, [selected])

  return <div className={ styles.segmentedControl }>
    {items.map((item, index) => {
      return <div key={`sc_${index}`} className={ [styles.segmentedControlItem, selectedIndex === index ? styles.selected : null].join(' ')} onClick={() => select(index)}>{ item }</div>
    })}
  </div>
}

export default TranspaisSegmentedControl
import styled from 'styled-components'
import { colors, settings } from './_config.styled'

export const Modal = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
`

export const ModalContent = styled.div`
  background-color: white;
  border-radius: ${ settings.borderRadius };
  min-width: ${320 * settings.scale}px;
  padding: ${18 * settings.scale}px;
`

export const ModalHeader = styled.div`
  
`

export const ModalClose = styled.div`
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  color: #2E2E2E;
  display: flex;
  justify-content: end;
  height: 44px;
  position: relative;
`

export const ModalTitle = styled.h4`
  font-size: ${16 * settings.scale}px;
  margin: 0;
  text-align: center;
`

export const ModalBody = styled.div`
  color: ${colors.body};
  font-weight: 400;
  font-feature-settings: 'cv11' on;
  font-size: ${12 * settings.scale}px;
  line-height: 150%;
`

export const ModalFooter = styled.div`
  display: flex;
  gap: ${28 * settings.scale}px;
  justify-content: center;
  margin-top: 15px;
`
import React from 'react'
import TranspaisButton from './TranspaisButton'
import TranspaisIconButton from './TranspaisIconButton'
import TranspaisLoader from './TranspaisLoader'
import TranspaisModal from './TranspaisModal'
import TranspaisModalView from './TranspaisModalView'
import TranspaisCountdown from './TranspaisCountdown'
import TranspaisScreenSaver from './TranspaisScreenSaver'
import TranspaisTimer from './TranspaisTimer'
import TranspaisSegmentedControl from './TranspaisSegmentedControl'
import TranspaisActionFormField from './TranspaisActionFormField'
import TranspaisDeparture from './TranspaisDeparture'
import TranspaisCarrousel from './TranspaisCarrousel'
import TranspaisBusLayout from './TranspaisBusLayout'
import TranspaisBusSeat from './TranspaisBusSeat'
import TranspaisTripData from './TranspaisTripData'
import TranspaisTripAcotaciones from './TranspaisTripAcotaciones'
import TranspaisShoppingCart from './TranspaisShoppingCart'
import TranspaisInputAutocomplete from './TranspaisInputAutocomplete'

export const Button = (props) => {
  return <TranspaisButton { ...props }>{ props.children }</TranspaisButton>
}

export const IconButton = (props) => {
  return <TranspaisIconButton { ...props }/>
}

export const Loader = (props) => {
  return <TranspaisLoader { ...props }/>
}

export const Modal = (props) => {
  return <TranspaisModal { ...props }/>
}

export const ModalView = (props) => {
  return <TranspaisModalView { ...props }/>
}

export const Countdown = (props) => {
  return <TranspaisCountdown { ...props }/>
}

export const ScreenSaver = (props) => {
  return <TranspaisScreenSaver { ...props }/>
}

export const Timer = (props) => {
  return <TranspaisTimer { ...props }/>
}

export const SegmentedControl = (props) => {
  return <TranspaisSegmentedControl { ...props }/>
}

export const ActionFormField = (props) => {
  return <TranspaisActionFormField { ...props }/>
}

export const Departure = (props) => {
  return <TranspaisDeparture { ...props }/>
}

export const Carrousel = (props) => {
  return <TranspaisCarrousel { ...props }/>
}

export const TripData = (props) => {
  return <TranspaisTripData { ...props }/>
}

export const Acotaciones = (props) => {
  return <TranspaisTripAcotaciones { ...props }/>
}

export const BusLayout = (props) => {
  return <TranspaisBusLayout { ...props }/>
}

export const BusSeat = (props) => {
  return <TranspaisBusSeat { ...props }/>
}

export const ShoppingCart = (props) => {
  return <TranspaisShoppingCart { ...props }/>
}

export const InputAutocomplete = (props) => {
  return <TranspaisInputAutocomplete { ...props }/>
}
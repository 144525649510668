import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../../components/navigation/PrivateRoute'

import ReservationLookup from './ReservationLookup'
import ReservationReview from './ReservationReview'
import ReservationConfirmed from './ReservationConfirmed'
import { BASE_URL } from '../../utilities/constants'

const routes = (
  <Route exact path={`${BASE_URL}/reservations`}>
    <Route exact path="lookup" element={<ProtectedRoute component={ <ReservationLookup/>}/>}/>
    <Route exact path="review" element={<ProtectedRoute component={ <ReservationReview/>}/>}/>
    <Route exact path="confirmed" element={<ProtectedRoute component={ <ReservationConfirmed/>}/>}/>
  </Route>
)

export default routes

import React from 'react'
import { Link } from 'react-router-dom'
import './TranspaisButton.scss'

const TranspaisButton = (props) => {

  const icon = (icon) => {
    return (
      <span className="icon-wrapper">
          <svg className="icon">
            <use xlinkHref={`#${icon}`}/>
          </svg>
        </span>
    )
  }

  const button = (
    <span className={[`transpais-button-style-${props.type}`].join(' ')}>
      {props['left-icon'] && icon(props['left-icon'])}
      <span className="label">{props.children}</span>
      {props['right-icon'] && icon(props['right-icon'])}
    </span>
  )

  return props.to ? (
    <TranspaisButtonWrapperRoute {...props}>
      {button}
    </TranspaisButtonWrapperRoute>
  ) : (<div className={['transpais-button', props.disabled ? 'disabled' : null].join(' ')}
            onClick={props.onClick}>{button}</div>)
}

const TranspaisButtonWrapperRoute = ({disabled, to, children}) => {
  return (
    <Link to={to} className={['transpais-button', disabled ? 'disabled' : null].join(' ')}>
      {children}
    </Link>
  )
}

export default TranspaisButton

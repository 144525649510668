import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useReactToPrint } from 'react-to-print'

import { Button, IconButton, Loader } from '../../components/transpais'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL, DEBUG_PINPAD, VER_APP } from '../../utilities/constants'
import logo_cpagos_image from '../../assets/images/logo_cpagos.png'

import {
  accessToken,
  estacionID,
  puntoVentaID,
  reservationConfirmation,
  reservationFolio,
  pinpadPayment,
  reservationTickets,
  usuarioID,
  bookingSessionTickets,
  bookingSessionPayments,
  idleDoneTimerPaused,

} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'

import gateway from '../../services/gateway'

import '../../scss/Layout.scss'
import global from './BookingGlobal.module.scss'
import styles from './BookingConfirmed.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLogger } from '../../contexts/logger.context'
import { Breadcrumbs } from '@sentry/react'
import { currency } from '../../components/transpais/helpers/currency'
import SaleTicket from './SaleTicket'

const BookingConfirmed = () => {

  const {loggerDebug} = useLogger()
  const navigate = useNavigate()

  const printAll = useRef(false)
  const ticketsArea = useRef(null)
  const selectedTicket = useRef(null)
  const ticketsRefs = useRef([])

  //const [modalType, setModalType] = useState(null)
  //const [modalPositiveTitle, setModalPositiveTitle] = useState('Si')
  //const [modalNegativeTitle, setModalNegativeTitle] = useState('No')
  //const [modalContent, setModalContent] = useState(null)
  //const [selectedTicket, setSelectedTicket] = useState(null)
  const [idleDonePaused, setIdleDonePaused] = useAtom(idleDoneTimerPaused)

  const [wasPrinted, setWasPrinted] = useState(false)

  const [currentTicketsScroll, setCurrentTicketsScroll] = useState(0)
  const [showScrollBottom, setShowScrollBottom] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)

  // Todos los atoms para reset
  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayments, setBookingPayments] = useAtom(bookingSessionPayments)
  const [payment, setPayment] = useAtom(pinpadPayment)
  const {showTimer, hideTimer, setupTimer} = useTimer()

  const {reset, charge} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()
  const componentRef = useRef(null);
  const [voucherPrint, setVoucherPrint] = useState(false)
  const [voucherPrinted, setVoucherPrinted] = useState(false)

  const dataCharge = {
    "reference": "KV010054798390-Y28D",
    "response": "approved",
    "foliocpagos": "100322235",
    "auth": "791700",
    "cd_response": "00",
    "cd_error": "",
    "nb_error": "",
    "time": "17:03:58",
    "date": "13/12/2022",
    "nb_company": "PRUEBAS INTEGRACIONES DLL",
    "nb_merchant": "7628597 VMC",
    "nb_street": "CORREGIDORA 92 COL. ZACAYUCAN PENA POBRE, DF",
    "cc_type": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "tp_operation": "VENTA",
    "cc_name": "",
    "cc_number": "4240",
    "cc_expmonth": "01",
    "cc_expyear": "26",
    "amount": "175.00",
    "voucher_comercio": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n@cnn 9249TDUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n@br \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "voucher_cliente": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@br \n@cnb -C-L-I-E-N-T-E- \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "friendly_response": "Agregado en QA",
    "appid": "A0000000041010",
    "appidlabel": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "arqc": "04F98E9383517767"
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current, // Aquí pasamos la referencia al componente que queremos imprimir
    onBeforePrint: () => {
      console.log('Antes de imprimir');
    },
    onAfterPrint: () => {
      console.log('Después de imprimir');
      setVoucherPrinted(true)
    },
    onPrintError: (errorLocation, error) => {
      console.error('Error de impresión:', error)
    },
  })

  const processFormat = (text) => {
    const lines = text.split('\n');
  
    return lines.map((line, index) => {
      if (line.startsWith('@')) {
        const alignment = line[1] === 'l' ? 'left' : line[1] === 'c' ? 'center' : 'right';
        const fontSize = line[2] === 'n' ? 'normal' : line[2] === 's' ? 'small' : 'big';
        const fontWeight = line[3] === 'b' ? 'bold' : line[3] === 'n' ? 'normal' : 'light';
        if (line.slice(4).includes('logo_cpagos')) {
          return (
            <img
              key={index}
              src={logo_cpagos_image}
              alt="Logo CPagos"
              style={{
                display: 'block',
                margin: '0 auto', // centrar horizontalmente
                marginTop: '10px',
                width: '180px',
                height: '35px',
              }}
            />
          )
        }
        if (line.slice(4).includes('ver_app')) {
          return (
            <p key={index} style={{ textAlign: alignment, fontSize, fontWeight }}>
              {VER_APP}
            </p>
          )
        }
        return (
          <p key={index} style={{ textAlign: alignment, fontSize, fontWeight }}>
            {line.slice(4)}
          </p>
        )
      } else {
        return (
          <p key={index} style={{ textAlign: 'left', fontSize: 'normal', fontWeight: 'normal' }}>
            {line}
          </p>
        );
      }
    });
  };


  const exit = () => {
    setBookingTickets([])
    setPayment({})
    setBookingPayments({})
    reset()

    navigate(`${ BASE_URL }/`)
  }

  const printDone = () => {
    loggerDebug('Printing completed...', printAll, selectedTicket.current, ticketsRefs.current.length)
    if (printAll.current && selectedTicket.current < (ticketsRefs.current.length - 1)) {
      selectedTicket.current++
      ticketsRefs.current[selectedTicket.current].printTicket()
    } else {
      selectedTicket.current = 0
      printAll.current = false
      setWasPrinted(true)
    }
  }

  const askPrintAll = () => {
    setupModal({
      title: 'Imprimir',
      content: <p>Estás a punto de imprimir todos tus boletos, toma cada uno conforme vaya saliendo ¿deseas continuar
        con la impresión?</p>,
      actions: {
        positive: {
          title: 'Si',
          onClick: () => {
            printAll.current = true
            selectedTicket.current = 0
            ticketsRefs.current[selectedTicket.current].printTicket()
            hideModal()
          }
        },
        negative: {
          title: 'No',
          onClick: () => {
            //selectedTicket.current = null
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const askPrint = () => {
    setupModal({
      title: 'Imprimir',
      content: <p>Al parecer ya imprimiste este boleto, ¿deseas imprimirlo una vez más?</p>,
      actions: {
        positive: {
          title: 'Reimprimir',
          onClick: () => {
            //loggerDebug(ticketsRefs.current,selectedTicket.current,ticketsRefs.current[selectedTicket])
            console.log(ticketsRefs.current[selectedTicket.current])
            console.log(ticketsRefs.current)
            console.log(selectedTicket.current)
            ticketsRefs.current[selectedTicket.current].printTicket()
            selectedTicket.current = null
            hideModal()
          }
        },
        negative: {
          title: 'No',
          onClick: () => {
            selectedTicket.current = null
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const askExit = () => {
    setupModal({
      title: 'Atención',
      content: <p>No imprimiste tus boletos, puedes verlos en tu app Transpais, ¿deseas volver al inicio?</p>,
      styles: {
        buttonType: 'small',
      },
      actions: {
        positive: {
          title: 'Quedarme a imprimirlos',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Volver al inicio sin imprimirlos',
          onClick: () => {
            hideModal()
            exit()
          }
        }
      }
    })
    showModal()
  }

  const voucher = () => {
    return (
      <div ref={componentRef} className={ styles.voucherContainer }>
        {DEBUG_PINPAD ? processFormat(dataCharge.voucher_cliente) : processFormat(charge.voucher_cliente)}
      </div>
    )
  }

  useEffect(() => {
    if ( !voucherPrinted ) {
      setVoucherPrint(true)
    } else if (!printAll.current) {
      printAll.current = true
      selectedTicket.current = 0
      ticketsRefs.current[selectedTicket.current].printTicket()
    }
  }, [voucherPrinted, voucherPrint])

  useEffect(() => {
    if (voucherPrint && !voucherPrinted) {
      handlePrint()
    }
  },[voucherPrint])

  useEffect(() => {
    loggerDebug(ticketsArea.current.scrollTop)
    loggerDebug(ticketsArea.current.offsetHeight)
    loggerDebug(ticketsArea.current.scrollHeight)
    if (ticketsArea.current.scrollHeight > ticketsArea.current.offsetHeight) {
      setShowScrollBottom(true)
    }
  }, [ticketsArea])

  const handleScroll = (e) => {
    setCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  useEffect(() => {
    //loggerDebug(ticketsArea.current.scrollTop)
    //loggerDebug(ticketsArea.current.offsetHeight)
    //loggerDebug(ticketsArea.current.scrollHeight)
    if (currentTicketsScroll > 0) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false)
    }

    if ((ticketsArea.current.scrollTop + ticketsArea.current.offsetHeight) === ticketsArea.current.scrollHeight) {
      setShowScrollBottom(false)
    } else {
      setShowScrollBottom(true)
    }
  }, [currentTicketsScroll])

  useEffect(() => {
    setIdleDonePaused(false)
    hideTimer()
  }, [])

  return (
    <>
      <Helmet>
      </Helmet>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', 'small', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.thanks }>
              <h1>¡GRACIAS!</h1>
            </div>
          </div>
          <div className={ styles.middle }>
            {/* Print voucher first before tickets */}
            <div>
              {voucherPrint && <div style={ {display: 'none'} }>{voucher()}</div>}
            </div>
            {/* Container tickets */}
            <div className={ styles.details }>
              {/* <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }> */}
                { bookingTickets.map((ticket, i) => (
                  <div className={ styles.ticketContainer }> 
                    <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                    <SaleTicket key={ i } ref={ (element) => {
                      ticketsRefs.current[i] = element
                    } } ticket={ ticket } payment={ payment } confirmation={ bookingPayments }
                                      printable={ true } ask={ () => {
                      // selectedTicket.current = i
                      // askPrint()
                    } } done={ printDone }/>
                    </div>
                    <div className={ styles.printButton } onClick={ () => {
                      ticketsRefs.current[i].printTicket()
                      selectedTicket.current = null
                    } }>
                      <svg className={ [styles.icon,].join(' ') }>
                        <use xlinkHref={ `#${ 'print' }` }/>
                      </svg>
                      <span>Imprimir</span>
                    </div>
                  </div>
                )) }
              {/* </div> */}
            </div>
            {/* <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { bookingTickets.map((ticket, i) => {
                  return <Ticket key={ i } ref={(element) => {
                    ticketsRefs.current[i] = element
                  } } ticket={ ticket } payment={ payment } confirmation={ bookingPayments }
                          printable={ true } ask={ () => {
                    selectedTicket.current = i
                    askPrint()
                  } } done={ printDone }/>
                })}
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
            </div> */}
            <div className={ styles.resume }>
              <div className={ [styles.receipt].join(' ') }>
                <h1>Tu pago</h1>
                <div className={ styles.group }>
                  <p>Fecha</p>
                  <span className={ styles.data }>{ `${ payment.date } ${ payment.time }` }</span>
                </div>
                <div className={ styles.group }>
                  <p>Referencia</p>
                  <span className={ styles.data }>{ payment.reference }</span>
                </div>
                <div className={ styles.multiGroup }>
                  <div className={ styles.group }>
                    <p>Operación</p>
                    <span className={ styles.data }>{ payment.foliocpagos }</span>
                  </div>
                  <div className={ styles.group }>
                    <p>Importe</p>
                    <span className={ styles.data }>{ currency(payment.amount) }</span>
                  </div>
                </div>
                <p className={ styles.disclaimer }>Por este pagaré me obligo incondicionalmente a pagar a la orden del
                  banco emisor el importe de este título en los términos del contrato suscrito para el uso de esta
                  tarjeta de crédito en el caso de operaciones con tarjeta de debito, expresamente reconozco y acepto
                  este recibo es el comprobante de la operación realizada, misma que se consigna en el anverso y tendrá
                  pleno valor probatorio y fuerza legal, en virtud de que lo firme personalmente y/o digite mi numero de
                  identificacion personal como firma electrónica el cual es exclusivo de mi responsabilidad manifestando
                  plena conformidad al respecto.</p>
                  <div className={styles.buttonContainer}>
                    <div className={ styles.button }>
                      {/* <Button onClick={ '' } type="neutral">Enviar por correo</Button> */}
                      <p>Enviar por correo</p>
                    </div>
                    <div className={ styles.button } onClick={ askPrintAll }>
                      {/* <Button onClick={ '' } type="neutral">Enviar por correo</Button> */}
                      <p>Imprimir todos</p>
                    </div>
                    {/* <Button onClick={ askPrintAll } type="neutral">Imprimir todos</Button> */}
                  </div>
                  {/* <Button onClick={ askPrintAll } type="neutral">Imprimir todos</Button> */}
                {/* <p></p> */}
                {/* <Button onClick={ askPrintAll } type="neutral">Imprimir todos</Button> */}
                {/* <div className={ styles.group }>
                  <Button onClick={ askPrintAll } type="neutral">Imprimir todos</Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
          </div>
          <div className={ global.middle }>
            <Button onClick={ () => {
              if (wasPrinted) {
                exit()
              } else {
                askExit()
              }
            } } type="positive" right-icon="house">Listo</Button>
          </div>
          <div>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingConfirmed

import React from 'react'
import styles from './TranspaisBusSeat.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { currency } from './helpers/currency'
import { PassengerType } from '../../components/transpais/enums'

const TranspaisBusSeat = ({
                            position,
                            type = 'default',
                            variant = 'default',
                            status = 'available',
                            selected = false,
                            value,
                            onClick,
                            categoria,
                            destacado
                          }) => {
  const render = () => {
    switch (type) {
      case 'default':
      case 'placeholder':
        return (
          <div onClick={ () => {
            if (type !== 'placeholder') {
              console.debug('click', selected)
              onClick(selected)
            }
          } } className={ [styles.seat, (status === 'unavailable' && type !== 'placeholder') ? styles.disabled : null, selected ? styles.selected : null, type === 'placeholder' ? styles.placeholder : null].join(' ') }
               style={ {top: position.y, left: position.x} }>
            <span>{ value }</span>
            {categoria === 6 ? <FontAwesomeIcon icon={ PassengerType.icon(6) } className={ styles.icon }/> : (destacado && <FontAwesomeIcon icon={ regular('wheelchair') } className={ styles.icons }/>)}
          </div>)
      case 'preview':
        return (<div
          className={ [styles.seat, styles.preview, status === 'unavailable' && styles.disabled, selected && styles.selected].join(' ') }></div>)
      default: return null
    }
  }

  return render()
}

export default TranspaisBusSeat
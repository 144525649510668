import React, {useEffect, useState} from 'react'
import {useKey} from 'react-use'
import {QRCode} from 'react-qrcode-logo'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import FadingBalls from 'react-cssfx-loading/lib/FadingBalls'
import {Link, useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {light} from '@fortawesome/fontawesome-svg-core/import.macro'
import {useAtom} from 'jotai'
import Keyboard from 'react-simple-keyboard'
import Rive from '@rive-app/react-canvas'

import {
    reservationTickets,
    reservationFolio,
    reservationFolioHome,
    accessToken,
    puntoVentaID,
    estacionID,
    idleDoneTimerPaused
} from '../../utilities/atoms'
import gateway from '../../services/gateway'

import {
    RESERVATION_CODE_MIN_SIZE,
    RESERVATION_CODE_MAX_SIZE,
    BASE_URL, RESERVATION_LIMIT_TIME,
} from '../../utilities/constants'
import {Button, IconButton, Loader} from '../../components/transpais'

import global from './ReservationGlobal.module.scss'
import styles from './ReservationLookup.module.scss'
import {usePinpad} from '../../contexts/pinpad.context'
import {useModal} from '../../contexts/modal.context'
import * as Sentry from '@sentry/react'
import { useLogger } from '../../contexts/logger.context'
import moment from 'moment'

const ReservationLookup = () => {
    //Amplify.configure({
    //    Logging: {
    //        logGroupName: '/aws/amplify/transpais-kiosk',
    //        logStreamName: `logs`,
    //    },
    //})
    const {loggerDebug, loggerInfo, loggerError} = useLogger()
    //Amplify.register(logger);
    //logger.addPluggable(new AWSCloudWatchProvider());

    const [localLoading, setLocalLoading] = useState(false)
    const [barcodeInputValue, setBarcodeInputValue] = useState('')
    const [isReading, setIsReading] = useState(false)
    const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
    const [canContinue, setCanContinue] = useState(false)

    const navigate = useNavigate()
    const {showModal, setupModal} = useModal()

    const [token,] = useAtom(accessToken)
    const [punto_venta_id,] = useAtom(puntoVentaID)
    const [estacion_id,] = useAtom(estacionID)

    const [, setTickets] = useAtom(reservationTickets)
    const [, setFolio] = useAtom(reservationFolio)
    const [prefolio, setPrefolio] = useAtom(reservationFolioHome)
    const {loading, reset, changeCompany} = usePinpad()

    const readCode = (event) => {
        const key = event.key
        //loggerDebug('length', barcodeInputValue.length)
        setCodeKey(key)
    }

    const setCodeKey = (key) => {
        loggerDebug(key)
        //if (barcodeInputValue.length >= RESERVATION_CODE_MAX_SIZE) {
        //loggerDebug('exit and not setting new char')
        //return
        //}

        if (key !== 'Enter') {
            if (!isReading) {
                setIsReading(true)
            }
            setBarcodeInputValue(barcodeInputValue + key)
        } else {
            search()
        }
    }

    const predicate = (event) => (event.key >= '0' && event.key <= '9') || event.key === 'Enter'
    useKey(predicate, readCode, {event: 'keyup'})

    useEffect(() => {
        //loggerDebug('is reading', isReading)
        //loggerDebug('new', barcodeInputValue)

        if (barcodeInputValue.length === RESERVATION_CODE_MIN_SIZE) {
            setCanContinue(true)
        }

        if (barcodeInputValue.length > RESERVATION_CODE_MIN_SIZE) {
            search()
        }
    }, [barcodeInputValue])

    useEffect(() => {
        if (prefolio != null && prefolio !== '') {
            loggerDebug('Tenemos folio', '[' + prefolio + ']')
            setBarcodeInputValue(prefolio)
            setIsReading(true)
        }
    }, [])

    useEffect(() => {
        if (showScreenKeyboard === false) {
            if (barcodeInputValue.length === 0) {
                restart()
            }
        }
    }, [showScreenKeyboard])

    const instructions = [
        <h1 key="i1">Coloca el código QR en el lector</h1>,
        <h1 key="i2">Verifica tu folio</h1>,
    ]

    const restart = () => {
        setCanContinue(false)
        setIsReading(false)
        setBarcodeInputValue('')
        setPrefolio(null)
        reset()
        //folioRef.current = ''
    }

    const showKeyboard = () => {
        if (!isReading) {
            setIsReading(true)
        }
        setShowScreenKeyboard(true)
    }

    const onKeyboardChange = (input) => {
        loggerDebug('Input changed', input)
    }

    const onKeyboardKeyPress = (button) => {
        loggerDebug('Button pressed', button)
        if (button === '{esc}') {
            setShowScreenKeyboard(false)
        } else if (button === '{bksp}') {
            setBarcodeInputValue(barcodeInputValue.slice(0, -1))
        } else {
            setCodeKey(button)
        }
    }

    const search = () => {
        setLocalLoading(true)
        loggerDebug(barcodeInputValue)
        Sentry.setContext('Search', {
            folio: barcodeInputValue,
        })
        loggerInfo(`Loking for reservation code: ${barcodeInputValue}`)
        gateway
            .reservationSearch(token, barcodeInputValue, punto_venta_id, estacion_id)
            .then((response) => {
                setLocalLoading(false)
                loggerDebug(response.data)
                const info = response.data.header
                if (info.error) {
                    loggerError(`Error for ${barcodeInputValue}, ${info.error}`)
                    setupModal({
                        title: 'Atención',
                        content: <p>No es posible localizar esta reservación, valida tus datos e inténtalo de
                            nuevo.</p>,
                    })
                    showModal()
                    restart()
                } else {
                    const data = response.data.content

                    loggerDebug(info)
                    loggerDebug(data)
                    loggerDebug(`Result for ${barcodeInputValue}`, data)
                    if (data.length > 0) {
                        const expiration = moment(data[0].fecHorLiberaReservacion, 'DD-MM-YYYY HH:mm').subtract(RESERVATION_LIMIT_TIME, 'seconds') //"06/12/2022 18:19"
                        if (!expiration.isValid()) {
                            loggerError(`Error for ${barcodeInputValue}, la fecha de expiración no es válida ${data[0].fecHorLiberaReservacion}.`)
                            setupModal({
                                title: 'Atención',
                                content: <p>Hubo un problema al localizar tu reserva, por favor inténtalo de nuevo.</p>,
                            })
                            showModal()
                            restart()
                            return
                        }

                        if (!moment().isBefore(expiration)) {
                            loggerError(`Error for ${barcodeInputValue}, no hay suficiente tiempo para realizar tu pago ${data[0].fecHorLiberaReservacion}.`)
                            setupModal({
                                title: 'Atención',
                                content: <p>Tu reserva está próxima a expirar, no es posible continuar con el proceso de pago.</p>,
                            })
                            showModal()
                            restart()
                            return
                        }

                        let empresa = data[0].itemDatosConexion
                        const selectedData = data.map((ticket) => {
                            ticket.selected = true
                            return ticket
                        })
                        changeCompany(empresa)
                        setTickets(selectedData)
                        setFolio(barcodeInputValue)
                        navigate(`${BASE_URL}/reservations/review`)
                    } else {

                    }
                }
            })
            .catch((error) => {
                setLocalLoading(false)
                loggerError(error)
                //hideLoader()
                /*swal({
                title: 'Ooops!',
                text: 'Lo sentimos pero tenemos problemas para localizar tu reserva, por favor inténtalo de nuevo.',
                icon: 'error',
              })*/
            })
    }

    return (
        <>
            <Loader show={loading || localLoading}/>
            {/*----Page----*/}
            <section className={['section-full', 'section-flex'].join(' ')}>
                <div className={[global.top].join(' ')}>
                    <div className={['logo', styles.logo].join(' ')}>
                        <figure className={['logo-image', 'logo-image-transpais-light'].join(' ')}/>
                    </div>
                    <div className={styles.formWrapper}>
                        {/*<QRCode
                            value="https://transpais.com.mx"
                            size={280}
                            quietZone={5}
                            fgColor={'#142785'}
                            bgColor={'#F4F7FC'}
                            qrStyle={'dots'}
                            eyeRadius={[
                                {
                                    outer: [30, 30, 0, 30],
                                    inner: 2,
                                },
                                {
                                    outer: [30, 30, 30, 0],
                                    inner: 2,
                                },
                                {
                                    outer: [30, 0, 30, 30],
                                    inner: 2,
                                },
                            ]}
                            logoImage={require('../../assets/images/icon.png')}
                            logoWidth="54"
                            removeQrCodeBehindLogo={true}
                        />*/}
                        <div>
                            <Rive src="https://transpais-kiosk.s3.amazonaws.com/animations/kiosk-referencia.riv" className={styles.instructionsLoop} />
                        </div>
                        <div className={styles.instructions}>
                            <ReactCSSTransitionReplace
                                transitionName="cross-fade"
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={500}
                            >
                                {instructions[!isReading ? 0 : 1]}
                            </ReactCSSTransitionReplace>
                        </div>
                        <div>
                            {!isReading ? (
                                <>
                                    <div className={styles.loadingWrapper}>
                                        <FadingBalls color="#003366"/>
                                    </div>
                                    <div className={styles.keyboardIconWrapper}>
                                        <IconButton
                                            type="clear"
                                            icon="keyboard"
                                            onClick={showKeyboard}
                                        />
                                        <div
                                            className={[global.notice, global.noticeRight, styles.keyboardNotice].join(' ')}>
                                            <FontAwesomeIcon
                                                icon={light('arrow-left')}
                                                className={global.pointer}
                                            />
                                            <div className={styles.pill}>
                                                <p>O utiliza el teclado en pantalla</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <div className={styles.folio}>
                                        <TransitionGroup>
                                            {barcodeInputValue.split('').map((character, index) => (
                                                <CSSTransition
                                                    key={index}
                                                    classNames="folio"
                                                    timeout={{enter: 300, exit: 300}}
                                                >
                          <span
                              key={`char${character}-${index}`}
                              className={styles.item}
                          >
                            {character}
                          </span>
                                                </CSSTransition>
                                            ))}
                                        </TransitionGroup>
                                    </div>
                                    <div className={styles.action}>
                                        <IconButton
                                            type="destructive"
                                            icon="trash"
                                            onClick={restart}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={[styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ')}>
                            <Keyboard
                                onChange={onKeyboardChange}
                                onKeyPress={onKeyboardKeyPress}
                                layout={{
                                    default: [
                                        '1 2 3', '4 5 6', '7 8 9', '{esc} 0 {bksp}',
                                    ]
                                }}
                                display={{
                                    '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                                    '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <footer className={global.bottom}>
                    <div>
                        <Button to="/" type="neutral" left-icon="chevron-left">
                            Volver
                        </Button>
                    </div>
                    <div className={global.middle}>
                        <TransitionGroup>
                            {!isReading && (
                                <CSSTransition
                                    key={'notice-01'}
                                    classNames="notice"
                                    timeout={{enter: 300, exit: 300}}
                                >
                                    <div className={global.notice}>
                                        <p>El lector está aquí</p>
                                        <FontAwesomeIcon
                                            icon={light('arrow-down')}
                                            bounce
                                            className={global.pointer}
                                        />
                                    </div>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                    <div>
                        <Button
                            type="positive"
                            right-icon="chevron-right"
                            disabled={!canContinue}
                            onClick={search}
                        >
                            Continuar
                        </Button>
                    </div>
                </footer>
            </section>
        </>
    )
}

export default ReservationLookup

import React, { useEffect, useState, useRef } from 'react'
import {
  addDays,
  startOfDay,
  getMonth,
  compareAsc,
  format,
  intlFormat,
  isSameDay,
  getDaysInMonth,
  getDate, getDay, parseISO
} from 'date-fns'

import { useLogger } from '../../../contexts/logger.context'
import { useModalView } from '../../../contexts/modalview.context'

import global from '../BookingGlobal.module.scss'
import styles from './CalendarView.module.scss'

import { Button } from '../../../components/transpais'
import { DateType, TripType } from '../../../components/transpais/enums'

const CalendarView = ({count, departure, returning, tripType, type, onChange, onFinish, setDate}) => {

  const {loggerDebug} = useLogger()
  const {showModalView, setupModalView, destroyModalView} = useModalView()

  const today = startOfDay(new Date())
  const lastday = addDays(today, count)

  const months = getMonth(today) !== getMonth(lastday)
    ? (getMonth(lastday) < getMonth(today) ? (getMonth(lastday) + 13) - getMonth(today) : (getMonth(lastday) - getMonth(today) + 1))
    : 1

  let currentDate = today

  const [canContinue, setCanContinue] = useState(false)

  const [newTripType, setNewTripType] = useState(tripType)
  const [newDeparture, setNewDeparture] = useState(parseISO(departure))
  const [newReturning, setNewReturning] = useState(parseISO(returning))

  const [didDeparture, setDidDeparture] = useState(tripType !== TripType.single)
  const [didReturning, setDidReturning] = useState(false)
  const NewReturning = useRef(null)

  const selectDate = (date) => {
    let _type = type

    if (type === DateType.departure) {
      setNewDeparture(date)
      setNewReturning(null)
      NewReturning.current = null
      if(tripType === TripType.single) {
        setCanContinue(true)
        continueSearch()
        // destroyModalView()
      } else {
        setNewReturning(null)
        continueSearch()
      }
    } else if (type === DateType.returning) {
      setNewReturning(date)
      NewReturning.current = date
      setCanContinue(true)
      continueSearch()
    }
    //if (didDeparture && didReturning) {
    //  if (isSameDay(date, newReturning)) {
    //    setNewReturning(null)
    //  } else {
    //    setNewDeparture(date)
    //    setNewReturning(null)
    //  }
    //  setNewTripType(TripType.single)
    //  setDidReturning(false)
    //  setDidDeparture(true)
    //type = DateType.departure
    //} else if (didDeparture === false) {
    //  setNewDeparture(date)
    //  setDidDeparture(true)
    //  setDidReturning(false)

    //type = DateType.departure
    //} else {
    //  if (compareAsc(date, newDeparture) <= 0) {
    //    setNewDeparture(date)
    //type = DateType.departure
    //  } else {
    //    setNewTripType(TripType.round)
    //    setNewReturning(date)
    //    setDidReturning(true)
    //type = DateType.returning
    //  }
    //}

    onChange(date, _type)
  }

  const clearReturningDate = () => {
    setNewReturning(null)
    setNewTripType(TripType.single)
    setDidReturning(false)
    setDidDeparture(true)
    onChange(newDeparture, DateType.departure)
  }

  const continueSearch = () => {
    if(NewReturning.current !== null && newTripType === TripType.round) {
      onFinish()
    } else if(newTripType === TripType.single && newDeparture !== null) {
      onFinish()
    } else {
      destroyModalView()
      setDate(newDeparture)
      
    }
    
  }

  useEffect(() => {
    console.log(newTripType, newDeparture)
    if ((newTripType === TripType.single && newDeparture != null) || (newTripType === TripType.round && NewReturning.current === null) || (newTripType === TripType.round && NewReturning.current !== null)) {
      setCanContinue(true)
    } else {
      setCanContinue(false)
    }
  }, [newTripType, newDeparture, newReturning])

  return (
    <>
      <div className={ styles.calendarView }>

        <div className={ styles.inputs }>
          { type === DateType.departure ? (
            <div>
              <label>Salida</label>
              <div className={ styles.input }>
              <span>{ intlFormat(newDeparture, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }) }</span>
              </div>
            </div>
          ) : null }
          { type === DateType.returning ? (
            <div>
              <label>Regreso</label>
              <div className={ styles.input }>
              <span>{ newTripType === TripType.round ? intlFormat(newReturning, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }) : '' }</span>
                { newTripType === TripType.round && false ? <div className={ styles.cleanDate } onClick={ clearReturningDate }>
                  <svg>
                    <use xlinkHref="#circle-xmark"/>
                  </svg>
                </div> : null }
              </div>
            </div>
          ) : null }
        </div>

        { [...Array(months)].map((_month, i) => {
            const days = (getDaysInMonth(currentDate) - getDate(currentDate)) + 1
            const weekMissing = getDay(currentDate)
            return (
              <section key={ `month_${ i }` } className={ styles.calendarMonth }>
                <h2>{ intlFormat(currentDate, {
                  month: 'long'
                }) }</h2>
                <div className={ styles.calendarDays }>
                  { ['D', 'L', 'M', 'M', 'J', 'V', 'S'].map((_day, j) => {
                    return (
                      <div className={ [styles.calendarDay, styles.empty].join(' ') } key={ `wd_${ j }` }>
                        <span>{ _day }</span>
                      </div>
                    )
                  }) }
                  { [...Array(weekMissing)].map((_day, j) => {
                    return (
                      <div className={ [styles.calendarDay, styles.empty].join(' ') } key={ `ed_${ j }` }>
                        <span></span>
                      </div>
                    )
                  }) }
                  { [...Array(days)].map((_day, j) => {
                    //console.log(currentDate, departure, lastday, compareAsc(currentDate, lastday))
                    if (!(compareAsc(currentDate, lastday) <= 0)) {
                      return null
                    }
                    const thisDate = currentDate

                    let isDisabled = false
                    if (type === DateType.returning) {
                      const _departure = parseISO(departure)
                      console.log('date', thisDate)
                      console.log('departure', _departure)
                      console.log('compare', compareAsc(thisDate, _departure))
                      if (compareAsc(thisDate, _departure) < 0) {
                        isDisabled = true
                      }
                    }

                    //loggerDebug(newDeparture, currentDate)
                    const isDeparture = isSameDay(currentDate, newDeparture)
                    const isReturning = isSameDay(currentDate, newReturning) && newTripType === TripType.round
                    //const disabled = currentDate?.startOf('day').isBefore(newDeparture?.startOf('day'))
                    const item = (
                      <div key={ `d_${ j }` }
                           className={ [styles.calendarDay,
                             isDeparture && styles.selectedDeparture,
                             isReturning && styles.selectedReturning,
                             isDisabled && styles.disabled,
                           ].join(' ') }>
                        <span onClick={ () => {
                          if (!isDisabled) {
                            selectDate(thisDate)
                          }
                        } }>{ format(currentDate, 'd') }</span>
                      </div>
                    )
                    currentDate = addDays(currentDate, 1)
                    return item
                  }) }
                </div>
              </section>
            )
          }
        ) }
      </div>
      <footer className={ global.bottom }>
        <div>
        </div>
        <div className={ global.middle }>
          <Button type="positive" right-icon="chevron-right" onClick={ () => continueSearch() }
                  disabled={ !canContinue }>Continuar</Button>
        </div>
        <div>
        </div>
      </footer>
    </>
  )
}

export default CalendarView
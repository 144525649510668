import React from 'react'
import * as Sentry from '@sentry/react'
import {Navigate} from 'react-router-dom'
import {useAtom} from 'jotai'
import {
    accessToken,
    claveUsuario,
    estacionID,
    nombrePuntoVenta,
    puntoVentaID,
    usuarioID
} from '../../utilities/atoms'
//import {isauth}  from 'auth'

const ProtectedRoute = ({component}) => {
    const [cveUsuario,] = useAtom(claveUsuario)
    const [usuarioId,] = useAtom(usuarioID)
    const [puntoVentaId,] = useAtom(puntoVentaID)
    const [estacionId,] = useAtom(estacionID)
    const [token,] = useAtom(accessToken)
    const [nombPuntoVenta,] = useAtom(nombrePuntoVenta)

    const checkAuth = () => {
        return cveUsuario.trim() !== '' && usuarioId !== 0 && puntoVentaId !== 0 && estacionId !== 0 && token.trim() !== '' && nombPuntoVenta
    }

    const authed = checkAuth()

    if (!authed) {
        Sentry.setUser(null)
    }

    return authed ? component : <Navigate to={'/login'}/>
}

export default ProtectedRoute
import React from 'react'
import styles from './TranspaisDeparture.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import {currency} from './helpers/currency'
import { DepartureViewVariant, TripAmenity } from './enums'
import { formatInTimeZone } from 'date-fns-tz'
import { parseISO } from 'date-fns'

const TranspaisDeparture = ({data, variant, onClick}) => {
  const quick = () => (
    <div className={styles.departure}>
      <div className={styles.departureMeta}>
        <figure className={[styles.departureBrand].join(' ')}>
          <img src={data.brand} alt="Brand"/>
        </figure>
        <p className={styles.trip}>{data.originLabel} <FontAwesomeIcon
          icon={solid('chevron-right')} size={'xs'}/> {data.destinationLabel}</p>
      </div>

      <div className={styles.departureInfo}>
        <div>
          <h3>Salida</h3>
          <p>{data.salida}</p>
        </div>
        <div>
          <h3>Asientos</h3>
          <p>{data.disponibles} <span>Libres</span></p>
        </div>
        <div>
          <h3 className={styles.right}>Precio</h3>
          <p><span className={styles.alt}>$</span>{currency(data.precio, false)}</p>
        </div>
      </div>
    </div>
  )

  const full = () => {

    var salida_raw = data.salida_raw.includes("-05:") ? data.salida_raw.replace(/-05:/, "-06:") : data.salida_raw
    var llegada_raw = data.llegada_raw.includes("-05:") ? data.llegada_raw.replace(/-05:/, "-06:") : data.llegada_raw
    const salida = parseISO(salida_raw)
    const llegada = parseISO(llegada_raw)

    return (
      <div onClick= { onClick } className={ [styles.departure, styles.departureFull].join(' ') }>
        <div className={ styles.data }>
          <div className={ styles.departureInfo }>
            <div>
              <div>
                <h3>Salida</h3>
                <p>{ formatInTimeZone(salida, 'America/Monterrey', 'H:mm') }</p>
              </div>
              <div className={ [styles.departureExtra, styles.inner].join(' ') }>
                <h3>Asientos</h3>
                <p>{ data.disponibles } libres</p>
              </div>
            </div>
            <div>
              <div>
                <h3>Llegada</h3>
                <p>{ formatInTimeZone(llegada, 'America/Monterrey', 'H:mm') }</p>
              </div>
              <div className={ [styles.departureExtra, styles.inner].join(' ') }>
                <h3>Duración</h3>
                <p>{ data.tiempo }</p>
              </div>
            </div>
            <div className={ styles.withAmenities }>
              <div>
                <h3 className={ styles.right }>Precio</h3>
                <p><span className={ styles.alt }>$</span>{ currency(data.precio, false) }</p>
              </div>
              <div className={ styles.amenities }>
                {data.amenidades.length > 0 && (
                  <>
                  {data.amenidades.map((amenity) => {
                    return <FontAwesomeIcon key={`am_${amenity.id}`} icon={ TripAmenity.icon(amenity.image) } className={ styles.icons } size={ '2xl' }/>
                  })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className={ [styles.departureMeta, styles.pad].join(' ') }>
          <figure className={ [styles.departureBrand].join(' ') }>
            <img src={ `/brands/${data.marca.replace(' ', '').toLowerCase()}.svg` } alt="Brand"/>
          </figure>
          {/*<a className={ styles.details }>Ver detalles</a>*/}
        </div>
      </div>
    )
  }

  const brief = () => (<div></div>)

  const renderView = () => {
    //console.debug('-------')
    //console.debug('variant', variant)
    switch (variant) {
      case DepartureViewVariant.quick:
        return quick()
      case DepartureViewVariant.brief:
        return brief()
      case DepartureViewVariant.full:
        return full()
      default:
        return full()
    }
  }

  return renderView()
}

export default TranspaisDeparture
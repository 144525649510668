import axios from 'axios'
import iBus from '../config/ibus'
import { accessToken } from '../utilities/atoms'

const urlBase = process.env.REACT_APP_TRANSPAISWS
const appToken = process.env.REACT_APP_TRANSPAISID_TOKEN

/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `${ urlBase }/${ url }`

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Application: appToken,
    ...headers,
  },
  timeout: 60000
})

const get = (url = '', headers = {}) => axios.get(readUrl(url), {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Application: appToken,
    ...headers,
  },
  data: {},
  timeout: 60000
})


const origins = (accessToken, user) => post('/v2/booking/from-list', {
  user,
}, {
  Authorization: `Bearer ${ accessToken }`
})

const destinations = (accessToken, user, origin) => {
  const body = {
    origin,
    user,
  }

  return post('/v2/booking/to-list', body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const search = (accessToken, trip_id, origin, destination, redondo, date, userInfo) => {
  const body = {
    'platform': {
      'type': 'Kiosk',
      'appVersion': `${ process.env.REACT_APP_VERSION } (${ process.env.REACT_APP_BUILD })`,
      'info': {
        'platform': userInfo.puntoVentaId,
        'version': userInfo.usuarioId,
        'os': userInfo.estacionId,
        'name': userInfo.cveUsuario,
        'platformString': userInfo.nombPuntoVenta,
        'uuid': 1
      }
    },
    user: userInfo.usuarioId,
    destination,
    redondo,
    origin,
    date
  }

  if (trip_id != null && redondo) {
    body.returning = trip_id
  }

  return post('v2/booking/search', body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const bus = (trip_id, key, redondo, punto, usuario) => {
  const body = {
    key,
    redondo,
    layout: 'horizontal',
    punto,
    user: usuario,
  }

  return post(`v2/booking/trip/${ trip_id }/bus`, body, {})
}

const block = (accessToken, trip_id, seats, usuario, punto, operation = null) => {
  const body = {
    seats,
    usuario,
    punto
  }

  if (operation) {
    body.operation = operation
  }

  return post(`v2/booking/trip/${ trip_id }/bus/seats/block`, body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const unblock = (accessToken, trip_id, seats, usuario) => {
  const body = {
    seats,
    user: usuario,
  }

  return post(`v2/booking/trip/${ trip_id }/bus/seats/unlock`, body, {
    Authorization: `Bearer ${ accessToken }`
  })
}

const canReserve = (accessToken, trip_id) => get(`v2/booking/trip/${ trip_id }/bus/seats/validate-reservation/taquilla`,{
  Authorization: `Bearer ${ accessToken }`
})

const canPay = (accessToken, trip_id) => get(`v2/booking/trip/${ trip_id }/bus/seats/can-pay`,{
  Authorization: `Bearer ${ accessToken }`
})

const confirm = (token, trip_id, usuario, card, email, seats) => {
  const body = {
    card,
    email,
    seats,
    usuario,
  }
  return post(`v2/booking/trip/${ trip_id }/bus/seats/confirm-creditcard`, body, {
    Authorization: `Bearer ${ token }`
  })
}

const credentials = (trip_id, usuario, empresa) => get(`v2/payments/mit/${ trip_id }/data/${usuario}/${ empresa }`, {})

const tickets = (reservation, pin) => {
  const body = {
    pin
  }
  return post(`/v2/tools/tickets/by-reservation/${reservation}`, body, {})
}

const booking = (token, trip_id, tipo) => get(`v2/booking/trip/${ trip_id }/bus/seats/validate-reservation/${ tipo }`, {
  Authorization: `Bearer ${ token }`
})

const confirmReservation = (token, trip_id, email, seats) => {
  const body = {
    email,
    seats,
  }
  return post(`v2/booking/trip/${trip_id}/bus/seats/confirm-reservation-cash`, body, {
    Authorization: `Bearer ${ token }`
  })
}

const ticketsAdmin = (accessToken) => get (`/v2/accounts/my-tickets`,{
  Authorization: `Bearer ${ accessToken }`
})

const transpais = {
  origins,
  destinations,
  search,
  bus,
  block,
  unblock,
  credentials,
  confirm,
  tickets,
  booking,
  confirmReservation,
  canPay,
  canReserve,
  ticketsAdmin
}

export default transpais

import React from "react"
import {BusSeat} from "./index"

import styles from "./TranspaisTripAcotaciones.module.scss"

const TranspaisTripAcotaciones = ({items}) => {
  return (
    <div className={styles.acotaciones}>
      <h2>Acotaciones</h2>
      <div className={styles.item}>
        <BusSeat type={'preview'} variant={'default'} status={'available'}/>
        <p>Libre</p>
      </div>
      <div className={styles.item}>
        <BusSeat type={'preview'} variant={'default'} status={'unavailable'}/>
        <p>Ocupado</p>
      </div>
      <div className={styles.item}>
        <BusSeat type={'preview'} variant={'default'} status={'available'} selected={true}/>
        <p>Seleccionado</p>
      </div>
      <hr/>
      {/*<div className={styles.item}>*/}
      {/*  <BusSeat type={'preview'} variant={'pet'} status={'available'}/>*/}
      {/*  <p>Permite mascota</p>*/}
      {/*</div>*/}
    </div>
  )
}

export default TranspaisTripAcotaciones
import TranspaisTripType from './TranspaisTripType'
import TranspaisDepartureViewVariant from './TranspaisDepartureViewVariant'
import TranspaisBusBrand from './TranspaisBusBrand'
import TranspaisDateType from './TranspaisDateType'
import TranspaisTripAmenity from './TranspaisTripAmenity'
import TranspaisPassengerType from './TranspaisPassengerType'
import TranspaisTicketService from './TranspaisTicketService'
import TranspaisSeatStatus from './TranspaisSeatStatus'

export const TripType = TranspaisTripType
export const DateType = TranspaisDateType
export const DepartureViewVariant = TranspaisDepartureViewVariant
export const BusBrand = TranspaisBusBrand
export const TripAmenity = TranspaisTripAmenity
export const PassengerType = TranspaisPassengerType
export const TicketService = TranspaisTicketService
export const SeatStatus = TranspaisSeatStatus
import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
//import useDetectPrint from 'use-detect-print'

import ReservationPrintableTicket from './ReservationPrintableTicket'

import { TicketType } from '../../utilities/constants'
//import { Impresora } from '../../assets/js/Impresora'

import styles from './ReservationTicket.module.scss'
import { useAtom } from 'jotai'
import { idleDoneTimerPaused } from '../../utilities/atoms'
import { useLogger } from '../../contexts/logger.context'
import { currency } from '../../components/transpais/helpers/currency'

const ReservationTicket = forwardRef(({
                                        ticket,
                                        redondo,
                                        regreso,
                                        payment,
                                        confirmation,
                                        printable,
                                        ask,
                                        done,
                                        onClick
                                      }, ref) => {

  const printableRef = createRef()
  //const isPrinting = false//useDetectPrint()
  const {loggerDebug, loggerError} = useLogger()

  const [, setIdleDonePaused] = useAtom(idleDoneTimerPaused)
  const [printed, setPrinted] = useState(false)
  //const [selected, setSelected] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => {
      loggerDebug('Content', printableRef, printableRef.current)
      return printableRef.current
    }, onBeforePrint: () => {
      setIdleDonePaused(true)
    }, //removeAfterPrint: false,
    onAfterPrint: () => {
      //setIdlePaused(false)
      setIdleDonePaused(false)
      setPrinted(true)
      done()
    }, onPrintError: (errorLocation, error) => {
      loggerError(errorLocation, error)
      setIdleDonePaused(false)
    }
  })

  useImperativeHandle(ref, () => ({
    printTicket() {
      handlePrint()
    }
  }))

  const printTicket = () => {
    //loggerDebug('Printed', printed)
    //setIdlePaused(true)
    if (printed) {
      loggerDebug('Printed', 'ask')
      ask()
    } else {
      setPrinted(true)
      handlePrint()
    }
  }

  //const toggleSelection = () => {
  //setSelected(!selected)
  //}

  return (<div ref={ ref }>
    <div
      className={ [styles.ticket, !printable ? styles.selectable : null, !printable ? ticket.selected ? styles.selected : styles.unselected : null].join(' ') }
      onClick={ () => !printable ? onClick(ticket) : null} style={ {display: 'none'} }>
      <div className={ [styles.info, printable ? styles.small : null].join(' ') }>
        <div className={ styles.seat }>
          <h3>Asiento</h3>
          <p>{ ticket.asiento }</p>
        </div>
        <div className={ styles.data }>
          <p className={ styles.name }>{ ticket ? ticket.pasajero : '-' }</p>
          { !printable && (<p className={ styles.type }>
            <svg className={ [styles.category, styles.icon].join(' ') }>
              <use xlinkHref={ `#${ TicketType.icon(ticket.categoria) }` }/>
            </svg>
            <span>{ TicketType.label(ticket.categoria) }</span>
            { redondo && <span className={ [styles.tag, regreso ? styles.return : null].join(' ') }>
              { ticket.origen } › { ticket.destino }
            </span> }
          </p>) }
        </div>
      </div>
      {/* { !printable ? (<div className={ styles.precio }>
        <p>{ currency(ticket ? ticket.precio : 0) }</p>
        <div className={ styles.selectWrapper }>
          <h3>Pagar</h3>
          <svg className={ [styles.icon].join(' ') }>
            <use xlinkHref={ `#${ !ticket.selected ? 'circle-dashed' : 'circle-check' }` }/>
          </svg>
        </div>
      </div>) : (<div className={ styles.print } onClick={ printTicket }>
        <svg className={ [styles.icon, printed ? styles.done : null].join(' ') }>
          <use xlinkHref={ `#${ !printed ? 'print' : 'check' }` }/>
        </svg>
        <span>Imprimir</span>
      </div>) } */}
    </div>
    { printable && (<div /*style={ {display: 'none'} }*/><ReservationPrintableTicket ref={ printableRef } ticket={ ticket }
                                                                                 payment={ payment }
                                                                                 confirmation={ confirmation }/>
    </div>) }
  </div>)
})

export default ReservationTicket

import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../../components/navigation/PrivateRoute'

import SearchTicket from './SearchTicket'
import PrintTicket from './PrintTicket'
import { BASE_URL } from '../../utilities/constants'

const routes = (
  <Route exact path={`${BASE_URL}/reprints`}>
    <Route exact path="search" element={<ProtectedRoute component={ <SearchTicket/>}/>}/>
    <Route exact path="ticket" element={<ProtectedRoute component={ <PrintTicket/>}/>}/>
  </Route>
)

export default routes

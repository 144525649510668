import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { addMinutes, formatISO } from 'date-fns'
import { useAtom } from 'jotai'

import { BASE_URL } from '../../utilities/constants'
import transpais from '../../services/transpais'

import {
  bookingSessionDepartureDisponibilidad,
  bookingSessionDepartureTrip,
  bookingSessionReturningTrip,
  bookingSessionDestination,
  bookingSessionOrigin,
  // bookingSessionTripId,
  bookingSessionDepartureBlockedTickets,
  bookingSessionTimeoutDeadline,
  puntoVentaID,
  usuarioID,
  bookingSessionTripConfig,
  bookingSessionReturningBlockedTickets,
  bookingSessionReturningDisponibilidad,
  bookingSessionReset,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  TranspaisIDToken,
} from '../../utilities/atoms'

import {
  Button,
  Timer,
  TripData,
  Acotaciones,
  BusLayout,
  ShoppingCart,
  Loader,
} from '../../components/transpais'

import { useNavigate } from 'react-router-dom'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'
import { useModal } from '../../contexts/modal.context'
import { TripType, PassengerType, SeatStatus } from '../../components/transpais/enums'

import styles from './BookingBus.module.scss'
import global from './BookingGlobal.module.scss'

const BookingBus = () => {
  const [usuarioId,] = useAtom(usuarioID)
  
  const [localLoading, setLocalLoading] = useState(false)
  const backReturn = useRef(false)
  const gotoReturning = useRef(false)
  const referencia = useRef(null)
  const noPets = useRef([])
  // const petWindow = useRef([])
  const noPetsWindow = useRef([])
  const isPet = useRef(false)
  const continueBlock = useRef(true)
  const boolSGI = useRef(false)

  const [bus, setBus] = useState([])
  const [selected, setSelected] = useState([])
  // const [referencia, setReferencia] = useState([])
  // const [noPets, setNoPets] = useState([])

  const [transpaisIdToken,] = useAtom(TranspaisIDToken)
  const [usuario,] = useAtom(usuarioID)
  const [puntoVenta,] = useAtom(puntoVentaID)
  const [bookingReset, setBookingReset] = useAtom(bookingSessionReset)
  const [bookingOrigin,] = useAtom(bookingSessionOrigin)
  const [bookingDestination,] = useAtom(bookingSessionDestination)
  const [bookingDepartureTrip, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  // const [bookingTripId, setBookingTripId] = useAtom(bookingSessionTripId)
  const [bookingDepartureDisponibilidad, setBookingDepartureDisponibilidad] = useAtom(bookingSessionDepartureDisponibilidad)
  //const [bookingDepartureSelectedTickets, setBookingDepartureSelectedTickets] = useAtom(bookingSessionDepartureSelectedTickets)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingTimeoutDeadline, setBookingTimeoutDeadline] = useAtom(bookingSessionTimeoutDeadline)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingReturningDisponibilidad, setBookingReturningDisponibilidad] = useAtom(bookingSessionReturningDisponibilidad)
  //const [bookingReturningSelectedTickets, setBookingReturningSelectedTickets] = useAtom(bookingSessionReturningSelectedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [listPassengers, setListPassengers] = useState(bookingDepartureBlockedTickets)
  const [selectedFloor, setSelectedFloor] = useState(null)
  const [seatSGI, setSeatSGI] = useState(null)

  const navigate = useNavigate()
  const {loading, reset, changeCompany} = usePinpad()
  const {showTimer, hideTimer, setupTimer} = useTimer()
  const {showModal, hideModal, setupModal} = useModal()

  const seatExists = (seat) => selected.findIndex((_seat) => {
    return _seat.asiento === seat.asiento
  })
  const returnPassengers = (_listPassengers) => {
    setListPassengers(_listPassengers)
  }
  const onSelectedFloor = (floor) => {
    setSelectedFloor(floor)
  }
  const seatSelected = (seat) => {

  }
  const onSelectSeat = (seat) => {
    console.log('entra onSelect={onSelectSeat}', seat) //entonces si entro todo bien vea
    const index = seatExists(seat.data)
    console.log(index)
    if (index >= 0) { //existe
      let _currentSeats = [...selected]
      _currentSeats.splice(index, 1)
      // const isSeatPassenger = listPassengers.find(({ seatReturn }) => seatReturn === seat.data.asiento)

      // console.log(isSeat)
      if (listPassengers.length !== 0) {
        let _listPassengers = listPassengers
        _listPassengers.map((passenger) => (
          passenger.seatReturn === seat.data.asiento ? passenger.seatReturn = null : null
        ))
        setListPassengers(_listPassengers)
      }
      

      // console.log(bookingDepartureBlockedTickets)
      // bookingDepartureBlockedTickets.map((ticket) => {
      //   if( ticket.seat === seat.data.asiento ) {
      //     return ticket.hasSelected = false
      //   }
      //   // return ticket.seat === seat.data.asiento ? ticket.hasSelected = false : null
      // })
      setSelected(_currentSeats)
    } else {
      let _currentSeats = [...selected]
      _currentSeats.push({
          nombre: seat.nombre,
          asiento: seat.data.asiento,
          tipo: seat.disponibilidad.categoria,
          trip: seat.data.query,
          redondo: false,
          //mancuerna: 10,
          servicio: seat.servicios.map((servicio) => {
            return {
              id: parseInt(servicio.id),
              precio: parseFloat(servicio.precio),
              nombre: servicio.nombre
            }
          }),
          revenue: seat.disponibilidad.revenew,
          pricing: {
            precio: parseFloat(seat.disponibilidad.precio),
            id: seat.disponibilidad.pricingAplicado,
            especifico: seat.disponibilidad.pricingEspecifico
          }
        }
      )
      setSelected(_currentSeats)
    }
  }

  const onTimeout = () => {
    console.log('onTimeout bus')
    setBookingTimeoutDeadline('')
    hideTimer()
    setupModal({
      title: 'Tu reserva expiró',
      content: <p>¡Lo sentimos! 😓 No podemos seguir reservando tus asientos porque más personas están intentando
        viajar.
        🚌 Vuelve a iniciar tu compra cuando estés listo.</p>,
      actions: {
        positive: {
          title: 'Aceptar',
          onClick: () => {
            setLocalLoading(true)
            transpais.unblock(
              transpaisIdToken, bookingDepartureTripId, bookingDepartureBlockedTickets.map((ticket) => ticket.id)
            ).then((response) => {
              Sentry.setContext('unblock', {
                tripId: bookingDepartureTripId,
                tickets: bookingDepartureBlockedTickets,
              })
              setLocalLoading(false)
              setBookingReset(true)
              hideModal()
            }).catch((error) => {
              Sentry.setContext('unblock', {
                tripId: bookingDepartureTripId,
                tickets: bookingDepartureBlockedTickets,
              })
              Sentry.addBreadcrumb({
                category: 'ibus',
                message: 'unblock',
                level: 'error',
              })
              Sentry.captureException(error)
              setLocalLoading(false)
              setBookingReset(true)
              hideModal()
            })
          }
        }
      }
    })
    showModal()
    console.log('TimeOut' + bookingTimeoutDeadline)
  }

  const block = () => {
    if (selected.length > 0) {
      const found = selected.find(adulto => 
        adulto.tipo === PassengerType.adulto || 
        adulto.tipo === PassengerType.senectud || 
        adulto.tipo === PassengerType.profesor || 
        adulto.tipo === PassengerType.estudiante ||
        adulto.tipo === PassengerType.preferente
      )
      let _continueBlock = true
      selected.map((seats) => {
        if (seats.tipo === 6) {
          //Mapeamos referencias
          referencia.current.map((_seats) => {
            //Buscamos el asiento que tenga como mancuerna el asiento seleccionado 
            if (_seats.mancuerna === seats.asiento) {
              let alonePet = selected.find((__seats) => __seats.asiento === _seats.asiento)
              //Si no encuentra el asiento en selected entonces no tiene acompañante
              if (alonePet === undefined) {
                _continueBlock = false
                
              }
            }
          })
        }
      })

      continueBlock.current = _continueBlock === true ? true : false

      if (found !== undefined && continueBlock.current === true) {
        setLocalLoading(true)
        // console.log(bookingTripId)
        let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId

        let _operation = bookingTripConfig.redondo === true ? bookingDepartureBlockedTickets[0].operacion : null
        const dataSendBlock = {
          'transpaisIdToken': transpaisIdToken,
          '_tripId': _tripId,
          'selected': selected,
          'usuario': usuario,
          'puntoVenta': puntoVenta,
          '_operation': _operation
        }
        console.log(dataSendBlock)
        transpais.block(transpaisIdToken, _tripId, selected, usuario, puntoVenta, _operation)
        .then((response) => {
          console.log('response block seat')
          console.log(response)
          setLocalLoading(false)
          Sentry.setContext('Block', {
            tripId: _tripId,
            selected: selected,
            user: usuario,
            pointOfSale: puntoVenta,
            operation: _operation,
            response: response.data
          })
          const _seats = response.data.seats
          const _time = response.data.time

          if (bookingTripConfig.type === TripType.single) {
            const deadline = addMinutes(new Date(), _time.min)
            setupTimer(formatISO(deadline), onTimeout, () => {
              setBookingDepartureBlockedTickets(_seats)
              setBookingTimeoutDeadline(formatISO(deadline))
              //console.log('Booking', formatISO(deadline))
              showTimer()
            })
          } else if (bookingTripConfig.type === TripType.round && bookingTripConfig.redondo === false) {
            console.log('entro if 2')
            const deadline = addMinutes(new Date(), _time.min)
            setupTimer(formatISO(deadline), onTimeout, () => {
              setBookingDepartureBlockedTickets(_seats)
              setBookingTimeoutDeadline(formatISO(deadline))
              setBookingTripConfig({type: TripType.round, redondo: true})
              gotoReturning.current = true
              //console.log('Booking', formatISO(deadline))
              showTimer()
            })
          } else if (bookingTripConfig.type === TripType.round && bookingTripConfig.redondo === true) {
            console.log('entro if 3')
            setBookingReturningBlockedTickets(_seats)
            gotoReturning.current = false
          }

        })
        .catch((error) => {
          const message = error.response.data
          Sentry.setContext('Block', {
            tripId: _tripId,
            selected: selected,
            user: usuario,
            pointOfSale: puntoVenta,
            operation: _operation,
            error
          })
          Sentry.addBreadcrumb({
            category: 'ibus',
            message: 'Block',
            level: 'error',
          })
          Sentry.captureException(error)
          setLocalLoading(false)
          setupModal({
            title: 'Atención',
            content: <p>{message.message}</p>,
            actions: {
              positive: {
                title: 'Ok',
                onClick: () => {
                  hideModal()
                }
              }
            }
          })
          showModal()
        })

      }else{
        let content = continueBlock.current !== true ? 'Tu mascota debe ir acompañada de alguien más, Debes elegir el asiento contiguo al de tu mascota.' 
        : 'Debe seleccionar por lo menos un pasajero adulto para continuar con su compra'
        
        setupModal({
          title: 'Atención',
          content: <p>{content}</p>,
          actions: {
            positive: {
              title: 'Ok',
              onClick: () => {
                hideModal()
              }
            }
          }
        })
        showModal()
      }
      
    }
  }

  const onBack = () => {
    if (backReturn.current === false) {
      console.log('entra return back')
      backReturn.current = true
      if (bookingTripConfig.type === TripType.round && bookingTripConfig.redondo === true) {
        gotoReturning.current = true
        setBookingReturningTrip(null)
        // navigate(`${ BASE_URL }/booking/trips`)
      }else {
        
        // navigate(`${ BASE_URL }/booking/trips`)
        // gotoReturning.current = false
        setBookingDepartureTripId(null)
        gotoReturning.current = true
        setBookingDepartureTrip(null)
        setBookingReturningTrip(null)
        // setSelected([])
        setBookingDepartureBlockedTickets([])
        // setBus([])
        setBookingDepartureDisponibilidad(null)
        setBookingTimeoutDeadline(null)
      }
      
    }
  }

  useEffect(() => {
    console.log('first')
    setLocalLoading(true)
    
    if (listPassengers !== null && listPassengers.length !== 0) {
      let _listPassengers = listPassengers
      _listPassengers.map((passenger) => (
        passenger.seatReturn = null
      ))
      setListPassengers(_listPassengers)
    }

    let _trip = bookingDepartureTrip
    let _tripId = bookingDepartureTripId
    if (bookingTripConfig.redondo === true) {
      _trip = bookingReturningTrip
      _tripId = bookingReturningTripId
    }
    
    transpais.bus(_tripId, _trip.query, bookingTripConfig.redondo, puntoVenta, usuarioId)
      .then((response) => {
        Sentry.setContext('Bus', {
          tripId: _tripId,
          tripQuery: _trip.query,
          tripConfig: bookingTripConfig.redondo,
          pointOfSale: puntoVenta,
          user: usuarioId,
        })
        setLocalLoading(false)
        const _layout = response.data.bus
        const _disponibilidad = response.data.disponibilidad

        setBus(_layout)
        // setBookingTripId(_tripId)
        if (bookingTripConfig.redondo === true) {
          setBookingReturningDisponibilidad(_disponibilidad)
        } else {
          setBookingDepartureDisponibilidad(_disponibilidad)
        }

      }).catch((error) => {
        Sentry.setContext('Bus', {
          tripId: _tripId,
          tripQuery: _trip.query,
          tripConfig: bookingTripConfig.redondo,
          pointOfSale: puntoVenta,
          user: usuarioId,
        })
        Sentry.addBreadcrumb({
          category: 'ibus',
          message: 'Bus',
          level: 'error',
        })
        Sentry.captureException(error)
      setLocalLoading(false)
    })

  }, [])
  

  useEffect(() => {
    //console.log('error', bookingDepartureBlockedTickets.length, bookingTimeoutDeadline, gotoReturning.current)
    if (bookingDepartureBlockedTickets.length > 0 && bookingTimeoutDeadline !== '' && gotoReturning.current === false && bookingTripConfig.redondo === false && bookingTripConfig.type === TripType.single) {
      console.log(bookingDepartureBlockedTickets)
      navigate(`${ BASE_URL }/booking/review`)
    } else if ((bookingDepartureBlockedTickets.length > 0 && bookingTimeoutDeadline !== '' && gotoReturning.current === true)
    || (bookingReturningTrip === null && gotoReturning.current === true)) {
      console.log('voy a trips')
      navigate(`${ BASE_URL }/booking/trips`)
    } else if (bookingReturningBlockedTickets.length > 0) {
      console.log('voy a review 2')
      navigate(`${ BASE_URL }/booking/review`)
    }
  }, [bookingDepartureBlockedTickets, bookingTimeoutDeadline, bookingReturningBlockedTickets, bookingReturningTrip])

  useEffect(() => {
    if (bookingReset) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReset])

  useEffect(() => {
    if (bus.length > 0) {
      bus[0].piso === 2 ? setSelectedFloor(2) : setSelectedFloor(1)
    }
  },[selectedFloor, bus])

  useEffect(() =>{
    if (bookingDepartureDisponibilidad != null || (bookingTripConfig.redondo && bookingDepartureDisponibilidad != null)) {

      if (bus.length > 0 && (Object.entries(bookingReturningDisponibilidad).length !== 0 || Object.entries(bookingDepartureDisponibilidad).length !== 0) && selectedFloor !== null) {

        var _disponibilidad
        if (bookingTripConfig.redondo === true) {
          _disponibilidad = bookingReturningDisponibilidad[selectedFloor].filter((disponibilidad) => {
            return disponibilidad.categoria === PassengerType.mascota
          })
        } else if (bookingDepartureDisponibilidad !== null) {
          _disponibilidad = bookingDepartureDisponibilidad[selectedFloor].filter((disponibilidad) => {
            return disponibilidad.categoria === PassengerType.mascota
          })
        }

        if (_disponibilidad !== null) {
          isPet.current = true
        } else {
          isPet.current = false
        }
        // if ( bookingTripConfig.redondo === true ) {
        //   isPet.current = bookingReturningDisponibilidad[1].hasOwnProperty(3)
        // }else if (bookingDepartureDisponibilidad !== null) {
        //   isPet.current = bookingDepartureDisponibilidad[1].hasOwnProperty(3)
        // }

        let _referencia = []
        bus.map((seat) => (
          Number.isInteger(parseInt(seat.asiento)) ? _referencia[seat.asiento] = seat : null// (seat.status === 'DP' || seat.status === 'PV') && (seat.asiento !== 'TV' || seat.asiento !== 'WC' ) ? _referencia[seat.asiento] = seat : null
        ));
        referencia.current = _referencia
        let seatSGI = null
        _referencia.map((seat, index) => {
          //Asiento vendido
          if (SeatStatus.deshabilitado(seat.status)) {
            //Asiento es un numero?
            if (Number.isInteger(parseInt(seat.asiento))) {
              //Pet es true?
              if (isPet.current) {
                //No es una ventana?
                if (seat.ventana === false) {
                  //Mancuerna es numero?
                  if (Number.isInteger(parseInt(seat.mancuerna))) {
                    //Agregamos mancuerna a noPets
                    let _noPets = noPets.current
                    _noPets[seat.mancuerna] = seat.mancuerna
                    noPets.current = _noPets
                    //obtenemos petWindow
                    let ventana = noPetsWindow.current[seat.mancuerna]
                    if (ventana !== undefined) {
                      seat.mascotable = false
                    }
                  }
                }
              }
            }
          }
          //Asiento Disponible
          if (SeatStatus.disponible(seat.status)) {
            //Validate to show view passenger if seat type are SG/SGI
            if (!boolSGI.current && SeatStatus.sugerido(seat.status)) {
              boolSGI.current = true
              seatSGI = seat
            }
            //Asiento es un numero?
            if (Number.isInteger(parseInt(seat.asiento))) {
              //Pet es true?
              if (isPet.current) {
                let _noPet = noPets.current[seat.asiento] !== undefined ? true : false
                //Es una ventana y no existe en noPets
                if (seat.ventana === true && _noPet === false) {
                  seat.mascotable = true
                  //Mancuerna es numero?
                  if (Number.isInteger(parseInt(seat.mancuerna))) {
                    let _noPetsWindow = []
                    _noPetsWindow[seat.mancuerna] = seat
                    noPetsWindow.current.push(_noPetsWindow)
                  }
                } else {
                  seat.mascotable = false
                }
                // console.log(seat)
              }
            }
          }
        });
        
        if (boolSGI.current && seatSGI !== null) {
          setSeatSGI(seatSGI)
          setupModal({
            title: 'Atención',
            content: <p>Hemos seleccionado el asiento 💺#<strong>{seatSGI.asiento}</strong> para ti, si buscas uno diferente puedes cambiarlo.</p>,
            actions: {
              positive: {
                title: 'Ok',
                onClick: () => {
                  hideModal()
                }
              }
            }
          })
          showModal()
        }
        

      }
    }
  },[bus, bookingDepartureDisponibilidad, bookingReturningDisponibilidad, selectedFloor])

  // useEffect(() => {
  //   if (bookingDepartureTrip === null
  //     && bus.length === 0
  //     && bookingDepartureDisponibilidad === null
  //     && selected.length === 0
  //     && bookingDepartureBlockedTickets === null
  //     && backReturn.current) {
  //     navigate(`${ BASE_URL }/booking/trips`)
  //   }
  // }, [bookingDepartureTrip, bus, bookingDepartureDisponibilidad, selected, bookingDepartureBlockedTickets, bookingReturningBlockedTickets, backReturn])

  return (
    <>
      <Loader show={ loading || localLoading }/>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', bookingTripConfig.redondo === true ? 'roundAlign' : null ,styles.logo].join(' ') }>
            <figure className={  ['logo-image', 'logo-image-transpais-light', bookingTripConfig.redondo === true ? 'logo-small' : null].join(' ') }/>
            { bookingTripConfig.type === TripType.round ? <div className={ styles.toPill }>
              <div className={ styles.pill }>
                <p>{bookingDepartureBlockedTickets.length === 0 ? 'Selecciona tus asientos de ida' : 'Selecciona tus asientos de regreso' }</p>
              </div>
            </div> : null}
            <div className={ styles.shoppCart }>
              {/*<ShoppingCart variant={ 'clock' } tickets={ [...bookingDepartureSelectedTickets, ...bookingReturningSelectedTickets] }/>*/ }
              <Timer variant={ 'clock' }/>
            </div>
          </div>

          <div className={ styles.middle }>

            <div className={ global.content }>
              <div className={ styles.tripsHeader }>
                <TripData variant={ 'brief' } data={ {
                  origin: bookingOrigin,
                  destination: bookingDestination,
                  returning: bookingTripConfig.redondo
                } }/>

                <TripData variant={ 'meta' }
                          meta={ {departure: bookingDepartureTrip, returning: bookingReturningTrip} }/>
              </div>

              <hr/>

              <div className={ [styles.departures].join(' ') }>
                <div className={ styles.layout }>

                  <div className={ styles.acotaciones }>
                    <Acotaciones items={ [] }/>
                  </div>

                  <div className={ styles.bus }>
                    <BusLayout
                      layout={ bus }
                      disponibilidad={ bookingTripConfig.redondo === false ? bookingDepartureDisponibilidad : bookingReturningDisponibilidad }
                      selected={ selected }
                      onSelect={ onSelectSeat }
                      seatSelect={ seatSGI }
                      listPasajeros={ returnPassengers }
                      listPassengers={listPassengers}
                      referencia={referencia}
                      noPets={noPets}
                      noPetsWindow={noPetsWindow}
                      selectFloor={ onSelectedFloor }/>
                  </div>
                </div>

              </div>
            </div>

          </div>
          {/* Modal form data passegner
        <Passenger/>*/ }
        </div>

        <footer className={ global.bottom }>
          <div>
            <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button>
            {/* <Button to={ `${ BASE_URL }/booking/trips` } type="neutral" left-icon="chevron-left">Volver</Button>backReturn */ }
          </div>
          <div className={ global.middle }>

          </div>
          <div>
            <Button onClick={ block } type="positive" right-icon="chevron-right">Continuar</Button>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingBus
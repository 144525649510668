import React from 'react'
import { Link } from 'react-router-dom'
import './Module.scss'

const Module = (props) => {
  return (
    <div className={ ['module', props.disabled && 'disabled'].join(' ') }>
      { props.disabled ? (
        <>
          <div className="module-button">
            <svg className="icon">
              <use xlinkHref={ '#' + props.icon }/>
            </svg>
          </div>
          <div className="module-title">
            { props.label }
          </div>
        </>
      ) : (
        <Link to={ props.route }>
          <div className="module-button">
            <svg className="icon">
              <use xlinkHref={ '#' + props.icon }/>
            </svg>
          </div>
          <div className="module-title">
            { props.label }
          </div>
        </Link>) }
    </div>
  )
}

export default Module

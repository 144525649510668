import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'

import { Button, IconButton, Loader } from '../../components/transpais'
import Ticket from './AdminTicket'
import transpais from '../../services/transpais'
import { isEmpty } from 'lodash'
// import Ticket from '../../components/transpais/TranspaisTicket'

import { BASE_URL } from '../../utilities/constants'

import {
  pinpadPayment,
  bookingSessionPayments,
  idleDoneTimerPaused,
  TranspaisIDToken

} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'

import '../../scss/Layout.scss'
import global from './PrintTicketGlobal.module.scss'
import styles from './PrintTicket.module.scss'
import { Helmet } from 'react-helmet'
import { useLogger } from '../../contexts/logger.context'
import { currency } from '../../components/transpais/helpers/currency'

const PrintTicket = () => {

  const {loggerDebug} = useLogger()
  const navigate = useNavigate()
  const [localLoading, setLocalLoading] = useState(false)

  const printAll = useRef(false)
  const ticketsArea = useRef(null)
  const selectedTicket = useRef(null)
  const ticketsRefs = useRef([])
  const [idleDonePaused, setIdleDonePaused] = useAtom(idleDoneTimerPaused)

  const [wasPrinted, setWasPrinted] = useState(false)

  const [currentTicketsScroll, setCurrentTicketsScroll] = useState(0)
  const [showScrollBottom, setShowScrollBottom] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  // Todos los atoms para reset
  // const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayments, setBookingPayments] = useAtom(bookingSessionPayments)
  const [payment, setPayment] = useAtom(pinpadPayment)

  const bookingTickets = [
    {
        "corridaId": 154300,
        "ikey": "ZATDCVAYAV885",
        "empresa": 1,
        "empresa_nombre": "TRANSPAIS UNICO S.A. DE C.V.",
        "empresa_rfc": "TUN930101L63",
        "empresa_direccion": "Carrera Torres 2315. Cd. Victoria, Tam.",
        "folio": "020026120885",
        "asiento": "16",
        "categoria": "Adulto",
        "pasajero": "Julio Alexis",
        "precio": "634.00",
        "origen": "Cd. Victoria",
        "origenKey": "VIC",
        "destino": "Monterrey",
        "destinoKey": "MTY",
        "fechaSalida": "01/09/2023 06:00",
        "leyenda": null,
        "servicios": [
            {
                "id": 64,
                "nombre": "ASISTENCIA MEDICA",
                "precio": "14.00",
                "subtotal": "12.07",
                "iva": "1.93"
            },
            {
              "id": 64,
              "nombre": "reembolso MEDICA",
              "precio": "14.00",
              "subtotal": "12.07",
              "iva": "1.93"
            },
            {
              "id": 64,
              "nombre": "taxi MEDICA",
              "precio": "14.00",
              "subtotal": "12.07",
              "iva": "1.93"
          }
        ],
        "descClase": "DIRECTA"
    },
    {
        "corridaId": 154300,
        "ikey": "ZATDCEABEB886",
        "empresa": 1,
        "empresa_nombre": "TRANSPAIS UNICO S.A. DE C.V.",
        "empresa_rfc": "TUN930101L63",
        "empresa_direccion": "Carrera Torres 2315. Cd. Victoria, Tam.",
        "folio": "020026120886",
        "asiento": "15",
        "categoria": "Menor",
        "pasajero": "Julio Lopez",
        "precio": "317.00",
        "origen": "Cd. Victoria",
        "origenKey": "VIC",
        "destino": "Monterrey",
        "destinoKey": "MTY",
        "fechaSalida": "01/09/2023 06:00",
        "leyenda": null,
        "servicios": [
            {
                "id": 64,
                "nombre": "ASISTENCIA MEDICA",
                "precio": "14.00",
                "subtotal": "12.07",
                "iva": "1.93"
            }
        ],
        "descClase": "DIRECTA"
    },
    {
        "corridaId": 144759,
        "ikey": "ZATDCEAYDY887",
        "empresa": 1,
        "empresa_nombre": "TRANSPAIS UNICO S.A. DE C.V.",
        "empresa_rfc": "TUN930101L63",
        "empresa_direccion": "Carrera Torres 2315. Cd. Victoria, Tam.",
        "folio": "020026120887",
        "asiento": "8",
        "categoria": "Adulto",
        "pasajero": "Julio Alexis",
        "precio": "392.50",
        "origen": "Monterrey",
        "origenKey": "MTY",
        "destino": "Cd. Victoria",
        "destinoKey": "VIC",
        "fechaSalida": "31/08/2023 07:30",
        "leyenda": null,
        "servicios": [
            {
                "id": 64,
                "nombre": "ASISTENCIA MEDICA",
                "precio": "14.00",
                "subtotal": "12.07",
                "iva": "1.93"
            }
        ],
        "descClase": "DIRECTA"
    },
    {
        "corridaId": 144759,
        "ikey": "ZATDCWABCX888",
        "empresa": 1,
        "empresa_nombre": "TRANSPAIS UNICO S.A. DE C.V.",
        "empresa_rfc": "TUN930101L63",
        "empresa_direccion": "Carrera Torres 2315. Cd. Victoria, Tam.",
        "folio": "020026120888",
        "asiento": "12",
        "categoria": "Menor",
        "pasajero": "Julio Lopez",
        "precio": "302.00",
        "origen": "Monterrey",
        "origenKey": "MTY",
        "destino": "Cd. Victoria",
        "destinoKey": "VIC",
        "fechaSalida": "31/08/2023 07:30",
        "leyenda": null,
        "servicios": [
            {
                "id": 64,
                "nombre": "ASISTENCIA MEDICA",
                "precio": "14.00",
                "subtotal": "12.07",
                "iva": "1.93"
            }
        ],
        "descClase": "DIRECTA"
    }
]

  const fechaSalida = bookingTickets[0]?.fechaSalida;
  const fecha = fechaSalida ? (fechaSalida.split(' ')[0]) : '-'
  const hora = fechaSalida ? (fechaSalida.split(' ')[1]) : '-'
  const {showTimer, hideTimer, setupTimer} = useTimer()

  const {reset} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()
  const [ticketsAdm, setTicketsAdm] = useState()
  const [canSearch, setCanSearch] = useState(true)

  const exit = () => {
    // setBookingTickets([])
    setPayment({})
    setBookingPayments({})
    reset()

    navigate(`${ BASE_URL }/`)
  }

  const printDone = () => {
    loggerDebug('Printing completed...', printAll, selectedTicket.current, ticketsRefs.current.length)
    if (printAll.current && selectedTicket.current < (ticketsRefs.current.length - 1)) {
      selectedTicket.current++
      ticketsRefs.current[selectedTicket.current].printTicket()
    } else {
      selectedTicket.current = 0
      printAll.current = false
      setWasPrinted(true)
    }
  }

  const askPrintAll = () => {
    setupModal({
      title: 'Imprimir',
      content: <p>Estás a punto de imprimir todos tus boletos, toma cada uno conforme vaya saliendo ¿deseas continuar
        con la impresión?</p>,
      actions: {
        positive: {
          title: 'Si',
          onClick: () => {
            printAll.current = true
            selectedTicket.current = 0
            ticketsRefs.current[selectedTicket.current].printTicket()
            hideModal()
          }
        },
        negative: {
          title: 'No',
          onClick: () => {
            //selectedTicket.current = null
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const askPrint = () => {
    setupModal({
      title: 'Imprimir',
      content: <p>Al parecer ya imprimiste este boleto, ¿deseas imprimirlo una vez más?</p>,
      actions: {
        positive: {
          title: 'Reimprimir',
          onClick: () => {
            console.log(ticketsRefs.current[selectedTicket.current])
            console.log(ticketsRefs.current)
            console.log(selectedTicket.current)
            ticketsRefs.current[selectedTicket.current].printTicket()
            selectedTicket.current = null
            hideModal()
          }
        },
        negative: {
          title: 'No',
          onClick: () => {
            selectedTicket.current = null
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const askExit = () => {
    setupModal({
      title: 'Atención',
      content: <p>No imprimiste tus boletos, puedes verlos en tu app Transpais, ¿deseas volver al inicio?</p>,
      styles: {
        buttonType: 'small',
      },
      actions: {
        positive: {
          title: 'Quedarme a imprimirlos',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Volver al inicio sin imprimirlos',
          onClick: () => {
            hideModal()
            exit()
          }
        }
      }
    })
    showModal()
  }

  useEffect(() => {
    loggerDebug(ticketsArea.current.scrollTop)
    loggerDebug(ticketsArea.current.offsetHeight)
    loggerDebug(ticketsArea.current.scrollHeight)
    if (ticketsArea.current.scrollHeight > ticketsArea.current.offsetHeight) {
      setShowScrollBottom(true)
    }
  }, [ticketsArea])

  const handleScroll = (e) => {
    setCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  useEffect(() => {
  })

  useEffect(() => {
    if (currentTicketsScroll > 0) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false)
    }

    if ((ticketsArea.current.scrollTop + ticketsArea.current.offsetHeight) === ticketsArea.current.scrollHeight) {
      setShowScrollBottom(false)
    } else {
      setShowScrollBottom(true)
    }
  }, [currentTicketsScroll])

  useEffect(() => {
    setIdleDonePaused(false)
    hideTimer()
  }, [])

  useEffect(() => {
    if (isEmpty(ticketsAdm) && canSearch) {
      setLocalLoading(true)
      transpais.ticketsAdmin(transpaisIdToken)
      .then((response) => {
        let dataTickets = response.data.active
        const ticketsFilter = dataTickets.filter(prop => prop.ikey !== null);
        if (ticketsFilter.length > 0) {
          setTicketsAdm(ticketsFilter)
        } else {
          setCanSearch(false)
          setupModal({
            title: 'Atención',
            content: <>
            <p>Al parecer no se encontraron tickets activos, deseas reiniciar la busqueda?</p>
            </>,
            actions: {
              positive: {
                title: 'Si',
                onClick: () => {
                  setCanSearch(true)
                  hideModal()
                }
              },
              negative: {
                title: 'No',
                onClick: () => {
                  setCanSearch(false)
                  exit()
                  hideModal()
                }
              }
            }
          })
          showModal()
          setLocalLoading(false)
        }
        setLocalLoading(false)
      }).catch((error) => {
        const message = error.response.data
        setupModal({
          title: 'Atención',
          content: <>
          <p>{message.message}</p>
          </>,
        })
        showModal()
        setLocalLoading(false)
      })
    hideModal()
    }
  },[ticketsAdm, canSearch])

  return (
    <>
      <Helmet>
      </Helmet>
      <Loader show={ localLoading }/>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', 'small', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            {/* <div className={ styles.thanks }>
              <h1>¡GRACIAS!</h1>
              <p>Imprime cada uno de tus boletos.</p>
            </div> */}
          </div>
          <div className={ styles.middle }>
            <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { ticketsAdm?.map((ticket, i) => {
                  return <Ticket key={ i } ref={(element) => {
                    ticketsRefs.current[i] = element
                  } } ticket={ ticket } payment={ payment } confirmation={ bookingPayments }
                          printable={ true } ask={ () => {
                    selectedTicket.current = i
                    askPrint()
                  } } done={ printDone }/>
                })}
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                  } }
                />
              </div> }
            </div>
            {/* <div className={ styles.resume }>
              <div className={ [styles.receipt].join(' ') }>
                <h1>Tu pago</h1>
                <div className={ styles.group }>
                  <p>Fecha</p>
                  <span className={ styles.data }>{ `${ fecha } ${ hora }` }</span>
                </div>
                <div className={ styles.group }>
                  <p>Referencia</p>
                  <span className={ styles.data }>{ payment.reference }</span>
                </div>
                <div className={ styles.multiGroup }>
                  <div className={ styles.group }>
                    <p>Operación</p>
                    <span className={ styles.data }>{ payment.foliocpagos }</span>
                  </div>
                  <div className={ styles.group }>
                    <p>Importe</p>
                    <span className={ styles.data }>{ payment?.amount ? currency(payment.amount) : '-' }</span>
                  </div>
                </div>
                <p className={ styles.disclaimer }>Por este pagaré me obligo incondicionalmente a pagar a la orden del
                  banco emisor el importe de este título en los términos del contrato suscrito para el uso de esta
                  tarjeta de crédito en el caso de operaciones con tarjeta de debito, expresamente reconozco y acepto
                  este recibo es el comprobante de la operación realizada, misma que se consigna en el anverso y tendrá
                  pleno valor probatorio y fuerza legal, en virtud de que lo firme personalmente y/o digite mi numero de
                  identificacion personal como firma electrónica el cual es exclusivo de mi responsabilidad manifestando
                  plena conformidad al respecto.</p>
                <Button onClick={ askPrintAll } type="neutral">Imprimir todos</Button>
              </div>
            </div> */}
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
          </div>
          <div className={ global.middle }>
            <Button onClick={ () => {
              if (wasPrinted) {
                exit()
              } else {
                askExit()
              }
            } } type="positive" right-icon="house">Listo</Button>
          </div>
          <div>
          </div>
        </footer>
      </section>
    </>
  )
}

export default PrintTicket

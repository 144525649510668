import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'

import MIT from '../config/pinpad'
import { useAtom } from 'jotai'
import { idleTimerPaused, reservationTickets } from '../utilities/atoms'
import { LOADING_TIMEOUT, DEBUG_SEND_CONSULTA } from '../utilities/constants'
import * as Sentry from '@sentry/react'
import { useLogger } from './logger.context'
import { format } from 'date-fns'

const PinpadContext = createContext(null)

export function PinpadProvider(props) {
    const {loggerDebug, loggerError, loggerWarn, loggerLog} = useLogger()

    const [isInitializing, setIsInitializing] = useState(false)
    const [port, setPort] = useState(null)
    //const [user, setUser] = useState(null)
    const user = useRef(null)
    //const [pinpad, setPinpad] = useState(null)
    const pinpad = useRef(null)
    const [timerId, setTimerId] = useState(null)
    const [loading, setLoading] = useState(true)
    //const [company, setCompany] = useState(1)
    const company = useRef(null)
    //const [companyName, setCompanyName] = useState('TUN')
    const companyId = useRef(0)
    const companyName = useRef(null)
    const [charge, setCharge] = useState(null)

    const [tickets, setTickets] = useAtom(reservationTickets)
    const [idlePaused, setIdlePaused] = useAtom(idleTimerPaused)

    let onErrorCallback = useRef(null)

    const currentCredentials = useRef(null)
    const currentReference = useRef(null)

    //function showLoader() {
    //  setIsLoaderVisible(true)
    //}

    //function hideLoader() {
    //  setIsLoaderVisible(false)
    //}
    function init() {
        loggerDebug('Init Pinpad context')
        if (typeof window.chrome !== 'undefined') {
            setLoading(true)
            loggerDebug('Mostramos Loading')
            loggerDebug('init', isInitializing)
            //if (!isInitializing) {
            if (!isInitializing) {
                loggerDebug('entro a port')
                connect()
            } else if (port == null && isInitializing === false) {
                setIsInitializing(false)
            }
        }
    }

    function reset() {
        setCharge(null)
    }

    function onError(callback) {
        loggerDebug('SET ERROR CALLBACK')
        onErrorCallback.current = callback
    }

    function throwError(type, code, message) {
        if (onErrorCallback.current != null) {
            loggerDebug('con error callback')
            onErrorCallback.current(type, code, message)
        } else {
            loggerDebug('sin error callback')
        }
    }

    function changeCompany(data) {
        loggerDebug('Cambiando de', company.current, data, companyName.current)
        if (company.current == null || data.name !== company.current.name) {
            setLoading(true)
            //setCompany(empresaId)
            //setCompanyName(empresaId === 1 ? 'TUN' : 'TSL')
            company.current = data
            companyName.current = company.current.empresaId === 1 ? 'TUN' : company.current.empresaId === 552 ? 'TSL' : 'XXX'
            //setPinpad(null)
            pinpad.current = null
            login()
        }
        loggerDebug('Cambiando de', company.current, data, companyName.current)
    }

    /*useEffect(() => {
      loggerDebug('Intento login en effect', company, isInitializing)
      if (port != null && company != null && !isInitializing) {
        loggerDebug('Hago login en effect', company, isInitializing)
        login(company)
      }
    }, [company])*/

    function getCredentials() {
        loggerDebug('getCredentials', MIT)
        return {
            env: MIT.environment,
            user: company.current.name,
            pass: company.current.value,
        }
    }

    function connect() {
        company.current = null
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'launchApp'})
        portL.postMessage(MIT.chrome_appid)
        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            const objLogin = JSON.parse(response)
            loggerDebug('launch', objLogin)
            if (objLogin['RESPUESTA'] === 'ok') {
                setIsInitializing(true)
                if (tickets.length > 0) {
                    const empresa = tickets[0].itemDatosConexion
                    changeCompany(empresa)
                }

                if (company.current == null) {
                    //  loggerDebug('Hago login en port ', company.current)
                    //  login()
                    //} else {
                    setLoading(false)
                }
            } else {
                loggerError('No connect')
            }

        })
    }

    function login(callback = () => {
    }) {
        const credentials = getCredentials()

        const objDataLogin = {
            Ambiente: credentials.env,
            Usuario: credentials.user,
            Pass: credentials.pass,
        }

        loggerDebug('login', objDataLogin)
        Sentry.setContext('data', objDataLogin)
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'login'})
        portL.postMessage(objDataLogin)
        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            loggerDebug('respWP-' + response)

            let obj = JSON.parse(response)
            Sentry.setContext('response', obj)
            if (obj == null) {
                setLoading(false)
                setIdlePaused(true)
                Sentry.captureMessage('No hubo login')
                return
            }

            let aux = obj['RESPUESTA']

            if (aux == null || aux === 'error') {
                loggerDebug('quito loading en login error')
                setLoading(false)
                //TODO: Mostrar alerta de error y refrescar el sitio.
                loggerError(obj['ERROR'])
                Sentry.captureMessage('Error en login')
            } else {
                //setUser(obj)
                user.current = obj
                //localStorage.setItem('datosusuario', response)
                //localStorage.setItem('user', usuario +'|' + contra)

                //var datosUser = localStorage.getItem('datosusuario')
                //var objUser
                //const objUser = JSON.parse(datosUser)

                getKeys(credentials, callback)
            }
        })
    }

    function getKeys(credentials, callback = () => {
    }) {

        const objDataKeys = {
            Ambiente: credentials.env,
            Usuario: credentials.user,
            Pass: credentials.pass,
            Country: user.current.bs_country,
            IdBranch: user.current.bs_branch,
            IdCompany: user.current.bs_company,
        }

        //Se llaman las llaves RSA
        loggerDebug('getKeyRSA', objDataKeys)
        Sentry.setContext('data', objDataKeys)
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'getKeysRSA'})
        portL.postMessage(objDataKeys)

        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            loggerDebug('respWP' + response)
            //localStorage.setItem('datosPinPad', response)

            const obj = JSON.parse(response)
            const aux = obj['RESPUESTA']
            Sentry.setContext('response getKeys', obj)
            if (aux == null || aux === 'error') {
                loggerDebug('quito loading en get keys error')
                setLoading(false)
                //TODO: Mostrar alerta de error y refrescar el sitio.
                loggerError(obj['ERROR'])
                Sentry.captureMessage('Error en getKeys')
                login()
            } else {
                //setPinpad(obj)
                loggerDebug('debe quitarse el loading getKeyRSA')
                setLoading(false)
                pinpad.current = obj
                loggerDebug(obj)
                callback()
            }
        })
    }

    function readCard(payment_reference, amount) {
        loggerDebug('pongo loading de read')
        setLoading(true)
        setIdlePaused(true)
        loggerDebug('Company: ', company.current)
        const credentials = getCredentials()

        loggerDebug('Pinpad:', pinpad.current)
        if (pinpad.current == null) {
            loggerError('Pinpad es null')
            //setLoading(true)
            login(() => {
                readCard(payment_reference, amount)
            })
            return
        }

        const objDataTRX = {
            Ambiente: credentials.env,
            Currency: 'MXN',
            CurrencyCode: '0484',
            Amount: '' + amount,
            MarcaTerminal: pinpad.current.marca,
            ModeloTerminal: pinpad.current.modelo,
            SoportaCTLS: pinpad.current.soportaCTLS,
        }

        loggerDebug('readCard', objDataTRX)
        Sentry.setContext('data', objDataTRX)

        let portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'readCard'})
        portL.postMessage(objDataTRX)

        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            loggerDebug('respWP-' + response)

            const objTRX = JSON.parse(response)
            const aux = objTRX['RESPUESTA']
            Sentry.setContext('response readCard', objTRX)
            if (aux == null || aux === 'error') {
                setLoading(false)
                //TODO: Mostrar alerta de error cancelada.
                loggerError(objTRX.codError, objTRX.ERROR)
                let resp = objTRX.nb_respuesta

                if (resp === '' || resp == null) {
                    resp = objTRX.ERROR
                }
                Sentry.captureMessage('Error en readcard')
                setIdlePaused(false)
                throwError('readCard', objTRX.codError, resp)
            } else {
                //SE PROCESA EL RESULTADO DE LA TARJETA
                //$('#txtNumTar').val(objTRX['maskPan'])
                //$('#txtNomTar').val(objTRX['name'])
                //$('#txtFecha').val(objTRX['month'] + '/' + objTRX['year'])
                loggerDebug(objTRX)

                let binT = ''
                if (objTRX.maskPan.length > 6) {
                    binT = objTRX.maskPan.substring(0, 6)
                }

                getMerchant(credentials, binT, objDataTRX.Currency, payment_reference)
            }
        })

    }

    function getMerchant(credentials, bin, currency, payment_reference) {
        const objDataMerchant = {
            Ambiente: credentials.env,
            BIN: bin,
            User: credentials.user,
            Currency: currency,
        }

        loggerDebug('getMerchant', objDataMerchant)
        Sentry.setContext('data getmeerchant', objDataMerchant)
        //Get Afiliaciones
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'getMerchant'})
        portL.postMessage(objDataMerchant)
        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            loggerDebug('respWP getMerchant-' + response)

            const objTRX = JSON.parse(response)
            const aux = objTRX['respuesta']
            Sentry.setContext('response merchant', objTRX)

            if (aux == null || aux === '0') {

                let resp = objTRX.nb_respuesta

                if (resp === '' || resp == null) {
                    resp = objTRX.ERROR
                }
                setLoading(false)
                //TODO: Mostrar alerta de error y refrescar el sitio.
                loggerError(resp)
                Sentry.captureMessage('Error en get merchant')
                setIdlePaused(false)
                throwError('getMerchant', objTRX.codError, resp)
            } else {

                //se muestran las afiliaciones
                const nodoContado = objTRX.contado
                const nodoMSI = objTRX.msi
                const nodoMCI = objTRX.plazosmci

                if (nodoContado === undefined) {
                    loggerWarn('No contado')
                } else {
                    loggerDebug('Si contado')
                }

                if (nodoMSI === undefined) {
                    loggerWarn('No msi')
                } else {
                    loggerDebug('Si msi')
                }

                if (nodoMCI === undefined) {
                    loggerWarn('No mci')
                } else {
                    loggerDebug('Si mci')
                }

                ventaDirecta(credentials, currency, nodoContado.af, payment_reference)
            }
        })
    }

    function ventaDirecta(credentials, currency, contado, payment_reference) {
        const objDataVenta = {
            Ambiente: credentials.env,
            Country: user.current.bs_country,
            IdBranch: user.current.bs_branch,
            IdCompany: user.current.bs_company,
            pwd: credentials.pass,
            User: credentials.user,

            UserTRX: 'userPinpadWeb',

            EMV: pinpad.current.EMV,
            ModeloTerminal: pinpad.current.modelo,
            SerieTerminal: pinpad.current.serie,
            Contactless: pinpad.current.soportaCTLS,
            Printer: pinpad.current.impresora,
            VersionTerminal: pinpad.current.versionApp,

            TpOperation: 11,
            Reference: payment_reference,
            Currency: currency,
            Merchant: contado.merchant,
            Reverse: user.current.ExecuteReverse,
        }

        currentCredentials.current = credentials
        currentReference.current = payment_reference

        loggerDebug('sndVentaDirectaEMV', objDataVenta)
        Sentry.setContext('sndVentaDirecta data', objDataVenta)
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'sndVentaDirectaEMV'})
        portL.postMessage(objDataVenta)
        portL.onMessage.addListener(function getResp(response, sender) {
            //--sender.disconnect()
            loggerLog('respWP-' + response)

            const objResponse = JSON.parse(response)
            Sentry.setContext('response venta', objResponse)

            setLoading(false)
            setIdlePaused(false)

            processResponse(objResponse, false)
        })
    }

    function processResponse(objResponse, debuggable) {
        if (objResponse['response'] !== 'approved') {
            let codeError
            let msgError

            if (objResponse['response'] === 'denied') {
                codeError = objResponse['cd_response']
                msgError = objResponse['friendly_response']
            } else {
                let resp, codeError

                if (objResponse['response'] === 'error') {
                    codeError = objResponse['cd_error']
                    resp = objResponse['nb_error']

                    const respTRX = 'Response: ' + objResponse['response'] + '  /  No. Operación: ' + objResponse['foliocpagos'] + '  /  CodeError: ' + codeError + '  /  DesError: ' + resp
                    loggerError(respTRX)
                    Sentry.captureMessage('No hubo venta')
                } else {
                    codeError = objResponse['codError']
                    resp = objResponse['ERROR']

                    const respTRX = 'Response: ' + objResponse['RESPUESTA'] + '  /  Error: ' + objResponse['ERROR'] + '  /  CodeError: ' + codeError + '  /  DesError: ' + resp
                    loggerError(respTRX)
                    Sentry.captureMessage('No hubo venta')
                    //TODO: Mostrar alerta con el error y refrescamos
                }

            }
            throwError('sndVentaDirectaEMV', codeError, msgError)
            Sentry.captureMessage('No hubo venta')
        } else {
            const respTRX = 'Response: ' + objResponse['response'] + '  /  No. Operación: ' + objResponse['foliocpagos'] + '  /  No. Auth: ' + objResponse['auth'] + '  /  CdResponse: ' + objResponse['cd_response'] + '  /  Arqc: ' + objResponse['arqc'] + '  /  Aid: ' + objResponse['appid'] + '  /  AidLabel: ' + objResponse['appidlabel']

            let respVoucher = 'VoucherComercio: '
            respVoucher = respVoucher + '\n' + objResponse['voucher_comercio']

            respVoucher = respVoucher + 'VoucherCliente'
            respVoucher = respVoucher + '\n' + objResponse['voucher_cliente']

            loggerDebug(respTRX)
            loggerDebug(respVoucher)

            if (!debuggable) {
                //sndConsulta(credentials, payment_reference, objResponse['date'])
                //} else {
                setCharge(objResponse)
            }
        }
    }

    function sndReimpresion(credentials, reference) {
        loggerDebug('sndReimpresion')

        const date = format(new Date(), 'dd/MM/yyyy')

        const objDataConsulta = {
            Ambiente: credentials.env,
            User: credentials.user,
            Pwd: credentials.pass,
            IdBranch: user.current.bs_branch,
            IdCompany: user.current.bs_company,
            Country: user.current.bs_country,
            Tx_OperationNumber: reference,
        }
        loggerDebug('sndReimpresion: ')
        loggerDebug(objDataConsulta)
        const portL = window.chrome.runtime.connect(MIT.chrome_appid, {name: 'sndReimpresion'})
        portL.postMessage(objDataConsulta)

        portL.onMessage.addListener(function getResp(response) {
            console.log("respWP Reimp-" + response);

            const objResponse = JSON.parse(response)
            Sentry.setContext('response venta', objResponse)

            setLoading(false)
            setIdlePaused(false)

            processResponse(objResponse, false)
        })
    }

    useEffect(() => {
        if (loading) {
            loggerDebug('start timeout')
            const id = setTimeout(() => {
                loggerDebug('entro al timeout', timerId)
                sndReimpresion(currentCredentials, currentReference)
                // setLoading(false)
                // setIdlePaused(false)
                // Sentry.captureMessage('Timeout de pinpad')
                // connect()
                // throwError('timeout', '408', 'Lo sentimos, el proceso tardó más de lo esperado, por favor inténtalo de nuevo.')
            }, LOADING_TIMEOUT)
            loggerDebug('timer id', id)
            setTimerId(id)

            return () => clearTimeout(id)
        } else {
            loggerDebug('stop timeout')
            loggerDebug('clear', timerId)
            clearTimeout(timerId)
            setTimerId(null)
            setIdlePaused(false)
        }
        loggerDebug('timer id', timerId)
    }, [loading])

    /*useEffect(() => {
      setLoading(true)
      loggerDebug('Mostramos Loading')
      loggerDebug('port', port)
      loggerDebug('init', isInitializing)
      //if (!isInitializing) {
      if (port && !isInitializing) {
        loggerDebug('entro a port')
        connect()
      } else if (port == null && isInitializing === false) {
        setIsInitializing(false)
      }
    //}, [port])
  */
    useEffect(() => {
        loggerLog('init?')
        init()
    }, [])

    const value = useMemo(() => {
        return {
            readCard,
            loading,
            charge,
            reset,
            changeCompany,
            companyName,
            onError,
            sndReimpresion,
            getCredentials,
        }
    }, [loading, charge, companyName])

    return <PinpadContext.Provider value={value} {...props}/>
}

export function usePinpad() {
    const context = useContext(PinpadContext)

    if (!context) {
        throw new Error('usePinpad debe estar dentro del proveedor PinpadContext')
    }

    return context
}

import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import PrintableTicket from '../../components/transpais/TranspaisPrintableTicket'

import { TicketType } from '../../utilities/constants'

import styles from './AdminTicket.module.scss'
import { useAtom } from 'jotai'
import { idleDoneTimerPaused } from '../../utilities/atoms'
import { useLogger } from '../../contexts/logger.context'
import { currency } from '../../components/transpais/helpers/currency'
import { PassengerType, TicketService } from '../../components/transpais/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TranspaisTicket = forwardRef(({
                              ticket,
                              redondo,
                              regreso,
                              tripData/* = null*/,
                              payment,
                              confirmation,
                              printable/* = null*/,
                              ask,
                              done,
                              onClick
                            }, ref) => {
                              // console.log(ref)
                              // console.log(ask)
  const printableRef = createRef()
  //const isPrinting = false//useDetectPrint()
  const {loggerDebug, loggerError} = useLogger()

  const [, setIdleDonePaused] = useAtom(idleDoneTimerPaused)
  const [printed, setPrinted] = useState(false)
  //const [selected, setSelected] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => {
      loggerDebug('Content', printableRef, printableRef.current)
      return printableRef.current
    }, onBeforePrint: () => {
      setIdleDonePaused(true)
    }, //removeAfterPrint: false,
    onAfterPrint: () => {
      //setIdlePaused(false)
      setIdleDonePaused(false)
      setPrinted(true)
      done()
    }, onPrintError: (errorLocation, error) => {
      loggerError(errorLocation, error)
      setIdleDonePaused(false)
    }
  })

  useImperativeHandle(ref, () => ({
    printTicket() {
      handlePrint()
    }
  }))

  const printTicket = () => {
    //loggerDebug('Printed', printed)
    //setIdlePaused(true)
    if (printed) {
      loggerDebug('Printed', 'ask')
      ask()
    } else {
      setPrinted(true)
      handlePrint()
    }
  }
  return (<div ref={ ref }>
    <div
      className={ [styles.ticket].join(' ') }
      onClick={ () => !printable ? onClick(ticket) : null }>
      <div className={ styles.seat }>
        <h3>Asiento</h3>
        <p>{ ticket.seat ?? ticket.asiento }</p>
      </div>
      <div className={ [styles.info, printable ? styles.small : null].join(' ') }>
        <div className={ styles.data }>
          <p className={ styles.name }>{ ticket.pasajero ?? ticket.nombPasajero }</p>
          <p className={ styles.type }>
            <svg className={ [styles.category, styles.icon].join(' ') }>
              <use xlinkHref={ `#${ TicketType.icon(ticket.tipo || ticket.categoria) }` }/>
            </svg>
            <span>{ TicketType.label(ticket.tipo || ticket.categoria) }</span>
            <span className={ [styles.tag, regreso ? styles.return : null].join(' ') }>
              { ticket?.origen } › { ticket?.destino }
            </span>
          </p>

        </div>
        <div className={ styles.data }>
          <div className={ styles.servicesContainer }>
            { ticket.servicios ? ticket.servicios.map((servicio) => (
              <span key={ `t_${ ticket.id }_${ servicio.id }` } className={ styles.servicio }><FontAwesomeIcon
                icon={ TicketService.icon(servicio.nombre) }
                className={ styles.icon }/></span>
            )) : null }
          </div>
        </div>
      </div>
      <div className={ styles.precio }>
        <p>{ currency(ticket ? ticket.precio : 0) }</p>
      </div>
      <div className={ styles.print } onClick={ printTicket }>
        <svg className={ [styles.icon, printed ? styles.done : null].join(' ') }>
          <use xlinkHref={ `#${ !printed ? 'print' : 'check' }` }/>
        </svg>
        {/* <span>Imprimir</span> */}
      </div>
    </div>
    { printable && (<div style={ {display: 'none'} }><PrintableTicket ref={ printableRef } ticket={ ticket }
                                                                          payment={ payment }
                                                                          confirmation={ confirmation }/>
    </div>) }
  </div>)
})

export default TranspaisTicket

import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { Ray, ray } from 'node-ray/web'

import { Button, IconButton, Loader, Timer, InputAutocomplete } from '../../components/transpais'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL, DEBUG_PINPAD } from '../../utilities/constants'
import { isEmpty } from 'lodash'
import jwt_decode from "jwt-decode"

import {
  accessToken,
  bookingSessionDepartureBlockedTickets,
  bookingSessionReturningBlockedTickets,
  bookingSessionDepartureTrip,
  bookingSessionDestination,
  bookingSessionOrigin,
  // bookingSessionTripId,
  bookingSessionTickets,
  bookingSessionPayments,
  pinpadPayment,
  bookingSessionTimeoutDeadline,
  usuarioID, bookingSessionReturningTrip, bookingSessionReset,
  bookingSessionTripConfig,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  TranspaisIDToken
} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'

import '../../scss/Layout.scss'
import global from './BookingGlobal.module.scss'
import styles from './BookingReviewOrder.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLogger } from '../../contexts/logger.context'
import { Breadcrumbs } from '@sentry/react'
import { currency } from '../../components/transpais/helpers/currency'
import SaleTicket from './SaleTicket'
import { formatISO, parse, parseISO } from 'date-fns'
import Keyboard from 'react-simple-keyboard'
import transpaisid from '../../services/transpaisid'
import transpais from '../../services/transpais'

import { useBackListener } from '../../hooks/useBackListener'
import { useTimer } from '../../contexts/timer.context'
import { formatInTimeZone } from 'date-fns-tz'

const BookingReviewOrder = () => {

  const backReturn = useRef(false)
  const ticketsArea = useRef(null)
  const [localLoading, setLocalLoading] = useState(false)
  const [searchParam] = useState(['label', 'cve'])
  const [search, setSearch] = useState('')
  const [filteredEmails, setFilteredEmails] = useState([])

  const keyboard = useRef()
  const [keyboardLayout, setKeyboardLayout] = useState('default')

  const viajeIda = useRef(null)
  const viajeRegreso = useRef(null)
  const currentUser = useRef(null)
  const timeDone = useRef(false)
  const cursorPosition = useRef(0)
  const enableCursor = useRef(false)
  const [showMails, setShowMails] = useState(false)

  const [showScreenKeyboard, setShowScreenKeyboard] = useState(false)
  const [email, setEmail] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)

  const [currentTicketsScroll, setCurrentTicketsScroll] = useState(0)
  const [showScrollBottom, setShowScrollBottom] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [canSetDataCharge, setCanSetDataCharge] = useState(false)

  const [usuario,] = useAtom(usuarioID)
  const [bookingReset, setBookingReset] = useAtom(bookingSessionReset)
  const [bookingOrigin,] = useAtom(bookingSessionOrigin)
  const [bookingDestination,] = useAtom(bookingSessionDestination)
  const [bookingDepartureTrip,] = useAtom(bookingSessionDepartureTrip)
  const [bookingReturningTrip,] = useAtom(bookingSessionReturningTrip)
  // const [bookingTripId, setBookingTripId] = useAtom(bookingSessionTripId)
  const [bookingDepartureBlockedTickets, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [bookingReturningBlockedTickets, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayment, setBookingPayment] = useAtom(bookingSessionPayments)
  const [bookingTimeoutDeadline, setBookingTimeoutDeadline] = useAtom(bookingSessionTimeoutDeadline)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  const [bookingDepartureTripId, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [bookingReturningTripId, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  const [, setPayment] = useAtom(pinpadPayment)

  //DEBUG: copmentar
  // const [charge, setCharge] = useState(null)
  const navigate = useNavigate()
  const {loading, readCard, charge, onError, getCredentials} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()
  const {loggerDebug, loggerLog} = useLogger()
  const {showTimer, setupTimer, hideTimer, waitingPayment} = useTimer()

  const dataCharge = {
    "reference": "KV010054798390-Y28D",
    "response": "approved",
    "foliocpagos": "100322235",
    "auth": "791700",
    "cd_response": "00",
    "cd_error": "",
    "nb_error": "",
    "time": "17:03:58",
    "date": "13/12/2022",
    "nb_company": "PRUEBAS INTEGRACIONES DLL",
    "nb_merchant": "7628597 VMC",
    "nb_street": "CORREGIDORA 92 COL. ZACAYUCAN PENA POBRE, DF",
    "cc_type": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "tp_operation": "VENTA",
    "cc_name": "",
    "cc_number": "4240",
    "cc_expmonth": "01",
    "cc_expyear": "26",
    "amount": "175.00",
    "voucher_comercio": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n@cnn 9249TDUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n@br \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "voucher_cliente": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@br \n@cnb -C-L-I-E-N-T-E- \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
    "friendly_response": "Agregado en QA",
    "appid": "A0000000041010",
    "appidlabel": "DEBITO/TOKA INTERNACIONAL/MasterCard",
    "arqc": "04F98E9383517767"
  }

  const completeMail = [
    'gmail.com',
    'outlook.com',
    'transpais.com.mx',
    'me.com',
    'hotmail.com',
    'icloud.com',
    'yahoo.com.mx',
    'live.com.mx',
    'yahoo.com',
    'hotmail.es',
    'live.com',
    'hotmail.com.mx',
    'prodigy.net.mx',
    'msn.com',
  ]

  const exit = () => {
    let _bookingTicket = bookingDepartureBlockedTickets
    let returningTicket = false
    if (bookingReturningBlockedTickets.length !== 0) {
      _bookingTicket = bookingReturningBlockedTickets
    }

    let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId
    transpais.unblock(
      transpaisIdToken, _tripId, _bookingTicket.map((ticket) => ticket.id)
    ).then((response) => {
      Sentry.setContext('unblock', {
        tripId: _tripId,
        tickets: _bookingTicket,
      })
      if (returningTicket === true) {
        setBookingReturningBlockedTickets([])
      } else {
        hideTimer()
        setBookingDepartureBlockedTickets([])
        setBookingReturningBlockedTickets([])
      }
      setBookingDepartureTripId(null)
      setBookingReturningTripId(null)
    }).catch((error) => {
      Sentry.setContext('unblock', {
        tripId: _tripId,
        tickets: _bookingTicket,
      })
      Sentry.addBreadcrumb({
        category: 'ibus',
        message: 'unblock',
        level: 'error',
      })
      Sentry.captureException(error)
      setLocalLoading(false)
    })
    navigate(`${ BASE_URL }/`)
  }

  const handleClick = (value) => {
    cursorPosition.current = value
    if(cursorPosition.current === email.length) {
      enableCursor.current = false
    } else {
      enableCursor.current = true
    }
  }
  function onKeyboardKeyPress(button) {
    loggerDebug('Button pressed', button)
    if (button === '{esc}') {
      setShowScreenKeyboard(false)
    } else if (button === '{bksp}') {
      if (enableCursor.current) {
        if (cursorPosition.current > 0) {
          setEmail(email.slice(0, cursorPosition.current -1) + email.slice(cursorPosition.current))
          cursorPosition.current = cursorPosition.current - 1
        }
      }else{
        setEmail(email.slice(0, -1))
      }
      // setEmail(email.slice(0, -1))
      // if (showMails === true) {
      //   if (email.slice(-1) === '@') {
      //     setShowMails(false)
      //   } else {
      //     setSearch(search.slice(0, -1))
      //   }
      // }
    } else if (button === '{shift}' || button === '{lock}') {
      handleShift()
    } else {
      setCodeKey(button)
    }

    //show/hidde select mails extensions
    if (button === '{at}') {
      setShowMails(true)
    }
  }

  const setCodeKey = (key) => {
    //if (barcodeInputValue.length >= RESERVATION_CODE_MAX_SIZE) {
    //loggerDebug('exit and not setting new char')
    //return
    //}

    if (key !== 'Enter' && !(key.includes('{') && key.includes('}'))) {
      //if (!isReading) {
      //  setIsReading(true)
      //}
      enableCursor.current ? setKeyPosition(key) : setEmail(email + key)
      // setEmail(email + key)

      if (key === '@') {
        setShowMails(true)
      }

      // if (showMails) {
      //   setSearch(search + key)
      //   console.log('entra search + key->'+search+key)
      // } else {
      //   setSearch('')
      // }

    } else if (key === '{at}' && email.indexOf('@') === -1) {
      enableCursor.current ? setKeyPosition('@') : setEmail(email + '@')
      // setEmail(email + '@')
    } else if (key === '{space}') {
      enableCursor.current ? setKeyPosition(' ') : setEmail(email + ' ')
      // setEmail(email + ' ')
    } else if (key === '{dotcom}') {
      enableCursor.current ? setKeyPosition('.com') : setEmail(email + '.com')
      // setEmail(email + '.com')
    }
  }

  function setKeyPosition (key) {
    if(key !== '@') {
      let addEmail = email.substring(0, cursorPosition.current) + key + email.substring(cursorPosition.current)
      let increment = key === '.com' ? 4 : 1
      cursorPosition.current = cursorPosition.current + increment
      setEmail(addEmail)
    }
  }

  function handleShift() {
    //loggerDebug(keyboard.current)
    const newLayoutName = keyboardLayout === 'default' ? 'shift' : 'default'
    setKeyboardLayout(newLayoutName)
  }

  /*

  const [token,] = useAtom(accessToken)
  const [usuario_id,] = useAtom(usuarioID)
  const [punto_venta_id,] = useAtom(puntoVentaID)
  const [estacion_id,] = useAtom(estacionID)
  const [tickets, setTickets] = useAtom(reservationTickets)
  const [folio, setFolio] = useAtom(reservationFolio)
  const [, setPayment] = useAtom(pinpadPayment)
  
  const [confirmation, setConfirmation] = useAtom(reservationConfirmation)


  const [canContinue, setCanContinue] = useState(false)

  const cancel = () => {
    setupModal({
      title: 'Atención',
      content: <p>¿Estás seguro de cancelar la operación?</p>,
      actions: {
        positive: {
          title: 'Continuar en el pago',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Cancelar operación',
          onClick: () => {
            setTickets([])
            setFolio('')
            navigate(`${ BASE_URL }/reservations/lookup`)
            hideModal()
          }
        },
      }
    })
    showModal()
  }
*/
  const willPay = () => {
    if (!isEmpty(email)) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setupModal({
          title: 'Atención',
          content: <>
            <p>El correo ingresado no es correcto, por favor verifícalo e inténtalo de nuevo.</p>
          </>,
        })
        showModal()
  
        return
      }
    } else {
      const decodedToken = jwt_decode(transpaisIdToken)
      setEmail(decodedToken.email)
    }

    setShowScreenKeyboard(false)

    setupModal({
      title: 'Pago',
      content: <>
        <p>¿Deseas continuar con el pago de tus boletos?</p>
        <p className={ styles.importantText }>Sigue las instrucciones en el lector de tarjeta</p>
      </>,
      actions: {
        positive: {
          title: 'Pagar',
          onClick: () => {
            // hideTimer()
            let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId
            waitingPayment(true)
            setLocalLoading(true)
            transpais.canPay(transpaisIdToken, _tripId)
              .then((response) => {
                // currentUser.current = response.data
                ray({ service: 'transpais.canPay', _response: response });
                console.log('Response canPay')
                console.log(response)
                if ( response.data.can === true) {
                  console.log('canPay')
                  if ( DEBUG_PINPAD === 'Si' ) {
                    setCanSetDataCharge(true)
                  }
                  const reference = `KV${ bookingDepartureBlockedTickets[0].operacion }-${ Math.random().toString(36).slice(2, 6) }`
                  loggerDebug(reference)
                  // Sentry.setContext('canPay', {
                  //   email: email,
                  //   reference: reference,
                  // })
                  const total = [...bookingDepartureBlockedTickets, ...bookingReturningBlockedTickets].reduce((accumulator, object) => {
                    //const precio = object.selected ? object.precioBoleto : 0
                    const servicios = object.servicios.reduce((suma, servicio) => {
                      return suma + parseFloat(servicio.precio)
                    }, 0)
                    return accumulator + parseFloat(object.precio) + servicios
                  }, 0)

                  onError((type, code, message) => {
                    setCanSetDataCharge(false)
                    Sentry.setContext('Block', {
                      type: type,
                      code: code,
                      message: message,
                    })
                    Sentry.addBreadcrumb({
                      category: 'ibus',
                      message: 'payment',
                      level: 'error',
                    })
                    let msg = null
                    loggerDebug(type, code, message)
                    switch (code) {
                      case '10':
                        msg = <p>Haz cancelado la operación, puedes intentarlo de nuevo.</p>
                        break
                      case '11':
                        msg = <p>No fue posible leer tu tarjeta en el tiempo establecido, inténta pagar una
                          vez más.</p>
                        break
                      case '16':
                        msg = <p>No fue posible leer tu tarjeta, inténtalo nuevamente.</p>
                        break
                      case '408':
                        msg = <p>{ message }</p>
                        break
                      default:
                      //msg = <p>Otro: {code}</p>
                    }
                    setLocalLoading(false)
                    loggerLog(msg)
                    setupModal(genericErrorMessage(msg, () => {
                      waitingPayment(false)
                      hideModal()
  
                    }))
                    showModal()
                  })

                  readCard(reference, total)
                } else {
                  setLocalLoading(false)
                  setupModal({
                    title: 'Atención!',
                    content: <>
                      <p>{ response.data.message }</p>
                    </>,
                  })
                  showModal()
                }
                // readCard(reference, total)
                //DEBUG: comentar aqui
                // setupModal(genericErrorMessage(<p>No fue posible leer tu tarjeta, inténtalo nuevamente.</p>, () => {
                //   waitingPayment(false)
                //   hideModal()
                //   console.log('entro console log --------------------')
                // }))
                // showModal()
                // setTimeout( () => {
                  //   setCharge(
                  //   {
                  //     "reference": "KV010054798390-Y28D",
                  //     "response": "approved",
                  //     "foliocpagos": "100322235",
                  //     "auth": "791700",
                  //     "cd_response": "00",
                  //     "cd_error": "",
                  //     "nb_error": "",
                  //     "time": "17:03:58",
                  //     "date": "13/12/2022",
                  //     "nb_company": "PRUEBAS INTEGRACIONES DLL",
                  //     "nb_merchant": "7628597 VMC",
                  //     "nb_street": "CORREGIDORA 92 COL. ZACAYUCAN PENA POBRE, DF",
                  //     "cc_type": "DEBITO/TOKA INTERNACIONAL/MasterCard",
                  //     "tp_operation": "VENTA",
                  //     "cc_name": "",
                  //     "cc_number": "4240",
                  //     "cc_expmonth": "01",
                  //     "cc_expyear": "26",
                  //     "amount": "175.00",
                  //     "voucher_comercio": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n@cnn 9249TDUS0 \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@cnb -C-O-M-E-R-C-I-O- \n@br \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n@br \n@br \n@br \n@cnn  \n@cnn Autorizado sin Firma \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
                  //     "voucher_cliente": "@cnb Santander \n@cnn VENTA \n@br \n@cnn PRUEBAS INTEGRACIONES DLL \n@cnn (00132) PRUEBAS TP QUIOSCO \n@cnn CORREGIDORA 92  \n@cnn COL. ZACAYUCAN PENA POBRE, DF \n \n@cnn 7628597 VMC \n \n \n@lnn No.Tarjeta: xxxxxxxxxxxx4240 \n \n@lnn DEBITO/TOKA INTERNACIONAL/MasterCard \n@cnn CONTACTLESS-NFC \n@br \n@cnb -C-L-I-E-N-T-E- \n@lnn APROBADA \n \n \n@lnn IMPORTE\n@cnb $ 175.00 MXN \n@cnb Oper.:     100322235 \n@lnn Ref.:      KV010054798390-Y28D\n@lnn ARQC:      ***********7767\n@lnn AID:       A0000000041010 \n@cnb Aut.:      791700 \n \n \n \n@lnn   \n@lnn Fecha: 13/12/2022 17:03:58 \n \n \n@br \n \n@br \n \n@br \n@cnb logo_cpagos \n@cnn ver_app \n@bc 100322235 \n@br \n@lsn POR ESTE PAGARE ME OBLIGO INCONDI \n@lsn CIONALMENTE A PAGAR A LA ORDEN DEL \n@lsn BANCO EMISOR EL IMPORTE DE ESTE  \n@lsn TITULO EN LOS TERMINOS DEL CONTRA \n@lsn TO SUSCRITO PARA EL USO DE ESTA  \n@lsn TARJETA DE CREDITO EN EL CASO DE  \n@lsn OPERACIONES CON TARJETA DE DEBITO, \n@lsn EXPRESAMENTE RECONOZCO Y ACEPTO  \n@lsn ESTE RECIBO ES EL COMPROBANTE DE  \n@lsn LA OPERACION REALIZADA, MISMA QUE \n@lsn SE CONSIGNA  EN EL ANVERSO Y TEN \n@lsn DRA PLENO VALOR PROBATORIO Y FUER \n@lsn ZA LEGAL, EN VIRTUD DE QUE LO FIR \n@lsn ME PERSONALMENTE Y/O DIGITE MI NU \n@lsn MERO DE IDENTIFICACION PERSONAL  \n@lsn COMO FIRMA ELECTRONICA EL CUAL ES  \n@lsn EXCLUSIVO DE MI RESPONSABILIDAD \n@lsn MANIFESTANDO PLENA CONFORMIDAD  \n@lsn AL RESPECTO. \n \n    \n",
                  //     "friendly_response": "Agregado en QA",
                  //     "appid": "A0000000041010",
                  //     "appidlabel": "DEBITO/TOKA INTERNACIONAL/MasterCard",
                  //     "arqc": "04F98E9383517767"
                  //   }
                  // )
                // }, 10000 )
                //DEBUG: comentar hasta aqui
                //readCard(reference, 151000)
              })
            hideModal()
          }
        },
        negative: {
          title: 'No'
        }
      }
    })
    showModal()
  }

  const onBack = () => {
    if (backReturn.current === false) {
      console.log('entro function onBack')
      backReturn.current = true
      console.log('setTrue-> ' + backReturn)
      //Desbloquear tickets bookingDepartureBlockedTickets bookingReturningBlockedTickets
      let _bookingTicket = bookingDepartureBlockedTickets
      let returningTicket = false
      if (bookingReturningBlockedTickets.length !== 0) {
        _bookingTicket = bookingReturningBlockedTickets
        returningTicket = true
      }
      setLocalLoading(true)

      let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId

      transpais.unblock(
        transpaisIdToken, _tripId, _bookingTicket.map((ticket) => ticket.id)
      ).then((response) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      }).catch((error) => {
        Sentry.setContext('unblock', {
          tripId: _tripId,
          tickets: _bookingTicket,
        })
        Sentry.addBreadcrumb({
          category: 'ibus',
          message: 'unblock',
          level: 'error',
        })
        Sentry.captureException(error)
        setLocalLoading(false)

        if (returningTicket === true) {
          setBookingReturningBlockedTickets([])
        } else {
          hideTimer()
          setBookingDepartureBlockedTickets([])
          setBookingReturningBlockedTickets([])
        }
        // setBookingReturningBlockedTickets([])
        // setBookingDepartureBlockedTickets([])
      })

    }
  }

  const addMail = (event, message) => {
    let _email = email.split('@')[0] + '@' + message
    setEmail(_email)
    setShowMails(false)
    setSearch('')
  }

  /*const onTimeout = () => {
    console.log('onTimeout order')
    hideTimer()
    setBookingTimeoutDeadline('')
    setupModal({
      title: 'Tu reserva expiró',
      content: <p>¡Lo sentimos! 😓 No podemos seguir reservando tus asientos porque más personas están intentando
        viajar.
        🚌 Vuelve a iniciar tu compra cuando estés listo.</p>,
      actions: {
        positive: {
          title: 'Aceptar',
          onClick: () => {
            setLocalLoading(true)
            transpais.unblock(
              bookingTripId, bookingDepartureBlockedTickets.map((ticket) => ticket.id)
            ).then((response) => {
              timeDone.current = true
              setLocalLoading(false)
              setBookingReturningBlockedTickets([])
              setBookingDepartureBlockedTickets([])
              hideModal()
            }).catch((error) => {
              timeDone.current = true
              setLocalLoading(false)
              setBookingReturningBlockedTickets([])
              setBookingDepartureBlockedTickets([])
              hideModal()
            })
          }
        }
      }
    })
    showModal()
    console.log('TimeOut' + bookingTimeoutDeadline)
  }*/

  //Unblock Seat When TimeOut

  useEffect(() => {
    if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === false) {
      console.log('entra navigate effect 1')
      navigate(`${ BASE_URL }/booking/bus`)
    } else if (bookingReturningBlockedTickets.length === 0 && bookingDepartureBlockedTickets.length !== 0 && bookingTripConfig.redondo === true) {
      console.log('entra navigate effect 2') //--------------------------------
      navigate(`${ BASE_URL }/booking/bus`)
    } else if (bookingReturningBlockedTickets.length === 0
      && bookingDepartureBlockedTickets.length === 0
      && timeDone.current === true) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReturningBlockedTickets, bookingDepartureBlockedTickets])

  useEffect(() => {
    let _charge = charge
    if ( canSetDataCharge ) {
      _charge = dataCharge
    }
    if (_charge != null) {
      setPayment(_charge)
      loggerDebug(_charge)
      setLocalLoading(true)
      if (!isConfirming) {
        setIsConfirming(true)

        //  const ticketsToConfirm = tickets.filter((ticket) => {
        //    return ticket.selected
        //  })

        //  const total = bookingDepartureBlockedTickets.reduce((accumulator, object) => {
        //   //const precio = object.selected ? object.precioBoleto : 0
        //   const servicios = object.servicios.reduce((suma, servicio) => {
        //     return suma + parseFloat(servicio.precio)
        //   }, 0)
        //   return accumulator + parseFloat(object.precio) + servicios
        // }, 0)
        const card = {
          autorizacion: _charge.auth,
          folio: _charge.foliocpagos,
          correo: email,
          fecha: formatISO(parse(`${ _charge.date } ${ _charge.time }`, 'dd/LL/yyyy HH:mm:ss', new Date())),
          importe: _charge.amount,
          cliente: _charge.cc_name,
          operacion: _charge.foliocpagos,
          status: _charge.response,
          referencia: _charge.reference,
          tarjeta: _charge.cc_number,
          tipo: _charge.cc_type,
          marca: _charge.cc_type
        }
        console.log('Card: ')
        console.log(card)

        let _tripId = bookingTripConfig.redondo === true ? bookingReturningTripId : bookingDepartureTripId

        transpais.confirm(transpaisIdToken, _tripId, usuario, card, email, bookingDepartureBlockedTickets.map((ticket) => ticket.token))
          .then((response) => {
            console.log(response.data)
            Sentry.setContext('Confirmation', {
              token: transpaisIdToken,
              tripId: _tripId,
              usuario: usuario,
              card: card,
              email: email,
              tickets: bookingDepartureBlockedTickets,
            })
            const tickets = response.data.tickets
            const payment = response.data.payment
            // const tripid = response.data.trip

            setBookingTickets(tickets)
            setBookingPayment(payment)
            // setBookingTripId(tripid)
          })
          .catch((error) => {
            Sentry.addBreadcrumb({
              category: 'ibus',
              message: 'confirm',
              level: 'error',
            })
            Sentry.captureMessage(error)
            setLocalLoading(false)
            setupModal({
              title: 'Atención',
              content: <p>No fue posible confirmar tus boletos.</p>,
              actions: {
                positive: {
                  title: 'Aceptar',
                  onClick: () => {
                    //TODO: Error grande
                    timeDone.current = true
                    setBookingReturningBlockedTickets([])
                    setBookingDepartureBlockedTickets([])
                    hideTimer()
                    hideModal()
                  }
                }
              }
            })
            showModal()
          })
      }
    }
  }, [charge, canSetDataCharge])
  /*


    const confirmReservation = (token, folio, ticketsToConfirm, total, charge, correo, usuario_id, punto_venta_id, estacion_id, retries = 5) => {
      gateway.reservationConfirm(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id)
        .then((response) => {
          const data = response.data
          loggerDebug(data)
          if (data.confirmada === false || data.hayError === true) {
            Sentry.setContext('Confirmation', {
              token,
              folio,
              tickets: ticketsToConfirm,
              total,
              charge,
              correo: tickets[0].correo,
              usuario_id,
              punto_venta_id,
              estacion_id,
              data
            })
            Sentry.addBreadcrumb({
              category: "ibus",
              message: "Reintento confirmar",
              level: "error",
            });
            Sentry.captureMessage("Reintento confirmar en respuesta normal.")
            if (retries >= 0) {
              confirmReservation(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
            } else {
              setupModal({
                title: 'Atención',
                content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
              })
              showModal()
              setLocalLoading(false)
              setIsConfirming(false)
            }
            return
          }
          setConfirmation(data)
        })
        .catch((error) => {
          Sentry.setContext('Confirmation', {
            token,
            folio,
            tickets: ticketsToConfirm,
            total,
            charge,
            correo: tickets[0].correo,
            usuario_id,
            punto_venta_id,
            estacion_id,
            error
          })
          Sentry.addBreadcrumb({
            category: "ibus",
            message: "Reintento confirmar",
            level: "error",
          });
          Sentry.captureException(error)
          if (retries >= 0) {
            confirmReservation(token, folio, ticketsToConfirm, total, charge, tickets[0].correo, usuario_id, punto_venta_id, estacion_id, retries - 1)
          } else {
            setupModal({
              title: 'Atención',
              content: <p>No fue posible realizar la confirmación de tus boletos.</p>,
            })
            showModal()
            setLocalLoading(false)
            setIsConfirming(false)
          }
        })
    }


  */
  useEffect(() => {
    //loggerDebug('Proceso boletos')
    /*let fecha = null
    bookingDepartureBlockedTickets.forEach(ticket => {
      console.log(ticket)
      if (fecha == null) {
        fecha = ticket.fecHorViaje
      } else if (fecha !== ticket.fecHorViaje) {
        redondo.current = true
        const viaje1 = moment(fecha, 'DD/MM/YYYY HH:mm')
        const viaje2 = moment(ticket.fecHorViaje, 'DD/MM/YYYY HH:mm')
        if (viaje1.isBefore(viaje2)) {
          viajeIda.current = viaje1
          viajeRegreso.current = viaje2
        } else {
          viajeIda.current = viaje2
          viajeRegreso.current = viaje1
        }
      }
    })
    if (redondo.current === false && fecha != null) {
      viajeIda.current = moment(fecha, 'DD/MM/YYYY HH:mm')
    }*/
    viajeIda.current = bookingDepartureTrip ? parseISO(bookingDepartureTrip.salida_raw.replace(/-05:/, '-06:')) : null
    viajeRegreso.current = bookingReturningTrip ? parseISO(bookingReturningTrip.salida_raw.replace(/-05:/, '-06:')) : null
    console.log('otro', viajeIda.current, bookingReturningTrip, viajeRegreso.current)
    //setupTimer(bookingTimeoutDeadline, onTimeout)
    //showTimer()
  }, [])
  /*
    useEffect(() => {
      const exists = !!tickets.find((ticket) => {
        return ticket.selected === true
      })

      setCanContinue(exists)

      loggerDebug(tickets)
      const empresaId = tickets[0].itemDatosConexion.empresaId
      Sentry.setTag('company', empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX')
      Sentry.setContext('Reservation', {
        folio: folio,
        company: empresaId === 1 ? 'TUN' : empresaId === 552 ? 'TSL' : 'XXX',
        tickets: tickets.map((ticket) => {
          return ticket
        })
      })
    }, [tickets])

    useEffect(() => {
      //loggerDebug(ticketsArea.current.scrollTop)
      //loggerDebug(ticketsArea.current.offsetHeight)
      //loggerDebug(ticketsArea.current.scrollHeight)
      if (ticketsArea.current.scrollHeight > ticketsArea.current.offsetHeight) {
        setShowScrollBottom(true)
      } else {
        setShowScrollBottom(false)
      }
    }, [ticketsArea])



    useEffect(() => {
      //loggerDebug(ticketsArea.current.scrollTop)
      //loggerDebug(ticketsArea.current.offsetHeight)
      //loggerDebug(ticketsArea.current.scrollHeight)
      if (currentTicketsScroll > 0) {
        setShowScrollTop(true)
      } else {
        setShowScrollTop(false)
      }

      if ((ticketsArea.current.scrollTop + ticketsArea.current.offsetHeight) === ticketsArea.current.scrollHeight) {
        setShowScrollBottom(false)
      } else {
        setShowScrollBottom(true)
      }
    }, [currentTicketsScroll])
  */
  useEffect(() => {
    if (bookingTickets != null && bookingTickets.length !== 0
      && bookingPayment != null && bookingPayment.length !== 0) {
      loggerDebug('Confirmation', bookingTickets)
      loggerDebug('set false')
      setLocalLoading(false)
      navigate(`${ BASE_URL }/booking/confirmed`)
    }
  }, [bookingTickets, bookingPayment])

  useEffect(() => {
    if (bookingReset) {
      navigate(`${ BASE_URL }/`)
    }
  }, [bookingReset])


  // useEffect(() => {
  //   if (search === '') {
  //     setFilteredEmails(completeMail)
  //   } else {
  //     const _items = completeMail.filter(
  //       (el) => el.toLowerCase().includes(search.toLowerCase())
  //     )
  //     setFilteredEmails(_items)
  //   }
  // }, [search])

  const handleScroll = (e) => {
    //etCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  return (
    <>
      <Helmet>
      </Helmet>
      {/*----Page----*/ }
      { <Loader show={ loading || localLoading }/> }
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.timers }>
              <Timer variant={ 'clock' }/>
            </div>
            {/* <div className={ styles.toPay }>
              <FontAwesomeIcon
                icon={ solid('arrow-down') }
                bounce
                className={ styles.pointer }
              />
              <div className={ styles.pill }>
                <p>Selecciona los boletos que quieras pagar</p>
              </div>
            </div> */ }
          </div>
          <div className={ styles.middle }>
            <div className={ styles.details }>
              <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                { bookingDepartureBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ bookingReturningBlockedTickets.length > 0 }
                              regreso={ false }
                              tripData={ {origin: bookingOrigin.label, destination: bookingDestination.label} }
                              printable={ false }/>
                )) }
                { bookingReturningBlockedTickets.map((ticket, i) => (
                  <Ticket key={ i } ticket={ ticket }
                              redondo={ true }
                              regreso={ true }
                              tripData={ {origin: bookingDestination.label, destination: bookingOrigin.label} }
                              printable={ false }/>
                )) }
              </div>
              { showScrollTop && <div className={ styles.scrollTop }>
                <IconButton
                  type="neutral"
                  icon="chevron-up"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: -300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
              { showScrollBottom && <div className={ styles.scrollBottom }>
                <IconButton
                  type="neutral"
                  icon="chevron-down"
                  onClick={ () => {
                    ticketsArea.current.scrollBy({top: 300, behavior: 'smooth'})
                    //setCurrentTicketsScroll(ticketsArea.current.scrollTop)
                  } }
                />
              </div> }
            </div>
            <div className={ styles.resume }>
              <div className={ styles.card }>
                <div className={ styles.cols }>
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Origen</h2>
                    <span className={ styles.value }>{ bookingOrigin.label }</span>
                  </div>
                  { bookingReturningBlockedTickets.length > 0 && <div className={ styles.redondo }>
                    <svg className={ [styles.icon].join(' ') }>
                      <use xlinkHref={ `#refresh` }/>
                    </svg>
                  </div> }
                  <div className={ [styles.data, styles.half].join(' ') }>
                    <h2>Destino</h2>
                    <span className={ styles.value }>{ bookingDestination.label }</span>
                  </div>
                </div>
                <div className={ styles.cols }>
                  <div className={ styles.data }>
                    <h2>Fecha y hora de viaje</h2>
                    { <span
                      className={ styles.value }>{ viajeIda.current !== null && formatInTimeZone(viajeIda.current, 'America/Monterrey', 'dd/MM/yyyy HH:mm') }{ viajeRegreso.current !== null &&
                      ' › ' + formatInTimeZone(viajeRegreso.current, 'America/Monterrey', 'dd/MM/yyyy HH:mm')
                    }</span> }
                  </div>
                </div>
              </div>
              <div className={ styles.important }>
                <p>Tus boletos serán enviados al correo:</p>
                {/* <input type="text" value={ email } placeholder="Escribe tu correo electrónico"
                       onFocus={ () => setShowScreenKeyboard(true) }
                       onChange={ (e) => {
                         setEmail(e.target.value)
                         console.log('change email')
                         if (e.target.value.includes('@') && !showMails) {
                           setShowMails(true)
                         } else if (!e.target.value.includes('@') && showMails) {
                           setShowMails(false)
                         } else if (e.target.value.includes('@') && showMails) {
                           setSearch(e.target.value.split('@')[1])
                         }
                       } }/> */}
                {/* { showMails ? <div className={ styles.list }>
                  { filteredEmails.map((item) => (
                    <div className={ styles.item } onClick={ event => addMail(event, item) }>
                      <p className={ styles.mail }>{ email.split('@')[0] }<span>@{ item }</span></p>
                    </div>
                  )) }
                </div> : null } */}
                <div>
                {/* <InputAutocomplete items={emails} trigger='@' onChange={(value) => setEmail(value)} /> */}
                  <InputAutocomplete onFocus={ (e) =>  setShowScreenKeyboard(e) } 
                    valueInput={ email } 
                    items={completeMail} 
                    trigger='@' 
                    onChange={(value) => setEmail(value)}
                    handleClicked={handleClick}
                  />
                </div>
                
              </div>
              <div className={ [styles.card, styles.total].join(' ') }>
                <div>
                  <h2>Total a pagar</h2>
                  { <span
                    className={ styles.precio }>{ currency([...bookingDepartureBlockedTickets, ...bookingReturningBlockedTickets].reduce((accumulator, object) => {
                    //const precio = object.selected ? object.precioBoleto : 0
                    const servicios = object.servicios.reduce((suma, servicio) => {
                      return suma + parseFloat(servicio.precio)
                    }, 0)

                    return accumulator + parseFloat(object.precio) + servicios
                  }, 0)) }</span> }
                  <p>Podrás realizar tu pago utilizando únicamente tu tarjeta de débito o crédito,
                    visa o
                    mastercard.</p>
                  <div className={ styles.cardsLogos }>
                    <img src={ require('../../assets/images/visa.svg').default } alt="VISA"/>
                    <img src={ require('../../assets/images/mastercard.svg').default }
                         alt="mastercard"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
            <Button onClick={ onBack } type="neutral" left-icon="chevron-left">Volver</Button>
            {/* <Button to={ `${ BASE_URL }/booking/bus` } type="neutral" left-icon="chevron-left">Volver</Button> */ }
          </div>
          <div className={ global.middle }>
            <Button onClick={ () => {
              exit()
            } } type="positive" right-icon="house">Ir a inicio</Button>
          </div>
          <div>
            <Button onClick={ willPay } type="positive" right-icon="chevron-right">Pagar</Button>
          </div>
        </footer>
      </section>

      <div className={ [styles.keyboardWrapper, showScreenKeyboard ? styles.keyboardShown : null].join(' ') }>
        <Keyboard keyboardRef={ r => (keyboard.current = r) }
          //onChange={onKeyboardChange}
                  onKeyPress={ onKeyboardKeyPress }
                  layoutName={ keyboardLayout }
                  layout={ {
                    default: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      '{blank} q w e r t y u i o p',
                      '{lock} a s d f g h j k l \u00f1 {blank}',
                      '{shift} z x c v b n m . - {shift}',
                      '{space} {at} {dotcom}',
                    ],
                    shift: [
                      '{esc} 1 2 3 4 5 6 7 8 9 0 {bksp}',
                      'Q W E R T Y U I O P \u0308',
                      '{lock} A S D F G H J K L \u00d1 {blank}',
                      '{shift} {blank} Z X C V B N M _ {shift}',
                      '{space} {at} {dotcom}',
                    ],
                  } }
                  display={ {
                    '{at}': `<svg class="key-icon"><use xlink:Href="#at"/></svg>`,
                    '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
                    '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
                    '{blank}': ` `,
                    '{space}': ` `,
                    '{lock}': `<svg class="key-icon"><use xlink:Href="#font-case"/></svg>`,
                    '{shift}': `<svg class="key-icon"><use xlink:Href="#up"/></svg>`,
                    '{dotcom}': `.com`,
                  } }
        />
      </div>
    </>
  )
}

export default BookingReviewOrder

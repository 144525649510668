import { atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'

export const onAdmin = atom(false)
export const idleTimerPaused = atom(false)
export const idleDoneTimerPaused = atom(true)

export const accessToken = atomWithStorage('settingsToken', '')
export const claveUsuario = atomWithStorage('settingsClaveUsuario', '')
export const usuarioID = atomWithStorage('settingsUsuarioID', 0)
export const usuarioEmpresa = atomWithStorage('settingsUsuarioEmpresa', 0)
export const puntoVentaID = atomWithStorage('settingsPuntoVentaID', 0)
export const estacionID = atomWithStorage('settingsEstacionID', 0)
export const userFullName = atomWithStorage('settingsUsuarioNombre', '')
export const nombrePuntoVenta = atomWithStorage('settingsPuntoVenta', '')
export const TranspaisIDToken = atomWithStorage('settingsTranspaisIDToken', '')

export const reservationFolioHome = atomWithStorage('reservationFolioHome', '')
export const reservationFolio = atomWithStorage('reservationFolio', '')
export const reservationTickets = atomWithStorage('reservationTickets', [])
export const pinpadPayment = atomWithStorage('pinpadPayment', {})
export const reservationConfirmation = atomWithStorage('reservationConfirmation', {})
export const reservationMessageConfirm = atomWithStorage('reservationMessageConfirm', '')
export const reservationEmail = atomWithStorage('reservationEmail', '')
export const tokenUser = atomWithStorage('tokenUser', '')
export const reservationConfirmed = atomWithStorage('reservationConfirmed', false)
export const sessionCanReserve = atomWithStorage('sessionCanReserve',{})

export const bookingSessionReset = atomWithStorage('bookingSessionReset', false)
export const bookingSessionOrigin = atomWithStorage('bookingSessionOrigin', {})
export const bookingSessionDestination = atomWithStorage('bookingSessionDestination', {})
export const bookingSessionDepartureDate = atomWithStorage('bookingSessionDepartureDate', '')
export const bookingSessionReturningDate = atomWithStorage('bookingSessionReturningDate', '')
export const bookingSessionDepartureTripId = atomWithStorage('bookingSessionDepartureTripId', null)
export const bookingSessionDepartureTrip = atomWithStorage('bookingSessionDepartureTrip', {})
export const bookingSessionDepartureDisponibilidad = atomWithStorage('bookingSessionDepartureDisponibilidad', {})
export const bookingSessionDepartureSelectedTickets = atomWithStorage('bookingSessionDepartureSelectedTickets', [])
export const bookingSessionDepartureBlockedTickets = atomWithStorage('bookingSessionDepartureBlockedTickets', [])
export const bookingSessionTickets = atomWithStorage('bookingSessionTickets', [])
export const bookingSessionPayments = atomWithStorage('bookingSessionPayments', [])
export const bookingSessionTimeoutDeadline = atomWithStorage('bookingSessionTimeoutDeadline', '')
export const bookingSessionTripConfig = atomWithStorage('bookingSessionTripConfig', {})
export const bookingSessionReturningTrip = atomWithStorage('bookingSessionReturningTrip', null)
export const bookingSessionReturningTripId = atomWithStorage('bookingSessionReturningTripId', null)
export const bookingSessionReturningDisponibilidad = atomWithStorage('bookingSessionReturningDisponibilidad', {})
export const bookingSessionReturningBlockedTickets = atomWithStorage('bookingSessionReturningBlockedTickets', [])
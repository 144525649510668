import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Modal, Timer } from '../components/transpais'

const TimerContext = createContext(null)

export function TimerProvider({children}) {
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [show, setShow] = useState(false)
  const [timeoutValue, setTimeoutValue] = useState(null)
  const [onTimeoutValue, setOnTimeoutValue] = useState(null)
  const [waitPay, setWaitPay] = useState(false)
  
  function showTimer() {
    //loggerDebug('isVisible', isVisible())
    if (isVisible()) {
      forceUpdate()
    }
    //loggerDebug('Muestra modal')
    setShow(true)
  }

  function hideTimer() {
    //loggerDebug('Oculta modal')
    setShow(false)
  }

  function isVisible() {
    return show
  }

  function setupTimer(timeout, onTimeout, callback) {
    setTimeoutValue(timeout)
    setOnTimeoutValue(() => onTimeout)
    callback()
  }

  function waitingPayment(waiting) {
    setWaitPay(waiting)
  }

  useEffect(() => {
    console.debug('showtimer',show)
    if (show === false) {
      //forceUpdate()
    }
  }, [show])

  const value = useMemo(() => {
    return {
      isVisible,
      showTimer,
      hideTimer,
      setupTimer,
      waitingPayment
    }
  }, [show])

  return <TimerContext.Provider value={ value }>
    {show && <Timer variant={ 'timer' } active={show} waiting={waitPay} timeout={ timeoutValue } onTimeout={ onTimeoutValue }/> }
    {children}
  </TimerContext.Provider>
}

export function useTimer() {
  const context = useContext(TimerContext)

  if (!context) {
    throw new Error('useTimer debe estar dentro del proveedor TimerContext')
  }

  return context
}

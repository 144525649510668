import React, {forwardRef} from 'react'
import {QRCode} from 'react-qrcode-logo'

import styles from './ReservationPrintableTicket.module.scss'
import {useAtom} from 'jotai'
import {nombrePuntoVenta, TranspaisIDToken} from '../../utilities/atoms'
import {currency} from '../../components/transpais/helpers/currency'
import {getTID} from "../../utilities/token";

const ReservationPrintableTicket = forwardRef(({ticket, payment, confirmation}, ref) => {

    const [transpaisIDToken,] = useAtom(TranspaisIDToken)
    const [nombPuntoVenta,] = useAtom(nombrePuntoVenta)

    const totalPayment = (data) => {
        let total = 0;
        data.reservation.seats.forEach((seat) => {
            total += parseFloat(seat.precio);
        });
        return currency(total)
    }

    return (
        <div ref={ref} className={styles.printable}>
            <div className={styles.datetime}>
                <div className={styles.date}>{payment.date}</div>
                <div className={styles.id}>{nombPuntoVenta}</div>
                <div className={styles.time}>{payment.time}</div>
            </div>
            <header className={styles.header}>
                <h1 className={styles.company}>{ ticket?.empresa_nombre || ticket?.razonsocial } - { ticket.empresa_rfc }</h1>
                <p>{ticket.direccion}</p>
            </header>
            <div className={styles.userData}>
                {/* <div className={[styles.spread, styles.mb10].join(' ')}>
          <div className={styles.data}>Pasajero: <strong className={styles.big}>{ticket.pasajero}</strong></div>
        </div> */}
                <div className={styles.split}>
                    <div className={styles.data}>Origen: <strong>{ticket.origen}</strong></div>
                    <div className={styles.data}>Destino: <strong>{ticket.destino}</strong></div>
                </div>
                {/* <div className={styles.split}>
          <div className={styles.data}>Destino: <strong>{ticket.destino}</strong></div>
        </div> */}
                <div className={styles.split}>
                    <div className={styles.data}>Fecha: <strong>{ticket.fechaSalida.split(' ')[0]}</strong></div>
                    <div className={styles.data}>Hora: <strong>{ticket.fechaSalida.split(' ')[1]}</strong></div>
                </div>
                {/* <div className={styles.split}>
          <div className={styles.data}>Hora: <strong>{ticket.fechaSalida.split(' ')[1]}</strong></div>
          {/*<div className={styles.data}><strong>ID:</strong> {ticket.corridaId}</div>
        </div> */}
                <div className={styles.split}>
                    <div className={styles.data}>PIN: <strong>{getTID(transpaisIDToken)}</strong></div>
                    <div className={styles.data}>Unidad: <strong></strong></div>
                    {/*<div className={styles.data}><strong>Asiento:</strong> {ticket.numAsiento}</div>*/}
                    {/*<div className={styles.data}>{ticket.descClase}</div>*/}
                </div>
                <div className={styles.split}>
                    {/*<div className={styles.data}><strong>Unidad:</strong></div>*/}
                    {/* <div className={styles.data}>Asiento: <strong>{ticket.asiento}</strong></div>
          <div className={[styles.data, styles.center].join(' ')}><span className={styles.important}>{ticket.categoria}</span></div>
          <div className={[styles.data, styles.right].join(' ')}><span className={styles.important}>{ticket.descClase}</span></div> */}
                </div>
            </div>
            <div className={styles.paymentWrapper}>
                <div className={styles.qr}>
                    <QRCode value={confirmation.reservation.folio}
                            size={116}
                            quietZone={5}
                            fgColor={'#000000'}
                            bgColor={'#FFFFFF'}
                        /*qrStyle={'dots'}
                        eyeRadius={[
                          {
                            outer: [30, 30, 0, 30],
                            inner: 2,
                          },
                          {
                            outer: [30, 30, 30, 0],
                            inner: 2,
                          },
                          {
                            outer: [30, 0, 30, 30],
                            inner: 2,
                          },
                        ]}*/
                        //logoImage={require('../../assets/images/icon.png')}
                        //logoWidth="24"
                            removeQrCodeBehindLogo={false}/>
                </div>
                <div className={styles.payment}>
                    <div className={[styles.split, styles.fixed].join(' ')}>
                        <div className={styles.data}><strong>TOTAL:</strong></div>
                        <div className={[styles.data, styles.right].join(' ')}>
                            <strong>{totalPayment(confirmation)}</strong></div>
                    </div>
                    <div className={[styles.split, styles.fixed].join(' ')}>
                        <div className={styles.data}>PAGAR ANTES DE:</div>
                    </div>
                    <div className={[styles.split, styles.fixed].join(' ')}>
                        <div className={[styles.data, styles.right].join(' ')}><strong>Antes
                            del {confirmation.reservation.expires}</strong></div>
                    </div>
                </div>
            </div>
            {/* <div className={[styles.split, styles.folios].join(' ')}>
        <div className={styles.data}><strong>IKEY:</strong> {ticket.ikey}</div>
        <div className={styles.data}><strong>FOLIO:</strong> {ticket.numFolioSistema}</div>
      </div> */}
            <div className={styles.disclaimer}>
                <p>Este es un comprobante electrónico. Favor de presentarlo en los comercios afiliados para efectuar el
                    pago.</p>
                <p>{confirmation.reservation.mensaje}</p>
                <p>Te recordamos que para pasajeros MENORES, ADULTO MAYOR, ESTUDIANTE Y/O MAESTROS al momento de abordar
                    se te solicitará la documentación en la sección "PREGUNTAS FRECUENTES".
                    Al abordar la unidad deberás presentar al operador un identificación oficial vigente (credencial del
                    INE, cartilla militar, pasaporte, cédula profesional o licencia de manejo).</p>
            </div>
            <hr className={styles.cut}/>
            {/* <div className={styles.userData}> */}
            {confirmation.reservation.seats.map((ticket, index) => (
                <div key={index} className={styles.userData}>
                    <div className={styles.spread}>
                        <div className={styles.data}>Pasajero: <strong>{ticket.pasajero}</strong></div>
                    </div>
                    <div className={styles.split}>
                        <div className={styles.data}>TIPO: <strong>{ticket.categoria}</strong></div>
                        <div className={styles.data}>ASIENTO: <strong>{ticket.asiento}</strong></div>
                    </div>
                </div>
            ))}
            {/* <div className={styles.spread}>
          <div className={styles.data}>Pasajero: <strong>{ticket.pasajero}</strong></div>
        </div>
        <div className={styles.split}>
          <div className={styles.data}>TIPO: <strong>{ticket.categoria}</strong></div>
          <div className={styles.data}>ASIENTO: <strong>{ticket.asiento}</strong></div>
        </div> */}
            {/* </div> */}
            {/* <div className={[styles.datetime, styles.last].join(' ')}>
        <div className={styles.date}>{payment.date}</div>
        <div className={styles.id}>{nombPuntoVenta}</div>
        <div className={styles.time}>{payment.time}</div>
      </div> */}
            <hr className={[styles.cut, styles.cutMargin].join(' ')}/>
        </div>
    )
})

export default ReservationPrintableTicket

import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'

import {
  Routes,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

import { BrowserTracing } from '@sentry/tracing'
import './components/transpais/helpers/date'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import App from './App'

import { PinpadProvider } from './contexts/pinpad.context'
import { ModalProvider } from './contexts/modal.context'
import { LoggerProvider } from './contexts/logger.context'
import { ModalViewProvider } from './contexts/modalview.context'
import { TimerProvider } from './contexts/timer.context'

import 'react-simple-keyboard/build/css/index.css'
import './scss/global.scss'

Sentry.init({
  dsn: 'https://4d0cb5c5b30f450f8a68b1e395089732@o306196.ingest.sentry.io/6529996',
  release: `${ process.env.REACT_APP_VERSION } (${ process.env.REACT_APP_BUILD })`,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  }),],
  normalizeDepth: 5,
  tracesSampleRate: 1.0,
})

config.autoAddCss = false

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    {/*----Icons----*/ }
    <FontAwesomeIcon icon={ solid('calendar-clock') } symbol="calendar-clock"/>
    <FontAwesomeIcon icon={ solid('ticket') } symbol="ticket"/>
    <FontAwesomeIcon icon={ solid('chevron-right') } symbol="chevron-right"/>
    <FontAwesomeIcon icon={ solid('chevron-left') } symbol="chevron-left"/>
    <FontAwesomeIcon icon={ solid('chevron-down') } symbol="chevron-down"/>
    <FontAwesomeIcon icon={ solid('chevron-up') } symbol="chevron-up"/>
    <FontAwesomeIcon icon={ solid('trash') } symbol="trash"/>
    <FontAwesomeIcon icon={ solid('user-tie') } symbol="user-tie"/>
    <FontAwesomeIcon icon={ solid('child') } symbol="child"/>
    <FontAwesomeIcon icon={ solid('person-cane') } symbol="person-cane"/>
    <FontAwesomeIcon icon={ solid('user-graduate') } symbol="user-graduate"/>
    <FontAwesomeIcon icon={ solid('chalkboard-user') } symbol="chalkboard-user"/>
    <FontAwesomeIcon icon={ solid('wheelchair') } symbol="wheelchair"/>
    <FontAwesomeIcon icon={ solid('house') } symbol="house"/>
    <FontAwesomeIcon icon={ solid('print') } symbol="print"/>
    <FontAwesomeIcon icon={ solid('check') } symbol="check"/>
    <FontAwesomeIcon icon={ light('keyboard') } symbol="keyboard"/>
    <FontAwesomeIcon icon={ light('keyboard-down') } symbol="keyboard-down"/>
    <FontAwesomeIcon icon={ light('at') } symbol="at"/>
    <FontAwesomeIcon icon={ light('up') } symbol="up"/>
    <FontAwesomeIcon icon={ light('font-case') } symbol="font-case"/>
    <FontAwesomeIcon icon={ light('delete-left') } symbol="delete-left"/>
    <FontAwesomeIcon icon={ solid('refresh') } symbol="refresh"/>
    <FontAwesomeIcon icon={ solid('circle-check') } symbol="circle-check"/>
    <FontAwesomeIcon icon={ solid('circle-dashed') } symbol="circle-dashed"/>
    <FontAwesomeIcon icon={ solid('pen-to-square') } symbol="pen-to-square"/>
    <FontAwesomeIcon icon={ regular('sun-bright') } symbol="sun-bright"/>
    <FontAwesomeIcon icon={ solid('moon') } symbol="moon"/>
    <FontAwesomeIcon icon={ solid('paw') } symbol="paw"/>
    <FontAwesomeIcon icon={ solid('suitcase-rolling') } symbol="suitcase-rolling"/>
    <FontAwesomeIcon icon={ solid('clothes-hanger') } symbol="clothes-hanger"/>
    <FontAwesomeIcon icon={ solid('circle-chevron-left') } symbol="circle-chevron-left"/>
    <FontAwesomeIcon icon={ solid('circle-chevron-right') } symbol="circle-chevron-right"/>
    <FontAwesomeIcon icon={ solid('tv') } symbol="tv"/>
    <FontAwesomeIcon icon={ regular('cart-shopping') } symbol="cart-shopping"/>
    <FontAwesomeIcon icon={ solid('user-tie') } symbol="user-tie"/>
    <FontAwesomeIcon icon={ solid('person-cane') } symbol="person-cane"/>
    <FontAwesomeIcon icon={ solid('child-reaching') } symbol="child-reaching"/>
    <FontAwesomeIcon icon={ solid('graduation-cap') } symbol="graduation-cap"/>
    <FontAwesomeIcon icon={ solid('chalkboard-user') } symbol="chalkboard-userp"/>
    <FontAwesomeIcon icon={ solid('rotate-right') } symbol="rotate-right"/>
    <FontAwesomeIcon icon={ solid('briefcase-medical') } symbol="briefcase-medical"/>
    <FontAwesomeIcon icon={ solid('taxi') } symbol="taxi"/>
    <FontAwesomeIcon icon={ solid('xmark') } symbol="xmark"/>
    <FontAwesomeIcon icon={ solid('circle-xmark') } symbol="circle-xmark"/>
    <FontAwesomeIcon icon={ regular('stopwatch') } symbol="stopwatch"/>
    <FontAwesomeIcon icon={ solid('location-dot') } symbol="location-dot"/>
    <FontAwesomeIcon icon={ solid('print') } symbol="print"/>
    <FontAwesomeIcon icon={ solid('wheelchair') } symbol="wheelchair"/>
    {/* <FontAwesomeIcon icon="fa-regular fa-stopwatch" /> */}
    {/* <FontAwesomeIcon icon="fa-solid fa-tv" /> */ }
    {/* <FontAwesomeIcon icon="fa-solid fa-circle-chevron-left" /> */ }
    {/* <FontAwesomeIcon icon="fa-solid fa-suitcase-rolling" /> */ }
    {/* <FontAwesomeIcon icon="fa-solid fa-paw" /> */ }
    {/* <FontAwesomeIcon icon="fa-regular fa-sun-bright" /> */ }
    {/* <FontAwesomeIcon icon={ solid('spinner') } symbol="spinner"/> */ }

    <LoggerProvider>
      <ModalProvider>
        <ModalViewProvider>
          <TimerProvider>
            <PinpadProvider>
              <BrowserRouter>
                <App/>
              </BrowserRouter>
            </PinpadProvider>
          </TimerProvider>
        </ModalViewProvider>
      </ModalProvider>
    </LoggerProvider>
  </React.StrictMode>
)

//reportWebVitals(console.debug)

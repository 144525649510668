import React from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import './TranspaisScreenSaver.scss'

const TranspaisScreenSaver = ({show}) => {

    /*useEffect(() => {
      if (show) {
          const el = document.getElementById('backgroundvid')
          loggerDebug('play', el)
          if (el) {
            el.play()
          }
      } else {
          const el = document.getElementById('backgroundvid')
          loggerDebug('pause', el)
          if (el) {
              el.pause()
          }
      }
    }, [show])*/

    return (
        <TransitionGroup>
            {show && (
                <CSSTransition classNames="loader" timeout={300}>
                    <div className={['screen-saver', show ? 'screen-saver-show' : null].join(' ')}>
                        <video id="backgroundvid" playsInline autoPlay loop={true} muted>
                            <source src="https://transpais-kiosk.s3.amazonaws.com/screensaver/editar_boleto.mp4" type="video/mp4"/>
                            <p>Fallback content to cover incompatibility issues</p>
                        </video>
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>)
}

export default TranspaisScreenSaver

import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAtom } from 'jotai'
import { useKey } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../utilities/constants'
import { Ray, ray } from 'node-ray/web'

import {
  reservationConfirmation,
  reservationFolio,
  reservationFolioHome,
  pinpadPayment,
  reservationTickets,
  puntoVentaID,
  estacionID,
  accessToken,
  onAdmin,
  bookingSessionOrigin,
  bookingSessionDestination,
  bookingSessionDepartureDate,
  bookingSessionReturningDate,
  bookingSessionDepartureTrip,
  bookingSessionDepartureDisponibilidad,
  bookingSessionDepartureSelectedTickets,
  bookingSessionDepartureBlockedTickets,
  bookingSessionTickets,
  bookingSessionPayments,
  bookingSessionReturningBlockedTickets,
  bookingSessionReturningTrip,
  bookingSessionDepartureTripId,
  bookingSessionReturningTripId,
  bookingSessionReset,
  sessionCanReserve
} from '../../utilities/atoms'

import { usePinpad } from '../../contexts/pinpad.context'
import { Loader, Departure, Timer } from '../../components/transpais'
import { useModal } from '../../contexts/modal.context'
import { useLogger } from '../../contexts/logger.context'

import { BusBrand } from '../../components/transpais/enums'

import gateway from '../../services/gateway'
//const ToPrint = React.forwardRef((props, ref) => (
//    <div ref={ref}><h1>Transpais</h1></div>
//))
import '../../scss/Layout.scss'
import styles from './Dashboard.module.scss'

import Footer from './components/Footer'
import Module from './components/Module'
import { useTimer } from '../../contexts/timer.context'

const Dashboard = () => {

  const navigate = useNavigate()
  //let componentRef = useRef()
  Sentry.configureScope(scope => scope.clear())
  // Todos los atoms para reset
  const [isOnAdmin, setIsOnAdmin] = useAtom(onAdmin)

  const [token,] = useAtom(accessToken)
  const [punto_venta_id,] = useAtom(puntoVentaID)
  const [estacion_id,] = useAtom(estacionID)

  const [, setFolio] = useAtom(reservationFolio)
  //const [, setPrefolio] = useAtom(reservationFolioHome)
  const [, setTickets] = useAtom(reservationTickets)
  const [, setPayment] = useAtom(pinpadPayment)
  const [, setConfirmation] = useAtom(reservationConfirmation)

  const [, setBookingReset] = useAtom(bookingSessionReset)
  // const [, setBookingTripId] = useAtom(bookingSessionTripId)
  const [, setBookingOrigin] = useAtom(bookingSessionOrigin)
  const [, setBookingDestination] = useAtom(bookingSessionDestination)
  const [, setBookingDepartureDate] = useAtom(bookingSessionDepartureDate)
  const [, setBookingReturningDate] = useAtom(bookingSessionReturningDate)
  const [, setBookingDepartureTrip] = useAtom(bookingSessionDepartureTrip)
  const [, setBookingDepartureDisponibilidad] = useAtom(bookingSessionDepartureDisponibilidad)
  const [, setBookingDepartureSelectedTickets] = useAtom(bookingSessionDepartureSelectedTickets)
  const [, setBookingDepartureBlockedTickets] = useAtom(bookingSessionDepartureBlockedTickets)
  const [, setBookingDepartureTripId] = useAtom(bookingSessionDepartureTripId)
  const [, setBookingReturningTrip] = useAtom(bookingSessionReturningTrip)
  const [, setBookingReturningTripId] = useAtom(bookingSessionReturningTripId)
  //const [, setBookingReturningBus] = useAtom(bookingSessionReturningBus)
  //const [, setBookingReturningDisponibilidad] = useAtom(bookingSessionReturningDisponibilidad)
  //const [, setBookingReturningSelectedTickets] = useAtom(bookingSessionReturningSelectedTickets)
  const [, setBookingReturningBlockedTickets] = useAtom(bookingSessionReturningBlockedTickets)
  const [, setBookingTickets] = useAtom(bookingSessionTickets)
  const [, setBookingPayment] = useAtom(bookingSessionPayments)
  const [, setSessionCanReserve] = useAtom(sessionCanReserve)

  const barcodeInputValue = useRef('')
  const [localLoading, setLocalLoading] = useState(false)
  const {showModal, setupModal} = useModal()
  const {hideTimer} = useTimer()

  const {loading, reset, changeCompany} = usePinpad()
  const {loggerDebug, loggerError} = useLogger()

  useEffect(() => {
    //Reset contexts
    Sentry.configureScope(scope => scope.clear())
    // Reset reservation
    setFolio('')
    //setPrefolio('')
    setTickets([])
    setPayment({})
    setConfirmation({})
    reset()
    // Reset booking
    // setBookingTripId(null)
    setBookingDepartureTripId(null)
    setBookingReturningTripId(null)
    setBookingOrigin({})
    setBookingDestination({})
    setBookingDepartureDate('')
    setBookingReturningDate('')

    setBookingDepartureTrip(null)
    setBookingDepartureDisponibilidad({})
    setBookingDepartureSelectedTickets([])
    setBookingDepartureBlockedTickets([])

    setBookingReturningTrip(null)
    //setBookingReturningBus([])
    //setBookingReturningDisponibilidad({})
    //setBookingReturningSelectedTickets([])
    setBookingReturningBlockedTickets([])
    setSessionCanReserve({})

    setBookingTickets([])
    setBookingPayment([])

    setBookingReset(false)
    ray({ type: 'text', replace: true });
    hideTimer()
  }, [])

  const readCode = (event) => {
    if (!isOnAdmin) {
      const key = event.key
      //loggerDebug('length', barcodeInputValue.length)
      setCodeKey(key)
    }
  }
  // useRay('dev dev')

  const setCodeKey = (key) => {
    if (key === 'Enter') {
      //if (barcodeInputValue.current.length >= RESERVATION_CODE_MIN_SIZE && barcodeInputValue.current.length <= RESERVATION_CODE_MAX_SIZE)
      //setPrefolio(barcodeInputValue.current)
      //navigate(`${ BASE_URL }/reservations/review`)
      search()
      return
    }

    barcodeInputValue.current = barcodeInputValue.current + key

    if (barcodeInputValue.current.length > 12) {
      search()
    }
  }

  const predicate = (event) => (event.key >= '0' && event.key <= '9') || event.key === 'Enter'
  useKey(predicate, readCode, {event: 'keyup'})

  const search = () => {
    setLocalLoading(true)
    loggerDebug('search', barcodeInputValue)
    Sentry.setContext('Search', {
      folio: barcodeInputValue.current,
    })
    gateway
      .reservationSearch(token, barcodeInputValue.current, punto_venta_id, estacion_id)
      .then((response) => {
        setLocalLoading(false)
        loggerDebug(response.data)
        const info = response.data.header
        if (info.error) {
          //hideLoader()

          setupModal({
            title: 'Atención',
            content: <p>No es posible localizar esta reservación, valida tus datos e inténtalo de
              nuevo.</p>,
          })
          showModal()
          barcodeInputValue.current = ''
        } else {
          const data = response.data.content

          loggerDebug(info)
          loggerDebug(data)
          if (data.length > 0) {
            let empresa = data[0].itemDatosConexion
            const selectedData = data.map((ticket) => {
              ticket.selected = true
              return ticket
            })
            changeCompany(empresa)
            setTickets(selectedData)
            setFolio(barcodeInputValue.current)
            navigate(`${ BASE_URL }/reservations/review`)
          } else {
            barcodeInputValue.current = ''
          }
        }
      })
      .catch((error) => {
        loggerError(error)
        Sentry.captureException(error)
        setLocalLoading(false)
        setupModal({
          title: 'Atención',
          content: <p>No es posible localizar esta reservación, valida tus datos e inténtalo de
            nuevo.</p>,
        })
        showModal()
        barcodeInputValue.current = ''
      })
  }

  const _tripData = {
    brand: BusBrand.ibus,
    salida: '7:00',
    salidaAmPm: 'AM',
    llegada: '10:00',
    llegadaAmPm: 'AM',
    precio: 299.00,
    asientos: '44',
    duracion: '4h 10m'
  }

  return (
    <>
      <Loader show={ loading || localLoading }/>
      {/*----Page----*/ }
      <section className={ [styles.sectionDashboard, 'section-full'].join(' ') }>
        <div className={ [styles.sectionDashboardContent, 'section-flex'].join(' ') }>
          <div className={ [styles.logo, 'logo'].join(' ') }>
            <figure className="logo-image logo-image-transpais-dark"/>
            <div className={styles.clock}>
              <Timer variant={ 'clock' } blur={true}/>
            </div>
          </div>
          <div className={ styles.modules }>
            <div className={ styles.modulesContent }>
              <Module label={ 'Compra tus boletos' } icon={ 'ticket' }
                      route={ `${ BASE_URL }/booking/search` } disabled={ false }/>
              <Module label={ 'Paga tu reserva' } icon={ 'calendar-clock' }
                      route={ `${ BASE_URL }/reservations/lookup` }/>
              <Module label={ 'Imprimir boletos' } icon={ 'print' }
                      route={ `${ BASE_URL }/reprints/search` }/>
              {/* <Module label={ 'Reservar viaje' } icon={ 'ticket' }
                      route={ `${ BASE_URL }/reservationTrips/search` }/> */}
            </div>
            {/*<div className={ styles.content }>
              <div className={ styles.departures }>
                <p>Monterrey { '>' } Cd. Victoria</p>
                <Departure variant={ DepartureViewVariant.quick } data={ _tripData }/>
                <p className={ styles.corridastxt }>Ver todas las corridas</p>
              </div>
              <div className={ styles.departures }>
                <p>Monterrey { '>' } Cd. Victoria</p>
                <Departure variant={ DepartureViewVariant.quick } data={ _tripData }/>
                <p className={ styles.corridastxt }>Ver todas las corridas</p>
              </div>
              <div className={ styles.departures }>
                <p>Monterrey { '>' } Cd. Victoria</p>
                <Departure variant={ DepartureViewVariant.quick } data={ _tripData }/>
                <p className={ styles.corridastxt }>Ver todas las corridas</p>
              </div>
              <div className={ styles.departures }>
                <p>Monterrey { '>' } Cd. Victoria</p>
                <Departure variant={ DepartureViewVariant.quick } data={ _tripData }/>
                <p className={ styles.corridastxt }>Ver todas las corridas</p>
              </div>
            </div>
*/}
          </div>
          <Footer/>
        </div>
        <div className={ styles.imageWrapper }>
          <figure className={ [styles.image, styles.imageDashboardBusBackground].join(' ') }/>
        </div>
      </section>
    </>
  )
}

export default Dashboard

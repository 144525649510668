import React, { createContext, useContext, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'

const LoggerContext = createContext(null)

export function LoggerProvider(props) {

  function init() {

    loggerInfo('\n  11111111111111 1111111111111     111111111     1111111111     1111111111   1111111111111     111111111      111    111111111 \n' +
      '     11111      1111       1111          11111 1111    11111 1111          1111       11111          11111   11  1111          \n' +
      '    1111       11111111111111  11111111111111 1111     1111 111111111111  111111111111111  11111111111111  1111  11111111111   \n' +
      '   1111      1111     1111   1111      1111  111      111           1111 1111            1111      1111  1111           1111   \n' +
      '  111       111        1111  1111111111111 1111     1111 111111111111   111              1111111111111  1111  111111111111     ')

    //if (typeof window.chrome !== 'undefined') {
    //  connect()
    //}
  }

  /*function connect() {
    window.chrome.runtime.sendMessage('iidgdklihnieaelgphldnhdaneoijdgi', {
      level: 'info',
      message: 'connect'
    }, function (response) {
      loggerDebug(response)
    })
  }*/

  function loggerDebug() {
    const today = new Date()
    const stamp = '[' + today.toLocaleString() + '] '
    for (let i = 0; i < arguments.length; i++) {
      console.debug(stamp, arguments[i])
      sendLog('DEBUG', stamp, arguments[i])
    }
  }

  function loggerInfo() {
    const today = new Date()
    const stamp = '[' + today.toLocaleString() + '] '
    for (let i = 0; i < arguments.length; i++) {
      console.info(stamp, arguments[i])
      sendLog('INFO', stamp, arguments[i])
    }
  }

  function loggerError() {
    const today = new Date()
    const stamp = '[' + today.toLocaleString() + '] '
    for (let i = 0; i < arguments.length; i++) {
      console.error(stamp, arguments[i])
      sendLog('ERROR', stamp, arguments[i])
    }
  }

  function loggerWarn() {
    const today = new Date()
    const stamp = '[' + today.toLocaleString() + '] '
    for (let i = 0; i < arguments.length; i++) {
      console.warn(stamp, arguments[i])
      sendLog('WARN', stamp, arguments[i])
    }
  }

  function loggerLog() {
    const today = new Date()
    const stamp = '[' + today.toLocaleString() + '] '
    for (let i = 0; i < arguments.length; i++) {
      console.log(stamp, arguments[i])
      sendLog('LOG', stamp, arguments[i])
    }
  }

  function sendLog(level, stamp, msg) {
    const _level = '[' + level.padEnd(5) + '] '
    const log = stamp + _level + msg
    //console.log(log)
    if (['WARN', 'ERROR'].includes(level)) {
      Sentry.captureMessage(msg)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const value = useMemo(() => {
    return {
      loggerDebug,
      loggerInfo,
      loggerError,
      loggerLog,
      loggerWarn,
    }
  }, [])

  return <LoggerContext.Provider value={ value } { ...props }/>
}

export function useLogger() {
  const context = useContext(LoggerContext)

  if (!context) {
    throw new Error('useLogger debe estar dentro del proveedor LoggerContext')
  }

  return context
}

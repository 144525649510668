import React from 'react'

import styles from './TranspaisActionFormField.module.scss'

const TranspaisActionFormField = ({label, value, brief, disabled, onClick}) => {
  return <div className={ [styles.actionFormField, disabled && styles.disabled].join(' ') } onClick={onClick}>
    {brief && <div className={ styles.actionFormFieldValue }>
      <h2>{ brief ?? '-' }</h2>
    </div>}
    <div className={ styles.actionFormFieldLabel }>
      <h2>{ label }</h2>
      <p>{ value ?? '-'}</p>
    </div>
    <div className={ styles.actionFormFieldIcon }>
      <svg className={ styles.icon }>
        <use xlinkHref="#pen-to-square"/>
      </svg>
    </div>
  </div>
}

export default TranspaisActionFormField
import React, { createRef, useEffect } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import FadingBalls from 'react-cssfx-loading/lib/FadingBalls'
import { RELOAD_TOUCH_COUNT } from '../../utilities/constants'

import { useModal } from '../../contexts/modal.context'

import './TranspaisLoader.scss'
import styles from '../../modules/reservations/ReservationLookup.module.scss'
import Keyboard from 'react-simple-keyboard'
import { useAtom } from 'jotai'
import { onAdmin } from '../../utilities/atoms'
import { useLogger } from '../../contexts/logger.context'

const TranspaisLoader = (props) => {
  const adminPass = createRef()

  const {loggerDebug} = useLogger()
  const {showModal, hideModal, setupModal} = useModal()

  const closeBrowser = () => {
    setupModal({
      title: 'Transpais',
      content: <><p>Introduce la contraseña de administrador.
        <br/>
        <input className={ 'passInput' } type={ 'password' }
               ref={ adminPass } /><div
          className={[styles.keyboardWrapper].join(' ')}>
          <Keyboard
            onChange={(input) => {
              loggerDebug('Input changed', input)
            }}
            onKeyPress={(button) => {
              loggerDebug('Button pressed', button)
              if (button === '{esc}') {
                //setShowScreenKeyboard(false)
              } else if (button === '{bksp}') {
                //setBarcodeInputValue(barcodeInputValue.slice(0, -1))
                adminPass.current.value = adminPass.current.value.slice(0, -1)
              } else {
                //setCodeKey(button)
                adminPass.current.value += button
              }
            }}
            layout={{
              default: [
                '1 2 3', '4 5 6', '7 8 9', ' 0 {bksp}',
              ]
            }}
            display={{
              '{bksp}': `<svg class="key-icon"><use xlink:Href="#delete-left"/></svg>`,
              '{esc}': `<svg class="key-icon"><use xlink:Href="#keyboard-down"/></svg>`,
            }}
          />
        </div>
      </p>
      </>,
      actions: {
        positive: {
          title: 'Salir',
          onClick: () => {
            loggerDebug(adminPass.current.value)
            if (adminPass.current.value === process.env.REACT_APP_ADMIN_PASS) {
              window.location = 'kioskexit1337'
            } else {
              hideModal()
            }
          }
        },
        negative: {
          title: 'Cancelar',
          onClick: () => {
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  return (
    <TransitionGroup>
      {props.show && (
        <CSSTransition classNames="loader" timeout={300}>
          <div className={['loader', props.show ? 'loader-show' : null].join(' ')}>
            <div className="loader-wrapper">
              <img onClick={(e) => {
                if (e.detail === RELOAD_TOUCH_COUNT) {
                  closeBrowser()
                }
              }} src={require('../../assets/images/icon.svg').default} alt="Transpais"/>
              <FadingBalls color="#003366"/>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>)
}

export default TranspaisLoader

import React from 'react'

import styles from './TranspaisModalView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useModalView } from '../../contexts/modalview.context'

const TranspaisModalView = ({show, title, cancelable, children}) => {

  const {destroyModalView} = useModalView()
  const dismiss = () => {
    destroyModalView()
  }

  return (
    <div className={ [styles.modalView, !show && styles.hidden].join(' ') }>
      <div className={ styles.modalViewContainer }>
        <div className={ styles.modalViewHeader }>
          <h1>{ title }</h1>
          { cancelable && (
            <div className={ styles.close } onClick={ dismiss }>
              <FontAwesomeIcon icon={ solid('xmark') } size={'2xl'}/>
            </div>
          ) }
        </div>
        { children }
      </div>
    </div>
  )
}

export default TranspaisModalView
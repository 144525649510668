import axios from 'axios'
import iBus from '../config/ibus'

const urlBase = process.env.REACT_APP_TRANSPAISID
const appToken = process.env.REACT_APP_TRANSPAISID_TOKEN

/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `${urlBase}/${url}`

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  },
  timeout: 10000
})

const get = (url = '', headers = {}) => axios.get(readUrl(url), {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  },
  timeout: 10000
})

const signupGuest = (email, confirmation) => {
  const body = {
    email: email,
    email_confirmation: confirmation
  }

  return post('v2/signup-guest', body, {
    Authorization: `Bearer ${appToken}`
  })
}

const signIn = (email, confirmation) => {
  const body = {
    email: email,
    password: confirmation
  }

  return post('v2/signin', body, {
    Authorization: `Bearer ${appToken}`
  })
}

const transpaisid = {
  signupGuest,
  signIn
}

export default transpaisid

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const TranspaisTicketService = {
  icon: (name) => {
    //console.log('nombre', name)
    if (name.toLowerCase().includes("asistencia")) {
      return solid('briefcase-medical')
    } else if (name.toLowerCase().includes("reembolso")) {
      return solid('arrow-rotate-right')
    } else if (name.toLowerCase().includes("taxi")) {
      return solid('taxi')
    }
    return null
  },
}

export default TranspaisTicketService
import styled from 'styled-components'
import {rem} from '../../styles/functions.styled'
import {Settings} from '../../styles/settings.styled'
import {Colors} from '../../styles/colors.styled'
import {FontWeight} from '../../styles/typography.styled'
import BackgroundImageSrc from '../../assets/images/quiosco-bg-01.jpg'

export const Page = styled.section`
`

export const Background = styled.div`
  display: flex;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
`

export const BackgroundImage = styled.figure`
  background-image: url(${BackgroundImageSrc});
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  margin: 0;
  padding: 0;
`

export const Card = styled.div`
  background-color: rgba(0, 51, 102, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  //display: flex;
  //gap: 30px;
  text-align: center;
  //margin-bottom: 50px;
  padding: 30px;
  
  h1 {
    //background-color: ${Colors.primary};
    color: white;
    font-size: ${rem(48)};
    margin: 0 0 20px 0;
    padding: 5px 20px;
  }
`

export const Content = styled.div`
  height: 100vh;
  justify-content: center;
`

export const FieldGroup = styled.div`
  // align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  
  label {
    //background-color: ${Colors.primary};
    color: white;
    display: inline-block;
    font-size: ${rem(34)};
    font-weight: ${FontWeight.medium};
    margin-bottom: 10px;
    padding: 5px 20px;
  }
  
  input {
    border: none;
    border-radius: ${Settings.border_radius}px;
    font-size: ${rem(34)};
    font-weight: ${FontWeight.medium};
    padding: 26px;
    text-align: center;
    width: auto;
    
    &:focus {
      outline: none;
    }
  }
`

export const FieldGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

export const LoginForm = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`

export const Top = styled.div`
  text-align: center;
`
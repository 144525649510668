import axios from 'axios'
import iBus from '../config/ibus'

const urlBase = process.env.REACT_APP_WEBSERVICE

/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = '') =>
    url.startsWith('http://') || url.startsWith('https://') ? url : `${urlBase}/${url}`

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    },
    timeout: 10000
})

const login = (mac, user, password) => post('login', {
    'mac': mac,
    'cveusuario': user,
    'desccontrasena': password,
})

const reservationSearch = (token, folio, punto_venta_id, estacion_id) => post('reservation-search', {
    'numReservacion': folio,
    'puntoVentaId': punto_venta_id,
    'estacionId': estacion_id
}, {
    Authorization: token
})

const reservationConfirm = (token, folio, tickets, amount, payment, email, usuario_id, punto_venta_id, estacion_id) => {
    const body = {
        'boletos': tickets.map(ticket => {
            return {
                'boletoId': ticket.boletoId,
                'cargoPorServicios': 0,
                'correo': ticket.correo,
                'precio': ticket.precioBoleto,
            }
        }),
        'estacionId': estacion_id,
        'numReservacion': folio,
        'pago': [ //llenamos con respuesta MIT
            {
                'clienteId': null, // cambiara por TID con email
                'formaPago': payment.cc_type.includes('CREDITO') ? 'TARJETA' : 'T. DEBITO',
                'itemTarjeta': {
                    'aid': payment.appid,
                    'arqc': payment.arqc,
                    'autorizacion': payment.auth,
                    'banco': null,
                    'complemento': null,
                    'correo': email,
                    'direccion': payment.nb_street,
                    'empresa': payment.nb_company,
                    'fechaOperacion': `${payment.date} ${payment.time}`,
                    'importe': payment.amount,
                    'nombre': payment.cc_name,
                    'numOperacion': payment.foliocpagos,
                    'numTarjeta': payment.cc_number,
                    'referencia': payment.reference,
                    'status': payment.response,
                    'sucursal': null,
                    'tipoTarjeta': payment.cc_type.includes('CREDITO') ? 2 : 3,
                    'usuarioBancario': null,
                    'vigencia': `${payment.cc_expmonth}/${payment.cc_expyear}`,
                },
                'valorFormaPago': amount,
            },
        ],
        'puntoVentaId': punto_venta_id,
        'totalPagar': amount,
        'usuarioId': usuario_id,
        'tipoVentaId': iBus.tipoVentaId
    }
    console.debug('reservation-confirmation')
    console.debug(body)
    return post('reservation-confirmation', body, {
        Authorization: token
    })
}

const Gateway = {
    login,
    reservationSearch,
    reservationConfirm,
}

export default Gateway

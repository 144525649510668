import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { isEmpty } from 'lodash'

import { Button, IconButton, Loader } from '../../components/transpais'
import Ticket from '../../components/transpais/TranspaisTicket'
import { BASE_URL } from '../../utilities/constants'
import jwt_decode from "jwt-decode"

import {
  accessToken,
  estacionID,
  puntoVentaID,
  reservationConfirmation,
  reservationFolio,
  pinpadPayment,
  reservationTickets,
  usuarioID,
  bookingSessionTickets,
  bookingSessionPayments,
  idleDoneTimerPaused,
  reservationConfirmed,
  TranspaisIDToken

} from '../../utilities/atoms'
import { useModal } from '../../contexts/modal.context'
import { usePinpad } from '../../contexts/pinpad.context'
import { useTimer } from '../../contexts/timer.context'
import ReservationTicket from './ReservationTicket'

import gateway from '../../services/gateway'

import '../../scss/Layout.scss'
import global from '../booking/BookingGlobal.module.scss'
import styles from './BookingConfirm.module.scss'
import { Helmet } from 'react-helmet'
import { genericErrorMessage } from '../../utilities/alerts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLogger } from '../../contexts/logger.context'
import { Breadcrumbs } from '@sentry/react'
import { currency } from '../../components/transpais/helpers/currency'
// import SaleTicket from './SaleTicket'

const BookingConfirm = () => {

  const {loggerDebug} = useLogger()
  const navigate = useNavigate()

  const printAll = useRef(false)
  const ticketsArea = useRef(null)
  const selectedTicket = useRef(null)
  const ticketsRefs = useRef([])

  //const [modalType, setModalType] = useState(null)
  //const [modalPositiveTitle, setModalPositiveTitle] = useState('Si')
  //const [modalNegativeTitle, setModalNegativeTitle] = useState('No')
  //const [modalContent, setModalContent] = useState(null)
  //const [selectedTicket, setSelectedTicket] = useState(null)
  const [idleDonePaused, setIdleDonePaused] = useAtom(idleDoneTimerPaused)
  const [transpaisIdToken,] = useAtom(TranspaisIDToken)

  const [wasPrinted, setWasPrinted] = useState(false)

  const [currentTicketsScroll, setCurrentTicketsScroll] = useState(0)
  const [showScrollBottom, setShowScrollBottom] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)

  // Todos los atoms para reset
  const [bookingTickets, setBookingTickets] = useAtom(bookingSessionTickets)
  const [bookingPayments, setBookingPayments] = useAtom(bookingSessionPayments)
  const [payment, setPayment] = useAtom(pinpadPayment)
  const {showTimer, hideTimer, setupTimer} = useTimer()
  const [confirmation, setConfirmation] = useAtom(reservationConfirmed)
  const [totalPayment, setTotalPayment] = useState(0.00)

  const {reset} = usePinpad()
  const {showModal, hideModal, setupModal} = useModal()

  const exit = () => {
    setPayment({})
    setConfirmation({})
    reset()

    navigate(`${ BASE_URL }/`)
  }

  const askPrintAll = () => {
    setupModal({
      title: 'Imprimir',
      content: <p>Estás a punto de imprimir tu comprobante de reserva, ¿deseas continuar
        con la impresión?</p>,
      actions: {
        positive: {
          title: 'Si',
          onClick: () => {

            // printAll.current = true
            selectedTicket.current = 0
            ticketsRefs.current[selectedTicket.current].printTicket()
            hideModal()
          }
        },
        negative: {
          title: 'No',
          onClick: () => {
            //selectedTicket.current = null
            hideModal()
          }
        }
      }
    })
    showModal()
  }

  const askExit = () => {
    setupModal({
      title: 'Atención',
      content: <p>No imprimiste tu reserva, puedes verla en tu app Transpais, ¿deseas volver al inicio?</p>,
      styles: {
        buttonType: 'small',
      },
      actions: {
        positive: {
          title: 'Quedarme a imprimirla',
          onClick: () => {
            hideModal()
          }
        },
        negative: {
          title: 'Volver al inicio sin imprimirla',
          onClick: () => {
            hideModal()
            exit()
          }
        }
      }
    })
    showModal()
  }

  const printDone = () => {
    loggerDebug('Printing completed...', printAll, selectedTicket.current, ticketsRefs.current.length)
    if (printAll.current && selectedTicket.current < (ticketsRefs.current.length - 1)) {
      selectedTicket.current++
      ticketsRefs.current[selectedTicket.current].printTicket()
    } else {
      selectedTicket.current = 0
      printAll.current = false
      setWasPrinted(true)
    }
  }

  const handleScroll = (e) => {
    setCurrentTicketsScroll(ticketsArea.current.scrollTop)
  }

  const processString = (message) => {
    const first = message.substring(0, message.indexOf("pago de tu reserva"));
    const second = message.substring(message.indexOf("pago de tu reserva"), message.indexOf("o tus"));
    const third = message.substring(message.indexOf("o tus"));
  
    return <p className={ styles.disclaimer }>{ first }<strong>{second}</strong>{third}</p>
    // return [primerParte, segundoParte, tercerParte];
  }

  useEffect(() => {
    selectedTicket.current = 0
    ticketsRefs.current[selectedTicket.current].printTicket()
  }, [])

  useEffect(() => {
    console.log('confirmation info')
    console.log(confirmation)
    console.log('token info: ')
    const decodedToken = jwt_decode(transpaisIdToken)
    console.log(decodedToken)
    if (!isEmpty(confirmation)) {
      let addPinOnConfirmation = confirmation
      addPinOnConfirmation.pin = decodedToken.sub
      setConfirmation(addPinOnConfirmation)
      let total = 0;
      addPinOnConfirmation.reservation.seats.forEach((seat) => {
        total += parseFloat(seat.precio);
      });
      setTotalPayment(total)
    }
  }, [confirmation])

  useEffect(() => {
    setIdleDonePaused(false)
    hideTimer()
  }, [])

  return (
    <>
      <Helmet>
      </Helmet>
      <section className={ ['section-full', 'section-flex'].join(' ') }>
        <div className={ [global.top, styles.top].join(' ') }>
          <div className={ ['logo', 'small', styles.logo].join(' ') }>
            <figure className={ ['logo-image', 'logo-image-transpais-light'].join(' ') }/>
            <div className={ styles.thanks }>
              <h1>¡GRACIAS!</h1>
              <p>Imprime cada uno de tus boletos.</p>
            </div>
          </div>
          <div className={ styles.middle }>
            {/* Container tickets */}
            <div className={ styles.details }>
                
              {/* <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }> */}
                { confirmation.reservation.seats.map((ticket, i) => (
                  <div className={ styles.ticketContainer }>
                    <div className={ styles.tickets } ref={ ticketsArea } onScroll={ handleScroll }>
                      <ReservationTicket key={ i } ref={ (element) => {
                        ticketsRefs.current[i] = element
                      } } ticket={ ticket } payment={ payment } confirmation={ confirmation }
                                        printable={ true } ask={ () => {
                        selectedTicket.current = i
                        // askPrint()
                      } } done={ printDone }/>
                    </div>
                    <div className={ styles.printButton } onClick={ () => {
                      ticketsRefs.current[i].printTicket()
                      selectedTicket.current = null
                    } }>
                      <svg className={ [styles.icon,].join(' ') }>
                        <use xlinkHref={ `#${ 'print' }` }/>
                      </svg>
                      <span>Imprimir</span>
                    </div>
                  </div>
                )) }
              {/* </div> */}
            </div>
            {/* Container payment */}
            <div className={ styles.resume }>
              <div className={ [styles.receipt].join(' ') }>
                <h1>Tu pago</h1>
                <div className={ styles.group }>
                  <p>Fecha de expiración</p>
                  <span className={ styles.data }>{ confirmation.reservation.expires }</span>
                </div>
                <div className={ styles.group }>
                  <p>Referencia</p>
                  <span className={ styles.data }>{ confirmation.reservation.folio }</span>
                </div>
                <div className={ styles.multiGroup }>
                  {/* <div className={ styles.group }>
                    <p>Operación</p>
                    <span className={ styles.data }>{ payment.foliocpagos }</span>
                  </div> */}
                  <div className={ styles.group }>
                    <p>Importe</p>
                    <span className={ styles.data }>{ currency(totalPayment) }</span>
                  </div>
                </div>
                {processString(confirmation.reservation.mensaje)}
                {/* <p className={ styles.disclaimer }>{ confirmation.reservation.mensaje }</p> */}
                <Button onClick={ () => {
                  selectedTicket.current = 0
                  ticketsRefs.current[selectedTicket.current].printTicket()
                  hideModal()
                  } } 
                  type="neutral">Imprimir comprobante
                  </Button>
              </div>
            </div>
          </div>
        </div>
        <footer className={ global.bottom }>
          <div>
          </div>
          <div className={ global.middle }>
            <Button onClick={ () => {
              if (wasPrinted) {
                exit()
              } else {
                askExit()
              }
            } } type="positive" right-icon="house">Inicio</Button>
          </div>
          <div>
          </div>
        </footer>
      </section>
    </>
  )
}

export default BookingConfirm

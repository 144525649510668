import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Modal } from '../components/transpais'

const ModalContext = createContext(null)

export function ModalProvider({children}) {
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [show, setShow] = useState(false)

  const negativeActionDefault = {
    title: 'OK',
    onClick: () => {
      setShow(false)
    }
  }

  const content = useRef('')
  const title = useRef('')
  const styles = useRef(null)
  const positiveAction = useRef(null)
  const negativeAction = useRef(null)
  const extraAction = useRef(null)
  const extraActionTwo = useRef(null)
  const positiveExtraAction = useRef(null)
  const cancelable = useRef(false)

  function showModal() {
    //loggerDebug('isVisible', isVisible())
    if (isVisible()) {
      forceUpdate()
    }
    //loggerDebug('Muestra modal')
    setShow(true)
  }

  function hideModal() {
    //loggerDebug('Oculta modal')
    setShow(false)
  }

  function isVisible() {
    return show
  }

  function setupModal(userInfo) {
    title.current = userInfo.title
    content.current = userInfo.content
    styles.current = userInfo.styles
    cancelable.current = userInfo.cancelable

    if (userInfo.actions) {
      positiveAction.current = userInfo.actions.positive
      negativeAction.current = userInfo.actions.negative
      extraAction.current = userInfo.actions.extra
      extraActionTwo.current = userInfo.actions.extraTwo
      positiveExtraAction.current = userInfo.actions.positiveExtra
    } else {
      negativeAction.current = negativeActionDefault
    }
  }

  useEffect(() => {
    if (show === false) {
      content.current = ''
      title.current = ''
      positiveAction.current = null
      negativeAction.current = null//negativeActionDefault
      extraAction.current = null
      extraActionTwo.current = null
      positiveExtraAction.current = null
    }
  }, [show])

  const value = useMemo(() => {
    return {
      isVisible,
      showModal,
      hideModal,
      setupModal
    }
  }, [show])

  return <ModalContext.Provider value={ value }>
    <Modal show={ show }
      onClose={ negativeAction.current?.onClick ?? negativeActionDefault.onClick }
      onAccept={ positiveAction.current?.onClick }
      onExtra={ extraAction.current?.onClick }
      onExtraTwo={ extraActionTwo.current?.onClick }
      onExtraAccept={ positiveExtraAction.current?.onClick }
      title={ title.current }
      buttonType={ styles.current?.buttonType }
      positiveButtonTitle={ positiveAction.current?.title }
      negativeButtonTitle={ negativeAction.current?.title }
      extraButtonTittle={ extraAction.current?.title }
      extraTwoButtonTittle={ extraActionTwo.current?.title }
      positiveExtraButtonTittle={ positiveExtraAction.current?.title }
      cancelable={ cancelable.current }>
      { content.current }
    </Modal>
    { children }
  </ModalContext.Provider>
}

export function useModal() {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModal debe estar dentro del proveedor ModalContext')
  }

  return context
}

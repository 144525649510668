import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { formatISO, intlFormat, isDate, parseISO } from 'date-fns'
import { useAtom } from 'jotai'
import { TripType } from '../../components/transpais/enums'

import styles from './TranspaisTripData.module.scss'
import { currency } from './helpers/currency'
import {bookingSessionDepartureDate, bookingSessionReturningDate, bookingSessionTripConfig} from '../../utilities/atoms'
import { formatInTimeZone } from 'date-fns-tz'
import esMX from 'date-fns/locale/es'

const TranspaisTripData = ({variant, data, meta}) => {
  const [bookingDepartureDate, setBookingDepartureDate] = useAtom(bookingSessionDepartureDate)
  const [bookingReturningDate, setBookingReturningDate] = useAtom(bookingSessionReturningDate)
  const [bookingTripConfig, setBookingTripConfig] = useAtom(bookingSessionTripConfig)
  
  const render = () => {
    switch (variant) {
      case 'brief':
        const _returning = data.returning

        let _origin = data.origin
        let _destination = data.destination
        if (_returning) {
          _origin = data.destination
          _destination = data.origin
        }

        return (<div className={ styles.trip }>
          <div>
            <h2>{ _origin.cve }</h2>
            <p>{ _origin.label }</p>
          </div>
          <FontAwesomeIcon
            icon={ solid('chevron-right') } size={ '2xl' }/>
          <div>
            <h2>{ _destination.cve }</h2>
            <p>{ _destination.label }</p>
          </div>
        </div>)
      case 'meta':
        let departureTime = '...'
        if (meta.departure?.salida_raw) {
          let departureTimeRaw = parseISO(meta.departure?.salida_raw.replace(/-05:/, '-06:'))
          departureTime = formatInTimeZone(departureTimeRaw, 'America/Monterrey', 'HH:mm', {locale: esMX})
        }

        let returningTime = '...'
        if (meta.returning?.salida_raw) {
          let returningTimeRaw = parseISO(meta.returning.salida_raw.replace(/-05:/, '-06:'))
          returningTime = formatInTimeZone(returningTimeRaw, 'America/Monterrey', 'HH:mm', {locale: esMX})
        }

        return (<div className={ styles.meta }>
          <div className={ [styles.metaItem,  bookingTripConfig.type === TripType.round ? styles.metaRound : null].join(' ') }>
            <h2>Fecha de salida</h2>
            {
              bookingDepartureDate.length !== 0 ? <p>{intlFormat(parseISO(bookingDepartureDate), {
                month: 'short',
                day: 'numeric',
                weekday: 'short'
              }, {
                locale: 'es-MX',
              })}</p> :
              <p>{ meta.departure?.salida_raw && intlFormat(parseISO(meta.departure?.salida_raw), {
                month: 'short',
                day: 'numeric',
                weekday: 'short'
              }, {
                locale: 'es-MX',
              }) }</p>
            }
            
          </div>
          <div className={ styles.dateSeparator }></div>
          <div className={ [styles.metaItem, bookingTripConfig.type === TripType.round ? styles.metaRound : null, meta.departure?.salida_raw ? null : styles.disabled].join(' ') }>
            <h2>Hora de salida</h2>
            <p>{ meta.departure?.salida_raw ? departureTime : '...' }</p>
          </div>
          { bookingReturningDate.length !== 0 && bookingTripConfig.type === TripType.round ? <>
          <div className={ styles.dateSeparator }></div>
          <div className={ [styles.metaItem, styles.metaRound, bookingTripConfig.type === TripType.round ? null : styles.disabled].join(' ') }>
            <h2>Fecha de regreso</h2>
            {
              bookingReturningDate.length !== 0 && bookingTripConfig.type === TripType.round ? <p>{intlFormat(parseISO(bookingReturningDate), {
                month: 'short',
                day: 'numeric',
                weekday: 'short'
              }, {
                locale: 'es-MX',
              })}</p> : <p>{ meta.returning?.salida_raw ? intlFormat(parseISO(meta.returning.salida_raw), {
                month: 'short',
                day: 'numeric',
                weekday: 'short'
              }, {
                locale: 'es-MX',
              }) : '...'}</p>
            }
          </div>
          <div className={ styles.dateSeparator }></div>
          <div className={ [styles.metaItem, styles.metaRound,(!meta.returning || Object.keys(meta.returning).length === 0) && styles.disabled].join(' ') }>
            <h2>Hora de regreso</h2>
            <p>{ returningTime }</p>
          </div>
          </> : null}
        </div>)
      default: return null
    }
  }

  return render()
}

export default TranspaisTripData